import React from 'react';
import { Tooltip } from 'react-tooltip';

/***
 * Required: 
 *   id - id of element if needs to be referenced in parent component
 *   content - content that goes inside the tooltip
 * Optional:
 *   iconType (default fa-info-circle) - icon shown for tooltip
 *   position (default top) - position of hover text
 *   color (default grey) - color of tooltip icon
 * */
const ToolTip = ({ type, id, content, iconType, position, color, className, iconFontSize }) => {
  return (
    <>
      <span
        className={className}
        style={{ color: `${color || ''}`, fontSize: `${iconFontSize ? iconFontSize : 'inherit'}` }}
        data-tooltip-id={id}
      >
        <i className={`fas ${iconType || 'fa-info-circle'}`} style={{ marginLeft: '5px' }} />
      </span>
      <Tooltip
        type={type ? type : 'dark'}
        id={id}
        place={`${position || 'top'}`}
      >
        {content}
      </Tooltip >
    </>
  )
}

export default ToolTip;

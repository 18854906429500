/* eslint-disable */
import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Button, Badge, Nav, NavItem, NavLink, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import NewPatientForm from './NewPatientForm';
import { isMobile, getLangType, throttle, dataTableThemeConfig, getMaskDisplayName, getStatusStylings, subdomain } from '../utils/utils';
import { collect } from 'react-recollect';
import DataTable, { createTheme } from 'react-data-table-component';
import { appStore } from '../store/store';
import { Tooltip } from 'react-tooltip';
import uuid from 'react-uuid';
import { languageOptions } from '../language';
import { PatientTableRowComponent } from './common/PatientTableRowComponent';
import PatientDetailsModal from './PatientDetailsModal';
import axios from 'axios';
import dayjs from 'dayjs';
import SortPatientList from './SortPatientList';
import CustomPagination from './CustomPagination';

createTheme('blueish', dataTableThemeConfig, 'dark');
const langType = getLangType();

export const statusesMap = {
  'Waiting': 'ptWaiting',
  'Processed': 'ptProcessed',
  'Delivered': 'ptDelivered',
  'Failed': 'ptFailed',
  'Scan Error': 'ptScanError',
  'Expired': 'ptExpired',
  'Completed': 'ptCompleted',
  'Voided': 'ptVoided',
  'RX': 'ptRX',
  'RX - Processed': 'ptRXProcessed',
  'RX - Completed': 'ptRXCompleted',
  'Recall - Completed': 'ptRecallCompleted',
  'Unsubscribed': 'ptUnsubscribed',
}

export let columns = [
  {
    name: languageOptions.ptPatientId[langType],
    selector: row => row.patientId,
    width: '130px',
  },
  {
    name: languageOptions.ptPatientName[langType],
    selector: row => row.fullName,
    width: isMobile() ? null : '225px',
    cell: (row) => {
      const tooltipID = uuid();
      return <>
        <a
          onClick={() => appStore.showPatientDetails(row)}
          className='link-primary fw-bold'
          data-tooltip-id={tooltipID}
          data-tooltip-content="Click to view Patient Details"
        >
          {row.fullName}
        </a>
        <Tooltip id={tooltipID} />
      </>
    }
  },
  {
    name: languageOptions.ptStatus[langType],
    selector: row => row.status,
    width: '210px',
    hide: "sm",
    cell: (row) => {
      const { classname, color, info } = getStatusStylings(row.status, row)
      var badgeStyle = { fontSize: '12px' };
      const isCompleted = info.includes('Completed') && !info.includes('RX');
      if (isCompleted) {
        badgeStyle = { fontSize: '12px', backgroundColor: '#253375' };
      }
      return <>
        <Badge color={isCompleted ? '#253375' : color} style={badgeStyle} >
          {info}
          <span style={{ paddingLeft: '2px' }}>
            {' '} <i className={classname} />
          </span>
        </Badge>
      </>
    }
  },
  {
    name: languageOptions.ptSelectedMak[langType],
    selector: row => row.currentMaskType,
    width: '270px',
    hide: "md",
    cell: (row) => {
      const tooltipID = uuid();
      if (row.currentMaskType || row.currentMaskSize) {
        // const type = row.currentMaskType || "Unk.";
        let type = '';
        if (row.currentMaskType === 'Other') {
          type = row.currentMaskTypeOther ? row.currentMaskTypeOther : 'Other'
        } else {
          type = (getMaskDisplayName(row.currentMaskType) || "Unk.");
        }
        const size = row.currentMaskSize || "Unk.";
        let color = 'success'
        let message = 'Recommended Mask Selected'
        if (row.fittingWay === 'technician_selection') {
          color = 'info';
          message = 'Clinician Selection'
        } else if (row.fittingWay === 'refit' || row.fittingWay === 'switched_from_prescription' || row.fittingWay === 'switch_from_recommendation') {
          color = 'dark';
          message = 'Refit'
        } else if (row.fittingWay === 'non-recommended-opt') {
          color = 'danger';
          message = 'Non-Recommended Option'
        } else if (row.fittingWay == 'prescription') {
          color = 'secondary';
          message = 'Prescription'
        }
        return <>
          <Badge
            color={color}
            style={{ fontSize: '12px', whiteSpace: 'wrap', textAlign: 'left', fontWeight: '600' }}
            data-tooltip-id={tooltipID}
            data-tooltip-content={message}
          >
            {type}, {size}
          </Badge>
          <Tooltip id={tooltipID} />
        </>
      }
      return <span title='Not available'>N/A</span>
    }
  },
  {
    name: languageOptions.ptLocation[langType],
    selector: row => row.location,
    minWidth: '270px',
    maxWidth: '290px',
    hide: "md",
    cell: (row) => <div> {row.location !== 'None - None' ? row.location : 'No Location'} </div>
  },
];
if (window.location.host.startsWith('rotech.')) {
  columns.push({
    name: "Added By",
    selector: row => row.createdBy,
    width: '170px',
    hide: "md",
  });
}
columns.push({
  name: 'Filtered by Date',
  selector: row => row.timezone,
  width: '200px',
  hide: "md",
  cell: (row) => {
    const formattedDate = dayjs(row.createdTimeFormatted).format('MM/DD/YYYY - hh:mm a')
    if (row.createdTimeFormatted === 'N/A') return <span >N/A</span>;
    return <span>
      {formattedDate}<br />
      <span style={{ color: 'var(--color-light-gray)', fontSize: '12px', fontStyle: 'italic' }}>
        ({row.timezone})
      </span>
    </span>
  }
},
);

class PatientsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      sharedPatientsLabel: 'Patients Shared From',
      isTablet: false,
      isMobile: false,
      showResetFilteredModal: false,
      currentPage: appStore.getcurrentPatientTablePage(),
      patientsTotalRows: 0,
      itemsPerPage: 50,
    };
    this.throttleResize = throttle(() => this.handleResize())
    this.cancelToken = axios.CancelToken.source();
  }

  patientTableRowComponent = (props) => <PatientTableRowComponent {...props} isMobile={this.state.isMobile} />

  customPaginationComponent = () => (
    <CustomPagination
      totalPages={Math.ceil(this.props.store.patientsTotalRows / this.state.itemsPerPage)}
      currentPage={appStore.getcurrentPatientTablePage()}
      onPageChange={this.handlePageChange}
      patientsTotalRows={this.props.store.patientsTotalRows}
      itemsPerPage={this.state.itemsPerPage}
    />
  );

  componentDidMount() {
    // appStore.loadPatientsStatus();

    // Check if scan counter is enabled, only then use the setInterval for future calls
    this.setState({ patientsTotalRows: appStore.patientsTotalRows });
    appStore.loadScanCounter().then(() => {
      if (this.props.store.scanPackageEnabled) {
        this.scanCountIntervalId = setInterval(function () {
          appStore.loadScanCounter();
        }, 20000);
      }
    })
    setTimeout(() => {
      appStore.getSettings();
      appStore.resetFilters();
      appStore.loadPatients(false, this.cancelToken);
      this.handleResize();
    });
    // this.intervalId = setInterval(function () {
    //   appStore.loadPatientsStatus();
    // }, 30000);


    //* When focus the main table, reset the "new updates" indicator
    window.addEventListener('focus', this.handleNotificationIndicator);
    window.addEventListener("resize", this.throttleResize)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    clearInterval(this.scanCountIntervalId);
    window.removeEventListener('focus', this.handleNotificationIndicator);
    window.removeEventListener("resize", this.throttleResize)
    this.cancelToken.cancel();
    appStore.resetFilters();
  }

  handleResize = () => {
    const { clientWidth } = document.documentElement
    if (clientWidth < 959 && clientWidth > 599) this.setState({ isTablet: true });
    else this.setState({ isTablet: false });
    if (clientWidth <= 599) this.setState({ isMobile: true });
    else this.setState({ isMobile: false });
  }

  handleNotificationIndicator = () => {
    appStore.resetNotificationIndicator();
    appStore.resetPatientNotificationCount();
  }

  refresh = () => {
    document.getElementById('nameSearch').value = '';
    document.getElementById('statusesList').value = 'all';
    appStore.resetFilters();
    appStore.loadPatients(false, this.cancelToken);
  };

  sendStaffMessage = () => {
    appStore.sendStaffMessage();
  }

  addRandomPatient = async () => {
    const { data: { results } } = await axios.get('https://randomuser.me/api/?results=1&nat=us')
    const { id, name, email, cell } = results[0]
    await appStore.addPatient({
      ...this.props.store.newPatient,
      id: id.value,
      firstName: name.first,
      lastName: name.last,
      email: email,
      number: cell,
      inviteChannel: 'email',
      location: window.location.host.includes('sleepgladstaging') ? 68 : 1,
      subdomain: subdomain,
      birthDate: '2011-01-01',
    })
    appStore.loadPatients()
  }

  handlePageChange = (newPage) => {
    this.setState({ currentPage: newPage }, () => {
      this.props.store.patientsPageNumber = newPage;
      appStore.setcurrentPatientTablePage(newPage);
      appStore.loadPatients(false, this.cancelToken);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  };

  render() {
    let locationOptions = [];
    if (this.props.store.currentSubdomain.locations && this.props.store.currentSubdomain.locations.length) {
      locationOptions = this.props.store.currentSubdomain.locations.map((data) => {
        return (
          <option key={data} value={data}>
            {data}
          </option>
        );
      });
    }

    locationOptions.unshift(
      <option key={'none'} value={'none'}>
        {languageOptions.selectLocation[langType]}
      </option>,
    );

    return (
      <React.Fragment>
        {/* Dashboard Patients table */}
        <Card className='table-card'>
          <CardBody>
            <CardTitle className='mb-4'>
              <div className="patient-tbl-nav-box">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={(
                        !this.props.store.newPatientFilter &&
                        !this.props.store.filters.resupplyPatients &&
                        !this.props.store.filters.internalPatients &&
                        !this.props.store.filters.sharedToPatients &&
                        !this.props.store.filters.sharedFromPatients &&
                        !this.props.store.filters.recallPtsOnly &&
                        !this.props.store.filters.recallPtsYes) ? "active" : ""
                      }
                      onClick={() => {
                        this.props.store.newPatientFilter = false;
                        this.props.store.filters.resupplyPatients = false;
                        this.props.store.filters.internalPatients = false;
                        this.props.store.filters.sharedToPatients = false;
                        this.props.store.filters.sharedFromPatients = false;
                        this.props.store.filters.recallPtsOnly = false;
                        this.props.store.filters.recallPtsYes = null;
                        console.log('Clearing All Filters')
                        appStore.loadPatients(false, this.cancelToken);
                      }}
                    >
                      <div>{languageOptions.ptAll[langType]}</div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={this.props.store.newPatientFilter == true || this.props.store.filters.internalPatients === true ? "active" : ""}
                      onClick={() => {
                        if (this.props.store.currentSubdomain.npi_client) {
                          this.props.store.newPatientFilter = true;
                          this.props.store.filters.internalPatients = true;
                          console.log('Setting filter to Internal Patients only')
                        } else {
                          this.props.store.newPatientFilter = true;
                          this.props.store.filters.internalPatients = false;
                          console.log('Setting filter to New Patients only')
                        }
                        this.props.store.filters.resupplyPatients = false;
                        this.props.store.filters.sharedToPatients = false;
                        this.props.store.filters.sharedFromPatients = false;
                        this.props.store.filters.recallPtsOnly = false;
                        this.props.store.filters.recallPtsYes = null;
                        appStore.loadPatients(false, this.cancelToken);
                      }}
                    >
                      <div>{this.props.store.currentSubdomain.npi_client ? 'Internal Patients' : languageOptions.ptNew[langType]}</div>
                    </NavLink>
                  </NavItem>
                  {!this.props.store.currentSubdomain.npi_client &&
                    <NavItem>
                      <NavLink
                        className={this.props.store.filters.resupplyPatients ? "active" : undefined}
                        onClick={() => {
                          this.props.store.newPatientFilter = false;
                          this.props.store.filters.resupplyPatients = true;
                          this.props.store.filters.internalPatients = false;
                          this.props.store.filters.sharedToPatients = false;
                          this.props.store.filters.sharedFromPatients = false;
                          this.props.store.filters.recallPtsOnly = false;
                          this.props.store.filters.recallPtsYes = null;
                          console.log('Setting filter to Resupply only')
                          appStore.loadPatients(false, this.cancelToken);
                        }}
                      >
                        <div>{languageOptions.ptResupply[langType]}</div>
                      </NavLink>
                    </NavItem>
                  }
                  <Tooltip id='recall-pt-tooltip' place='top-start' />
                  <NavItem data-tooltip-id='recall-pt-tooltip' data-tooltip-content='Click to view patients from the campaigns' >
                    <NavLink
                      className={this.props.store.filters.recallPtsOnly ? "active" : ""}
                      onClick={() => {
                        this.props.store.newPatientFilter = false;
                        this.props.store.filters.resupplyPatients = false;
                        this.props.store.filters.internalPatients = false;
                        this.props.store.filters.sharedToPatients = false;
                        this.props.store.filters.sharedFromPatients = false;
                        this.props.store.filters.recallPtsOnly = true;
                        this.props.store.filters.recallPtsYes = null;
                        console.log('Setting filter to Recall Patients only')
                        appStore.loadPatients(false, this.cancelToken);
                      }}
                    >
                      <span>{languageOptions.campaignPatients[langType]}</span>
                    </NavLink>
                  </NavItem>
                  <Tooltip id='recall-pt-yes-tooltip' place='top-start'>
                    Click to view patients from the recall campaign that answered <br />
                    the "Magnet-Sensitive Surgical Implant" as "Yes"
                  </Tooltip>
                  {/* <NavItem data-tooltip-id='recall-pt-yes-tooltip'>
                    <NavLink
                      className={this.props.store.filters.recallPtsYes ? "active" : ""}
                      onClick={() => {
                        this.props.store.newPatientFilter = false;
                        this.props.store.filters.resupplyPatients = false;
                        this.props.store.filters.internalPatients = false;
                        this.props.store.filters.sharedToPatients = false;
                        this.props.store.filters.sharedFromPatients = false;
                        this.props.store.filters.recallPtsOnly = false;
                        this.props.store.filters.recallPtsYes = true;
                        console.log('Setting filter to Recall Patients answered Yes')
                        appStore.loadPatients(false, this.cancelToken);
                      }}
                    >
                      <div>{languageOptions.ptRecallYes[langType]}</div>
                    </NavLink>
                  </NavItem> */}
                  {this.props.store.settings.sharedPatientsEnabled &&
                    <>
                      <NavItem>
                        <NavLink
                          className={this.props.store.filters.sharedFromPatients ? "active" : ""}
                          onClick={() => {
                            this.props.store.newPatientFilter = false;
                            this.props.store.filters.resupplyPatients = false;
                            this.props.store.filters.internalPatients = false;
                            this.props.store.filters.sharedToPatients = false;
                            this.props.store.filters.sharedFromPatients = true;
                            this.props.store.filters.recallPtsOnly = false;
                            this.props.store.filters.recallPtsYes = null;
                            console.log('Setting filter to Shared only')
                            appStore.loadPatients(false, this.cancelToken);
                          }}
                        >
                          <div>Patients Shared</div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={this.props.store.filters.sharedToPatients ? "active" : ""}
                          onClick={() => {
                            this.props.store.newPatientFilter = false;
                            this.props.store.filters.resupplyPatients = false;
                            this.props.store.filters.internalPatients = false;
                            this.props.store.filters.sharedToPatients = true;
                            this.props.store.filters.sharedFromPatients = false;
                            this.props.store.filters.recallPtsOnly = false;
                            this.props.store.filters.recallPtsYes = null;
                            console.log('Setting filter to Shared only')
                            appStore.loadPatients(false, this.cancelToken);
                          }}
                        >
                          <div>Patients Received</div>
                        </NavLink>
                      </NavItem>
                    </>}
                </Nav>
                <div className="patient-tbl-action-btn">
                  <Button
                    type='button'
                    color='primary'
                    size='sm'
                    onClick={this.refresh}
                    style={{ margin: 'auto 4px 4px auto' }}>
                    {languageOptions.ptReoloadPatients[langType]}{' '}
                    <span style={{ paddingLeft: '2px' }}>
                      {' '}
                      <i className='fas fa-sync' />
                    </span>
                  </Button>
                  {/* Add New Patient modal dialog */}
                  <NewPatientForm shouldSendInvite={true} />

                  {/* added a new button, which renders only when the user is staff.*/}
                  {this.props.store.currentUser.isStaff && (
                    <>
                      <Button
                        type='button'
                        color='primary'
                        size='sm'
                        disabled={this.props.store.patientsTotalRows > 100}
                        onClick={() => this.setState({ showResetFilteredModal: true })}
                        style={{ margin: 'auto 4px 4px 4px' }}>
                        Reset & Re-invite
                        <span style={{ paddingLeft: '2px' }}>
                          {' '}
                          <i className='fas fa-reply-all' />
                        </span>
                      </Button>

                      <Button
                        type='button'
                        color='primary'
                        size='sm'
                        onClick={this.sendStaffMessage}
                        style={{ margin: 'auto 4px 4px auto' }}>
                        Send messages
                        <span style={{ paddingLeft: '2px' }}>
                          {' '}
                          <i className='fas fa-envelope' />
                        </span>
                      </Button>

                      {/* [Staff] Bulk re-invites modal */}
                      <Modal
                        isOpen={this.state.showResetFilteredModal}
                        centered={true}
                      >
                        <ModalHeader>
                          <i className='fa fa-exclamation-circle' style={{ color: "goldenrod", fontSize: "1.5rem", marginRight: "10px" }} />
                          Bulk Reset & Re-invite
                        </ModalHeader>
                        <ModalBody>
                          <h5>Are you sure you want to <b>reset and re-invite</b> all filetered patients?</h5>
                          <p>This action will be automatically re-invite <b>{this.props.store.patientsTotalRows}</b> patients.</p>
                        </ModalBody>

                        <ModalFooter>
                          <Button
                            color='danger'
                            onClick={() => {
                              this.setState({ showResetFilteredModal: false })
                            }}
                          > Close </Button>

                          <Button
                            color='primary'
                            onClick={() => {
                              appStore.bulkReinvites();
                              this.setState({ showResetFilteredModal: false });
                            }}
                            disabled={this.props.store.bulkReinvitesLoading}
                          > Reset and Re-invite </Button>
                        </ModalFooter>
                      </Modal>
                    </>
                  )}
                </div>
              </div>
            </CardTitle>
            <div className='d-flex justify-content-between align-items-center'>
              <SortPatientList />
              <div className='d-flex'>
                {this.props.store.scanPackageEnabled &&
                  <div>
                    <Badge
                      pill
                      style={{ fontSize: '1.2rem', fontWeight: 'normal' }}
                      color={this.props.store.remainingScans > 20 ? 'success' : this.props.store.remainingScans > 10 ? 'warning' : 'danger'}
                      data-tooltip-id='scanPackage'
                      data-tooltip-content={'Remaining scans in your purchased scan package'}
                    >
                      {this.props.store.remainingScans}
                    </Badge>
                    <Tooltip id='scanPackage' />
                  </div>
                }
                {/* Available for local dev environment or staging environment */
                  (window.location.host.includes('localhost') || window.location.host.includes('sleepgladstaging')) &&
                  <Button color='primary' size='sm' onClick={this.addRandomPatient}>Add a Random Patient</Button>}
              </div>
            </div>
            <DataTable
              responsive
              striped
              expandableRows={this.state.isMobile || this.state.isTablet}
              expandOnRowClicked={this.state.isMobile || this.state.isTablet}
              expandableRowsComponent={this.patientTableRowComponent}
              columns={columns}
              noHeader
              theme="blueish"
              data={this.props.store.patients}
              progressPending={!this.props.store.patients.length && this.props.store.hasPatients !== false}
              progressComponent='Loading patients...'
              pagination
              paginationComponent={this.customPaginationComponent}
              paginationPerPage={50}
              paginationServer={true}
              paginationTotalRows={this.props.store.patientsTotalRows}
              paginationComponentOptions={{ noRowsPerPage: true }}
              // defaultSortField='createdTimeFormatted'
              // defaultSortAsc={false}
              style={{ textOverflow: '' }}
              onChangePage={(pageNumber) => {
                this.handlePageChange(pageNumber);
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <a
                href={encodeURI(appStore.buildPatientListUrl(false, true))}>
                <i
                  className='fas fa-file-excel'
                  style={{ color: 'green', paddingRight: '3px' }}
                />
                {languageOptions.ptDownloadCSV[langType]}
              </a>
            </div>
          </CardBody>
        </Card>
        <PatientDetailsModal />

        {/* In-Person modal */}
        <Modal
          isOpen={this.props.store.showInPersonModal}
          size="sm"
          centered
          style={{ maxWidth: '625px', width: '80%' }}
        >
          <ModalHeader toggle={this.toggleWarningModal}>
            <i className='fa fa-exclamation-circle' style={{ color: "goldenrod", fontSize: "2rem", marginRight: "10px" }} />
            <span>In Person mode</span>
          </ModalHeader>
          <ModalBody>
            <p>You are about to start a <b>In Person</b> mask fit.</p>
            <p>If the scan does not open, please check for blocked pop-ups or you can open it later from the patient profile.</p>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={() => appStore.setShowInPersonModal(false)}>
              {languageOptions.cancel[langType]}
            </Button>
            <Button color='primary' onClick={appStore.gotoInPersonScan}>
              Scan Now
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default collect(PatientsTable);

import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { appStore } from '../store/store';
import { collect, store } from 'react-recollect';
import { getLangType } from '../utils/utils';
import { languageOptions } from '../language';
import './Profile.css';
import { isMobile } from 'react-device-detect';
const langType = getLangType();

const ProfileMenu = () => {
  const [menu, setMenu] = useState(false)

  const otherBranches = store.currentSubdomain.branches ? store.currentSubdomain.branches.filter(branch =>
    (branch.owned_by === store.currentUser.customerSubdomain && branch.subdomain !== store.currentUser.customerSubdomain)) : [];
  const { npi_client, is_facility } = store.currentSubdomain

  const toggle = (event) => {
    event.preventDefault();
    setMenu((prevState) => !prevState)
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className='d-inline-block'>
        <DropdownToggle className='btn header-item waves-effect p-xs-0 p-sm-1 p-md-2' tag='button'>
          <div
            className={npi_client ? 'btn btn-gray p-0' : is_facility ? 'btn btn-blue p-0' : 'btn btn-primary p-0'}
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              fontSize: '16px',
              padding: '10px',
              borderRadius: '10px',
            }}
          >
            <div style={{
              borderRadius: '10px',
              background: 'var(--color-primary)',
              height: '48px'
            }}>
              <img
                src={encodeURI(store.currentSubdomain.customerImageURL)}
                className='d-inline-block align-top'
                style={{
                  borderRadius: '10px',
                  opacity: .85,
                  objectFit: 'contain',
                  height: '100%'
                }}
                alt="customer logo"
              />
            </div>
            &nbsp;&nbsp;
            {store.currentUser.firstName}
            &nbsp;&nbsp;
            <i className='fas fa-cog' style={{ fontSize: "30px" }} />
            &nbsp;
          </div>
        </DropdownToggle>
        <DropdownMenu end style={{ fontSize: '18px' }}>
          <div
            className='dropdown-item'
            style={{
              width: '300px',
              color: 'var(--color-primary)',
              borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
              marginBottom: '20px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontSize: '16px',
              padding: '0.25rem 0.8rem'
            }}>
            <div>{languageOptions[`role_${store.currentUser.userRole}`][langType]}</div>
            <DropdownItem
              onClick={() => {
                if (localStorage.getItem('login_type') === 'microsoft' && isMobile) {
                  localStorage.setItem('sso_mobile_logout', true)
                }
                appStore.logout()
              }}
              className='logout-dropdown-item p-0'>
              <div className='logout-parent d-flex flex-column align-items-center'>
                <i className='fas fa-power-off'></i>
                <span>{languageOptions.logout[langType]}</span>
              </div>
            </DropdownItem>
          </div>
          {otherBranches
            .sort((a, b) => a.name_formatted.localeCompare(b.name_formatted))
            .map((item, i) => {
              return (
                <a href={item.dashboard_url} className='dropdown-item' target="_blank" key={i} rel="noopener noreferrer">
                  <div
                    className='btn btn-primary'
                    style={{
                      color: 'white',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      fontSize: '16px',
                      padding: '10px',
                      borderRadius: '10px',
                    }}
                  >
                    <img
                      src={encodeURI(item.logo_url)}
                      height='32px'
                      className='d-inline-block align-top'
                      style={{
                        borderRadius: '6px',
                      }}
                      alt="logo"
                    />
                    &nbsp;&nbsp;
                    {item.name_formatted}
                  </div>
                </a>
              )
            })}
          <div className='dropdown-divider'></div>

        </DropdownMenu>
      </Dropdown>
    </React.Fragment >
  );
}

export default collect(ProfileMenu);

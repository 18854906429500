import React, { Component, lazy, Suspense } from 'react';
import DataTable from 'react-data-table-component';
import {
  Input, Button, Col, Row, Container, Card, CardHeader, CardBody, FormGroup, Label, Modal, FormFeedback, Form, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink as NavLinkRS, Alert,
  Badge
} from 'reactstrap';
import { collect, store } from 'react-recollect';
import classnames from 'classnames';
import { appStore } from '../store/store';
import { validatePhone, validateEmail, capitalize, getLangType, getMaskDisplayName, isValidArray, formatDate, getMaskCushionSize, getMaskNumCushionSizes, getMaskFrameSize, maskIsMagnetic, magneticImplantsListEng, displayFormError, validNumberInput, validatePatientID } from '../utils/utils';
import { getMaskOptionGroupsWithOther, numberErrorEnum } from './NewPatientForm';
import Select from "react-select";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { languageOptions } from '../language';
import './PatientDetails.css'
import ToolTip from './ToolTip';
import refitQuestions from '../assets/refit_questions.json';
import formularyJSON from '../assets/productIds.json';
import uuid from 'react-uuid';
import ReferringPhysicianLookup from './ReferringPhysicianLookup';
import Loading from '../components/Loading'
import RecallPatientNotes from './ptDetailTabs/RecallPatientNotes';
import { memoize, debounce, isEqual } from 'lodash';
import ScriptedMaskForm from './ScriptedMaskForm';
import PhoneNumberInput from './PhoneNumberInput';
import { isPossiblePhoneNumber, formatPhoneNumber } from 'react-phone-number-input';
import ErrorBoundary from './ErrorBoundary';
import CustomDatePicker from './CustomDatePicker.js';
import dayjs from 'dayjs';
import PatientRefitForm from './PatientRefitForm.js';
import PatientQuestionnaire from './PatientQuestionnaire';
import PatientNote from './PatientNote.js';
import PatientCompliance from './PatientCompliance.js';
import NonRecommendedOption from './NonRecommendedOption.js';

const AlertsTab = lazy(() => import('./ptDetailTabs/AlertsTab'))
const MessageTab = lazy(() => import('./messages/MessageTab'))
const PatientHistoryTab = lazy(() => import('./ptDetailTabs/PatientHistoryTab'))
const ComplianceDataTab = lazy(() => import('./ptDetailTabs/ComplianceDataTab'))

const refitChildQs = {};
for (const question of refitQuestions.questions) {
  if (question.children) {
    for (const child of question.children) {
      const cId = child.id;
      if (child.condition)
        refitChildQs[cId] = {
          "parentId": question.id,
          "parentValues": child.condition
        };
      else
        refitChildQs[cId] = child.condition;
    }
  }
}
const langType = getLangType();

export const getProductSKU = mask => {
  let product_id = 'N/A';
  let isFitpack = false;
  let headgearId = null;

  let choices = formularyJSON['supported_masks'][mask.name]['product_ids'];
  choices = choices.filter(sku => {
    if (sku.cushion_size) {
      return sku.cushion_size === mask.cushion_size;
    }
  })

  if (choices.length > 1) {
    choices = choices.filter(sku => sku.frame_size === mask.frame_size)
  }

  if (choices.length === 1) {
    let mask_choice = choices[0];
    product_id = mask_choice.product_id;
    if (mask_choice.fitpack_only) {
      isFitpack = true;
    }
    if (!mask_choice.with_headgear) {
      headgearId = mask_choice.headgear_id;
    }
  }
  return { product_id, isFitpack, headgearId };
}

const refit_columns = [
  {
    name: languageOptions.switchedToMask[langType],
    selector: row => row.switched_to_mask_type,
    width: '220px',
    conditionalCellStyles: [
      {
        when: row => maskIsMagnetic(row.switched_to_mask_type) === true,
        style: {
          color: 'var(--color-darkred)'
        }
      }
    ],
    cell: row => (
      row.switched_to_mask_type === 'Other' && row.switched_to_mask_type_other) ?
      `Other: ${row.switched_to_mask_type_other}` :
      getMaskDisplayName(row.switched_to_mask_type
      )
  },
  {
    name: languageOptions.cushionSize[langType],
    selector: row => row.switched_to_mask_size,
    center: true,
    width: '120px'
  },
  {
    name: languageOptions.type[langType],
    selector: row => row.type,
    center: true,
    cell: row => {
      if (row.switch_reason === 'non-recommended-opt') {
        return 'Non recommended';
      } else return row.type;
    }
  },
  {
    name: languageOptions.submittedAt[langType],
    selector: row => row.submitted_at,
    sortable: true,
    sortFunction: (date1, date2) => date1 > date2,
    width: '200px'
  },
  {
    name: languageOptions.submittedBy[langType],
    selector: row => row.submitted_by,
    minWidth: '220px'
  },
];

const facilityTypeOptions = [
  { value: 'DME/HME', label: 'DME/HME' },
  { value: 'Sleep Lab', label: languageOptions.sleepLab[langType] },
  { value: 'Hospital', label: 'Hospital' },
  { value: 'Medical Doctor', label: languageOptions.medicalDoctor[langType] },
  { value: 'Skilled Nursing Facility', label: languageOptions.skilledNursingFacility[langType] },
  { value: 'Other', label: languageOptions.other[langType] },
]

const ethnicityOptions = [
  { label: languageOptions.americanIndian[langType], value: 'american indian' },
  { label: languageOptions.asian[langType], value: 'asian' },
  { label: languageOptions.africanAmerican[langType], value: 'african american' },
  { label: languageOptions.hispanic[langType], value: 'hispanic' },
  { label: languageOptions.hawaiian[langType], value: 'pacific islander' },
  { label: languageOptions.caucasian[langType], value: 'caucasian' },
  { label: languageOptions.other[langType], value: 'other' },
]

const sleepStudyTypeOptions = [
  { label: languageOptions.homeSleepTest[langType], value: 'Home Sleep Test' },
  { label: `PSG ${languageOptions.withTitration[langType]}`, value: 'PSG with Titration' },
  { label: `PSG ${languageOptions.noTitration[langType]}`, value: 'PSG no Titration' },
]

const ptLanguageOptions = [
  { label: 'English (en-US)', value: 'en' },
  { label: 'Español (Spanish)', value: 'es' },
  { label: 'Português (Portuguese)', value: 'pt' }
]

const today = new Date();

const initErrors = {
  id: false,
  firstName: false,
  lastName: false
}
class PatientDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readOnly: this.props.readOnly || this.props.store.currentUser.userRole === 'readonly' || this.props.store.currentSubdomain.receive_pts_only,
      patientIdEdit: false,
      firstNameEdit: false,
      lastNameEdit: false,
      phoneEdit: false,
      emailEdit: false,
      pressureEdit: false,
      ahiEdit: false,
      formularyEdit: false,
      locationEdit: false,
      inviteTypeEdit: false,
      facilityTypeEdit: false,
      bmiEdit: false,
      dobEdit: false,
      ethnicityEdit: false,
      languageEdit: false,
      sleepStudyTypeEdit: false,
      sharedWithEdit: false,
      referringNPIEdit: false,
      scriptedMaskEdit: true,
      scriptedMaskAdd: true,
      patientId: this.props.store.selectedRow.patientId,
      firstName: this.props.store.selectedRow.first_name,
      lastName: this.props.store.selectedRow.last_name,
      pressure: this.props.store.selectedRow.patient_attributes.pressure,
      ahi: this.props.store.selectedRow.ahi,
      phoneNumber: this.props.store.selectedRow.phone,
      email: this.props.store.selectedRow.patient_email,
      model_output: this.props.store.selectedRow.model_output,
      formulary: this.getFormularyOptions(),
      formularyName: this.props.store.selectedRow.formularyName,
      location: {
        label: this.props.store.selectedRow.locationId && this.props.store.selectedRow.location,
        value: [this.props.store.selectedRow.subdomain, this.props.store.selectedRow.locationId]
      },
      language: ptLanguageOptions.filter((e) => e.value === this.props.store.selectedRow.language)[0],
      feedback_form_visible: false,
      scriptedForm: false,
      maskOptionGroupsWithOther: getMaskOptionGroupsWithOther(),
      refitFeedbackVersion: refitQuestions.version,
      refitFeedbackAnswers: {},
      refitFormChanged: false,
      selectedRecommendationMaskType: this.props.store.selectedRow.current_mask_type,
      selectedRecommendationMaskTypeOther: null,
      selectedRecommendationMaskCushionSize: this.props.store.selectedRow.current_mask_size,
      selectedRecommendationMaskFrameSize: this.props.store.selectedRow.current_mask_frame_size,
      refitFormValid: false,
      scriptedFormValid: false,
      showVoidModal: false,
      void_reason: '',
      void_reason_error: false,
      inviteType: this.props.store.selectedRow.patient_attributes.invite_channel || '',
      facilityType: { value: this.props.store.selectedRow.patient_attributes.facility_type, label: this.props.store.selectedRow.patient_attributes.facility_type },
      bmi: this.props.store.selectedRow.patient_attributes.bmi,
      dob: this.props.store.selectedRow.patient_attributes.date_of_birth, //"01/01/1970"
      ethnicity: ethnicityOptions.filter((e) => e.value === this.props.store.selectedRow.patient_attributes.ethnicity)[0],
      sleepStudyType: sleepStudyTypeOptions.filter((e) => e.value === this.props.store.selectedRow.patient_attributes.sleep_study_type)[0],
      shouldShowChinStrapModal: false,
      showChinStrapModal: false,
      shouldShowLowConfidenceModal: this.props.store.selectedRow.model_output?.confidence == "Low",
      showLowConfidenceModal: false,
      chinStrapType: 'n/a',
      shared_with: this.props.store.selectedRow.shared_with,
      showCompletedModal: false,
      dateOfService: today.toISOString().split('T')[0],
      doesRefitExist: false,
      switch_reason: 'Selection',
      showRefitConfirmationModal: false,
      showMagneticRefitModal: false,
      isScripted: false,
      showNewSharedNote: false,
      newSharedNote: {
        'body': '',
        'sharedWith': this.props.store.selectedRow.shared_with,
        'message_type': '',
        'message_status': '',
      },
      showSaveSharedNoteModal: false,
      showPtRecallCompleteModal: false,
      showFormularyWarningModal: false,
      errors: initErrors,
      phoneNumberError: 0,
      isValidPhoneNumber: false,
      phoneInputCountry: 'US',
      selectedOption: this.getPapValueStore(this.props.store.selectedRow.pap_therapy),
      pressureOption: [],
      resetReinviteModal: false,
      assignedClinicianEdit: false,
      setupDateEdit: false,
      showNROModal: false,
      resetReinviteUnsubscribedModal: false,
      phoneNumberUnsubscribed: this.props.store.selectedRow.patient_attributes?.sms_unsubscribed_at,
      emailUnsubscribed: this.props.store.selectedRow.patient_attributes?.email_unsubscribed_at,
      nonRecommendedOption: {}
    };
    this.refitParentQs = {};
    this.leafQs = {};
    this.debouncedPhoneInputChange = debounce(this.debouncedPhoneInputChange, 900);
  }

  componentDidMount() {
    this.updatePressureOptions();
    appStore.getLocationsSharedWith();
    if (!this.props.readOnly) appStore.getAvailableMaskOptionGroups(true)
    if (this.props.activeTab) appStore.changeActiveTab(this.props.activeTab)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.feedback_form_visible && !this.checkRequiredRefitQuestions())
      document.getElementById('save-refit-bt').disabled = true;
  }


  handleRefitFormChange(value, qVal, qValId) {
    //console.log('handleRefitFormChange', value, qVal, qValId);
    const qId = value.id;

    if (value.children) {
      if (value.type === 'multiple_choice_multiple_select') {
        if (!this.refitParentQs.hasOwnProperty(qId)) {
          let choices = {};
          for (let choiceId of Object.keys(value.choices))
            choices[choiceId] = false;
          this.refitParentQs[qId] = choices;
        }
        this.refitParentQs[qId][qValId] = qVal;
      }
      else
        this.refitParentQs[qId] = qVal;
      delete this.leafQs[qId];

      let desc = [...value.children];
      //console.log('desc: ', desc);
      while (desc.length > 0) {
        const cId = desc.shift().id;
        if (this.refitParentQs.hasOwnProperty(cId))
          delete this.refitParentQs[cId];
        if (this.leafQs.hasOwnProperty(cId))
          delete this.leafQs[cId];
        for (const question of refitQuestions.questions)
          if (question.id === cId && question.children)
            desc.push(...question.children);
      }
    } else {
      if (value.type === 'multiple_choice_multiple_select') {
        if (!this.leafQs.hasOwnProperty(qId)) {
          let choices = {};
          for (let choiceId of Object.keys(value.choices))
            choices[choiceId] = false;
          this.leafQs[qId] = choices;
        }
        this.leafQs[qId][qValId] = qVal;
      }
      else
        this.leafQs[qId] = qVal;
    }

    // console.log("leafQs" + JSON.stringify(this.leafQs));
    this.checkRequiredRefitQuestions();
    this.setState({ refitFormValid: this.checkRequiredRefitQuestions() });
  }

  shouldShowRefitQuestion(qId) {
    if (!refitChildQs[qId])
      return true;
    const pId = refitChildQs[qId].parentId;
    let pType = '';
    refitQuestions.questions.forEach((q) => { if (q.id === pId) pType = q.type; });
    if (pId && pType === 'multiple_choice_multiple_select' && this.refitParentQs[pId]) {
      for (let pVal of refitChildQs[qId].parentValues)
        if (this.refitParentQs[pId][pVal]) {
          return true;
        }
    }
    else if (refitChildQs[qId].parentValues.includes(this.refitParentQs[pId]))
      return true;
    return false;
  }

  checkRequiredRefitQuestions() {
    for (let [qId, qVal] of Object.entries(this.refitParentQs))
      if (!this.checkRequiredRefitValue(qId, qVal))
        return false;

    for (let [qId, qVal] of Object.entries(this.leafQs))
      if (!this.checkRequiredRefitValue(qId, qVal))
        return false;

    return true;
  }

  checkRequiredRefitValue(qId, qVal) {
    // console.log('qVal', qVal)
    let required = true;
    let type = '';
    refitQuestions.questions.forEach((q) => {
      if (q.id === qId) {
        required = q.required;
        type = q.type;
      }
    });

    if (this.refitParentQs.switch_reason_multiple === undefined) return false;
    else if (new Set(Object.values(this.refitParentQs.switch_reason_multiple)).size === 1) return false

    if (qVal.other) {
      if (this.refitParentQs.switch_reason_multiple) {
        //if its null, or has less than 10 characters it's not valid.
        if (!this.leafQs.switch_reason_other || this.leafQs.switch_reason_other.trim().length < 10) {
          return false;
        }
      }
    }

    if (required) {
      if (type === 'boolean' && (qVal === null || typeof qVal == 'undefined'))
        return false;
      else if (type === 'text' && !qVal.trim())
        return false;
      else if (type === 'multiple_choice_single_select' && (qVal === null || typeof qVal == 'undefined' || qVal === ''))
        return false;
      else if (type === 'multiple_choice_multiple_select') {
        let oneSelected = false;
        for (let [choiceId, choiceVal] of Object.entries(qVal))
          if (choiceVal) {
            oneSelected = true;
            break;
          }
        return oneSelected;
      }
    }

    return true;
  }

  getMaskResultsList = () => {
    let mask_results_list = []
    if (this.props.store.selectedRow
      && this.props.store.selectedRow.model_output
      && this.props.store.selectedRow.model_output.mask_results) {
      mask_results_list = Array.from(this.props.store.selectedRow.model_output.mask_results)
    }
    return mask_results_list
  }

  getRemainingMasks = () => {
    let remiaing_masks_list = []
    if (this.props.store.selectedRow
      && this.props.store.selectedRow.model_output
      && this.props.store.selectedRow.model_output.rest_of_formulary_mask_results
      && this.props.store.selectedRow.model_output.oof_mask_results) {
      remiaing_masks_list = this.props.store.selectedRow.model_output.rest_of_formulary_mask_results.concat(this.props.store.selectedRow.model_output.oof_mask_results)
    }
    return remiaing_masks_list
  }

  getModelOutput() {
    return this.props.store.selectedRow.model_output;
  }

  getConfidenceScore(model_output) {
    let confidence_score = 0;
    if (model_output) {
      if (model_output.confidence_score) {
        // For ModelOutput version 3.2.3+
        confidence_score = Number(model_output.confidence_score * 100).toFixed(1)
      } else if (model_output.face_detection_score) {
        // For older ModelOutput
        confidence_score = Number(model_output.face_detection_score * 100).toFixed(1)
      }
    }
    return confidence_score
  }

  isRecommendedMask = (maskResult) => {
    if (Object.keys(this.props.store.selectedRow).length > 1) {
      return maskResult.name === this.state.selectedRecommendationMaskType
    }
    return false
  }

  isCurrentMask(maskResult) {
    if (Object.keys(this.props.store.selectedRow).length > 1) {
      return maskResult.name === this.props.store.selectedRow.current_mask_type
    }
    return false
  }

  getFormularyOptions() {
    const formulary = this.props.store.selectedRow.formulary;
    const options = [];
    if (formulary) {
      for (let i = 0; i < formulary.length; i++) {
        options.push({
          value: formulary[i],
          label: getMaskDisplayName(formulary[i]),
        });
      }
    }
    return options
  }

  setRecommendedMask = (maskResult) => {
    this.setState({
      selectedRecommendationMaskType: maskResult.name,
      selectedRecommendationMaskCushionSize: maskResult.cushion_size,
      selectedRecommendationMaskFrameSize: maskResult.frame_size,
      shouldShowChinStrapModal: maskResult.chin_strap
    })
  }

  shouldShowCompletedModal() {
    if (this.props.store.selectedRow.is_first_time_cpap) {
      return this.props.store.currentSubdomain.requiredFields.includes('date_of_service')
    } else {
      return this.props.store.currentSubdomain.requiredResupplyFields.includes('date_of_service')
    }
  }

  nonRecommendedMaskIsSelected() {
    return !this.getMaskResultsList().some((item) => this.isRecommendedMask(item))
  }

  patientIsMagnetic() {
    return this.props.store.selectedRow
      && this.props.store.selectedRow.patient_attributes
      && this.props.store.selectedRow.patient_attributes.magnet_sensitive_implants;
  }

  hoursSinceRefundDate() {
    const delta = ((Date.now() / 1000) - this.props.store.selectedRow.timestamp) / 60 / 60;
    return Number(delta.toFixed(1))
  }

  isPrescriptionSelected() {
    // Prescription mask is selected
    const prescriptionSelection = this.props.store.selectedRow.refits.filter(refit => refit.switch_reason === 'prescription')[0];

    let is_prescription = prescriptionSelection?.switched_to_mask_type === this.state.selectedRecommendationMaskType &&
      prescriptionSelection?.switched_to_mask_size === this.state.selectedRecommendationMaskCushionSize &&
      prescriptionSelection?.switched_to_mask_frame_size === this.state.selectedRecommendationMaskFrameSize;

    return is_prescription;
  }

  isNonRecommendedOptionSelected() {
    // Non-Recommended mask is selected
    const nonRecommended = this.props.store.selectedRow.refits.filter(refit => refit.switch_reason === 'non-recommended-opt')[0];
    console.log('nonRecommended: ', nonRecommended)

    let is_non_recommended = nonRecommended?.switched_to_mask_type === this.state.selectedRecommendationMaskType &&
      nonRecommended?.switched_to_mask_size === this.state.selectedRecommendationMaskCushionSize &&
      nonRecommended?.switched_to_mask_frame_size === this.state.selectedRecommendationMaskFrameSize;

    return is_non_recommended;
  }

  isFirstRecommendationSelected() {
    // First recommendation in model output is selected
    const maskResults = this.getMaskResultsList()
    let is_first_recommendation = false

    if (maskResults.length > 1) {
      let firstOption = maskResults[0]
      is_first_recommendation = firstOption.name === this.state.selectedRecommendationMaskType &&
        firstOption.cushion_size === this.state.selectedRecommendationMaskCushionSize &&
        firstOption.frame_size === this.state.selectedRecommendationMaskFrameSize;
    }

    return is_first_recommendation;
  }

  handleSwitchReason() {
    // Pt completion: switch reason / fittingway
    let is_recommendation = this.isFirstRecommendationSelected();
    let is_prescription = this.isPrescriptionSelected();
    let is_scripted = this.props.store.selectedRow.is_scripted;
    let switch_reason = is_recommendation ? "recommendation" : "technician_selection"

    if(this.props.store.selectedRow.refits[0]?.switch_reason === 'prescription'){
      switch_reason = "switched_from_prescription";
    }
    if (is_scripted) {
      switch_reason = is_prescription ? "prescription" : "switched_from_prescription";
    }

    return switch_reason
  }

  prepareClinicianSelection() {

    if (this.isNonRecommendedOptionSelected()) {
      appStore.submitNonRecommendedFeedback(this.state.nonRecommendedOption, this.state.refitFeedbackVersion)
    } else {
      const switch_reason = this.state.isScripted ? 'prescription' : this.handleSwitchReason();
      appStore.submitClinicianSelection(
        this.props.store.selectedRow.website_token,
        this.props.store.selectedRow.subdomain,
        this.state.selectedRecommendationMaskType,
        this.state.selectedRecommendationMaskCushionSize,
        this.state.selectedRecommendationMaskFrameSize,
        this.state.refitFeedbackVersion,
        switch_reason,
        this.state.chinStrapType,
        this.state.selectedRecommendationMaskTypeOther
      );
    }
    appStore.markCompleteWithMessage(this.state.dateOfService, false, this.state.isScripted);
  }

  handleCompletePatient() {
    if(this.state.isScripted){
      this.prepareClinicianSelection();
    }else if (this.state.shouldShowChinStrapModal) {
      this.setState({ showChinStrapModal: true })
    }
    else {
      const showModal = this.shouldShowCompletedModal();
      showModal ? this.setState({ showCompletedModal: true }) : this.prepareClinicianSelection();
    }
  }

  getPatientSleepPosition = memoize((values) => {
    let sleepPosition = []
    try {
      if (values) {
        for (const [key, value] of Object.entries(values)) {
          if (value) {
            sleepPosition.push(key === "sitting_up" ? "sitting up" : key);
          }
        }
      }
    } catch (error) {
      console.error("Error in getPatientSleepPosition", error);
    }
    return sleepPosition
  })

  onSharedWithChange = (e) => {
    if (!e) this.setState({ shared_with: [] })
    if (e) this.setState({ shared_with: e })
  }

  renderMagneticImplantList = memoize((isMagnetSensitive = false) => {
    return (
      <div className='px-3 mt-4' style={{ fontSize: '10px', color: isMagnetSensitive ? 'darkred' : 'black', opacity: '0.95', lineHeight: '93%' }}>
        <h3 style={{ fontSize: '10.5px', color: isMagnetSensitive ? 'darkred' : 'black', opacity: '0.9', marginBottom: '0.2rem' }}>Patient confirms that he/she, someone in the household, or a caregiver {isMagnetSensitive ? 'has' : 'do not have'} any of the following:</h3>
        {magneticImplantsListEng.join(', ')}.
      </div>
    )
  })

  renderScriptedMaskRecommendation = () => {
    const prescription = this.props.store.selectedRow.refits.filter(refit => refit.switch_reason === 'prescription')[0];

    let scriptedMaskResult = {
      name: prescription?.switched_to_mask_type,
      cushion_size: prescription?.switched_to_mask_size,
      frame_size: prescription?.switched_to_mask_frame_size,
      chin_strap: false
    }
    return (
      <div>
        <small><strong>Scripted Mask</strong></small>
        {prescription && (
          <div className='recommendedMasks-container' >
            <Row
              className={(!this.state.readOnly && !this.props.store.selectedRow.voided_at) ? "recommendationSelectRow" : 'recommendationSelectRowNoHover'}
            >
              <Col xl={6} className="mask-info mb-2 mb-xl-0" onClick={() => this.setRecommendedMask(scriptedMaskResult)}>
                <div className="mask-check">
                  {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                    <input type="radio" disabled={!this.state.model_output}
                      value={prescription?.switched_to_mask_type}
                      checked={!this.state.model_output || (this.state.model_output && this.isRecommendedMask(scriptedMaskResult))} />
                  }
                  <strong style={{
                    marginLeft: '10px',
                    color: maskIsMagnetic(prescription?.switched_to_mask_type) ? "var(--color-darkred)" : undefined
                  }}>
                    { // If scripted is 'Other' and has a name include it.
                      (prescription?.switched_to_mask_type === 'Other' && prescription?.switched_to_mask_type_other)
                        ? `Other (External): ${prescription?.switched_to_mask_type_other}`
                        : getMaskDisplayName(prescription?.switched_to_mask_type)
                    }
                  </strong>
                </div>
              </Col>
              <Col xl={6}>
                <Row className='justify-content-between mb-2'>
                  <Col lg={5} className='d-flex align-items-center'>
                    {languageOptions.cushionSize[langType]}:&nbsp;<strong>{prescription?.switched_to_mask_size}</strong>
                  </Col>
                </Row>
                <Row className='justify-content-between mb-2'>
                  <Col lg={5} className='d-flex align-items-center'>
                    {languageOptions.frameSize[langType]}:&nbsp;<strong>{prescription?.switched_to_mask_frame_size}</strong>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </div>
    )
  }

  setShowVoidModal = (bool) => this.setState({ showVoidModal: bool })

  setResetReinviteModal = (bool) => this.setState({ resetReinviteModal: bool })

  handleMaskType = (mask, isOther = false) => {
    if (isOther) {
      this.setState({ selectedRecommendationMaskType: 'Other', scriptedFormValid: false });
      if (mask !== 'Other' && mask.length > 2 && mask.trim() !== '') {
        this.setState({
          selectedRecommendationMaskType: 'Other',
          selectedRecommendationMaskTypeOther: mask,
          scriptedFormValid: true
        });
      }
    } else {
      this.setState({ selectedRecommendationMaskType: mask, scriptedFormValid: true });
    }
  };

  handleCushionSize = (e) => {
    this.state.selectedRecommendationMaskCushionSize = e ? e.value : '';
  }

  handleFrameSize = (e) => {
    this.state.selectedRecommendationMaskFrameSize = e ? e.value : '';
  }

  onEditIdSave = async () => {
    const { patientId, subdomain } = this.props.store.selectedRow
    // If editing/leaving to the same value, do not do anything
    if (this.state.patientId == patientId) {
      this.setState({ patientIdEdit: false })
      // if not valid id, throw error before checking if existing
    } else if (!validatePatientID(this.state.patientId)) {
      this.setState((prev) => ({
        errors: { ...prev.errors, id: true }
      }))
    } else {
      const { data } = await appStore.checkExistingPatient({ id: this.state.patientId, subdomain })
      // Safety check if edited id is not same with existing(selected) pt. If so, api will return is_exist as true.
      if (this.state.patientId !== patientId && !data?.is_exist) {
        // Clean up error if there was.
        if (this.state.errors.id) {
          this.setState((prev) => ({
            errors: { ...prev.errors, id: false }
          }))
        }
        this.setState({ patientIdEdit: false })
        appStore.editData(
          {
            patient_id: this.state.patientId,
            patient_subdomain: this.props.store.selectedRow.subdomain,
            website_token: this.props.store.selectedRow.website_token,
          },
          'patientId',
          'patient_id',
        ).then(() => appStore.loadPatients())
      } else if (this.state.patientId !== patientId && data?.is_exist) {
        toast.error('This id already exists!')
        this.setState((prev) => ({
          errors: { ...prev.errors, id: true }
        }))
      }
    }
  }

  onEditSave = async (field, fieldEdit, patientType) => {
    if (!this.state[field].trim()) {
      this.setState((prev) => ({
        errors: { ...prev.errors, [field]: true }
      }))
    } else {
      // Clean up if there was error already
      if (this.state.errors[field]) {
        this.setState((prev) => ({
          errors: { ...prev.errors, [field]: false }
        }))
      }
      this.setState({ [fieldEdit]: false })
      await appStore.editData(
        {
          [field]: this.state[field],
          patient_subdomain: this.props.store.selectedRow.subdomain,
          website_token: this.props.store.selectedRow.website_token
        },
        patientType,
        `${field}`
      )
      appStore.loadPatients()
    }
  }

  reprocessPatient = async (updatedFormulary) => {
    this.props.store.selectedRow.patient_attributes.formulary = updatedFormulary
    const { website_token } = this.props.store.selectedRow
    await appStore.reprocessPatient({ website_token })
    if (this.props.store.selectedRow.model_output?.mask_results) {
      // We set recommended mask to trigger re-render and see new results
      this.setRecommendedMask(this.props.store.selectedRow.model_output.mask_results[0])
    }
  }

  handlePhoneInputChange = (value) => {
    // Code with no delay
    this.props.store.twilioLookupLoading = true
    this.setState({ phoneNumber: value ? value : '' })
    // Call the debounced version
    this.debouncedPhoneInputChange(value)
  }

  debouncedPhoneInputChange = async (value) => {
    if (!value && this.state.inviteType === 'invite_email') {
      this.setState({ phoneNumberError: numberErrorEnum.success })
    } else if (!value || !isPossiblePhoneNumber(value)) {
      this.setState({ phoneNumberError: numberErrorEnum.invalidNum })
    } else if (isPossiblePhoneNumber(value)) {
      const data = await appStore.twilioLookupPhone(this.state.phoneNumber)
      if (data) {
        const { numberLookupStatus, numberLookupCode, numberIsSMSReachable } = data
        if (numberLookupStatus == 200 && numberIsSMSReachable) {
          this.setState({
            phoneNumberError: numberErrorEnum.success,
            isValidPhoneNumber: true
          })
        } else if (numberLookupStatus != 200) {
          // Invalid number
          this.setState({ phoneNumberError: numberErrorEnum.invalidNum })
        } else if (!numberIsSMSReachable) {
          // SMS unreachable number
          this.setState({ phoneNumberError: numberErrorEnum.notMobileNum })
        }
      }
    }
    this.props.store.twilioLookupLoading = false
  }

  resetPhoneEdit = () => {
    this.setState({
      phoneEdit: false,
      phoneNumberError: 0,
      isValidPhoneNumber: false,
      phoneInputCountry: 'US'
    })
  }

  handleRadioButtonChange = (event) => {
    const { value } = event.target;
    this.setState(
      {
        selectedOption: value,
        pressure: null,
      }, () => {
        this.updatePressureOptions();
      }
    );
  };

  updatePressureOptions = () => {
    const { selectedOption } = this.state;
    if (!selectedOption) this.setState({ pressureOption: [] })
    let pressureOption = [];

    if (selectedOption === 'pressureCPAP' || selectedOption === 'pressureBIPAP' || selectedOption === 'pressureASVST') {
      for (let i = 5; i <= 19; i++) {
        pressureOption.push({ value: i.toString(), label: i.toString() });
      }
      pressureOption.push({ value: '> 20', label: '> 20' });
    } else if (selectedOption === 'pressureCPAPAP') {
      pressureOption.push({ value: '<16', label: 'Auto with max pressure <16' });
      pressureOption.push({ value: '>=16', label: 'Auto with max pressure >=16' });
    }
    this.setState({ pressureOption });
  };

  getPapValue = () => {
    if (this.state.selectedOption === 'pressureCPAP') return 'CPAP'
    else if (this.state.selectedOption === 'pressureASVST') return 'ASV/ST'
    else if (this.state.selectedOption === 'pressureCPAPAP') return 'AutoPAP'
    else if (this.state.selectedOption === 'pressureBIPAP') return 'BiPAP'
    else return null;
  }

  getPapValueStore = (papValue) => {
    if (papValue === 'CPAP') return 'pressureCPAP'
    else if (papValue === 'ASV/ST') return 'pressureASVST'
    else if (papValue === 'AutoPAP') return 'pressureCPAPAP'
    else if (papValue === 'BiPAP') return 'pressureBIPAP'
    else return null;
  }

  // Deprecated 
  // getArchivedMasks = () => {
  //   const archivedMasks = [];
  //   Object.keys(store.maskData).forEach((maskType) => {
  //     // store.maskData[maskType].forEach((mask) => {
  //     store.maskData.forEach((mask) => {
  //       if (mask.is_archived) {
  //         archivedMasks.push(JSON.parse(JSON.stringify(mask?.name)));
  //       }
  //     });
  //   });
  //   return archivedMasks;
  // };

  // getMaskTypeChoices = (qId, value, refitParentQs) => {
  //   const archivedMasks = this.getArchivedMasks();
  //   if (qId === 'switched_to_mask_size') {
  //     return getCushionSizesByName(refitParentQs?.switched_to_mask_type);
  //   } else {
  //     return value.choices.map((ch) => {
  //       if (!archivedMasks.includes(ch)) {
  //         return {
  //           value: ch,
  //           label: ch
  //         };
  //       }
  //       return null; //null is symbolic here. It will be filtered.
  //     }).filter((ch) => ch !== null);
  //   }
  // };

  clearMessageDelivery = (prevType) => {
    if (prevType === 'email') {
      this.setState({ inviteType: 'invite_email' });
    } else if (prevType === 'text') {
      this.setState({ inviteType: 'invite_text' });
    }
  }

  checkRefitExists = (maskName) => {
    appStore.checkExistingRefit(maskName)
      .then((res) => {
        if (res?.data?.is_exist) {
          this.setState({ doesRefitExist: true, switch_reason: res.data.switch_reason })
        } else {
          this.setState({ doesRefitExist: false, switch_reason: 'Selection' })
        }
      })
      .catch((error) => {
        console.error("/rest/check_existing_refit failed with error: ", error);
      });
  }

  handleRefitSave = (refitAnswers) => {
    if (this.state.doesRefitExist) {
      this.setState({ refitFeedbackAnswers: refitAnswers, showRefitConfirmationModal: true });
    } else if (this.props.store.selectedRow.patient_attributes.magnet_sensitive_implants) {
      this.setState({ refitFeedbackAnswers: refitAnswers });
      const isMaskMagnetic = maskIsMagnetic(refitAnswers.switched_to_mask_type);
      if (isMaskMagnetic) {
        this.setState({ showMagneticRefitModal: true })
      } else {
        appStore.submitRefitFeedback(refitAnswers, this.state.refitFeedbackVersion);
        appStore.loadPatients();
      }
    } else {
      this.setState({ refitFeedbackAnswers: refitAnswers });
      appStore.submitRefitFeedback(refitAnswers, this.state.refitFeedbackVersion);
      appStore.loadPatients();
    }
  }

  handleOtherMaskSave = async (refitAnswers) => {
    await appStore.submitNonRecommendedFeedback(refitAnswers, this.state.refitFeedbackVersion);
    appStore.markCompleteWithMessage(this.state.dateOfService, true, this.state.isScripted);
  }

  handleRefitCancel = () => {
    this.setState({ feedback_form_visible: false, doesRefitExist: false });
  }

  handleOtherMaskCancel = () => {
    this.setState({ feedback_form_visible: false, doesRefitExist: false, showNROModal: false });
  }

  setResetReinviteUnsubscribedModal = (bool) => this.setState({ resetReinviteUnsubscribedModal: bool })

  handleUnsubscribedReinvite = () => {
    let createdBy = this.props.store.selectedRow?.created_by
    let assignedTo = this.props.store.selectedRow?.patient_attributes?.patient_assigned_to
    let username = this.props.store.currentUser.username

    if ((createdBy === username) || (assignedTo === username)) {
      this.setResetReinviteUnsubscribedModal(true)
    } else {
      toast.info('Patient is unsubscribed and can not be re-invited.');
    }
  }

  renderUnsubscribeReinvite = () => {
    const email_unsubscribed = this.props.store.selectedRow.patient_attributes?.email_unsubscribed_at && this.props.store.selectedRow.patient_attributes.invite_channel === "invite_email"
    const sms_unsubscribed = this.props.store.selectedRow.patient_attributes?.sms_unsubscribed_at && this.props.store.selectedRow.patient_attributes.invite_channel === "invite_text"
    const has_email_alternative = !this.props.store.selectedRow.patient_attributes?.email_unsubscribed_at && this.props.store.selectedRow?.email
    const has_phone_alternative = !this.props.store.selectedRow.patient_attributes?.sms_unsubscribed_at && this.props.store.selectedRow?.phone
    const both_sms_email_unsuscribed = this.props.store.selectedRow.patient_attributes?.email_unsubscribed_at && this.props.store.selectedRow.patient_attributes?.sms_unsubscribed_at

    return (
      <>
        <ModalHeader style={{ padding: '0.8rem 1rem' }} toggle={() => this.setResetReinviteUnsubscribedModal(false)}>
          <i className='fa fa-exclamation-circle' style={{ color: "goldenrod", fontSize: "1.5rem", marginRight: "10px" }} />
          Unsubscribed Patient
        </ModalHeader>
        <ModalBody>
          {(has_phone_alternative || has_email_alternative) ?
            (
              <p>
                This patient is currently unsubscribed from {email_unsubscribed ? 'emails' : 'text messages'}.
                <span> Would you like to <b>Reset & Reinvite</b> the patient via {has_phone_alternative ? 'text message' : 'email'}?</span>
              </p>
            ) : (
              <>
                <p>
                  {
                    both_sms_email_unsuscribed ?
                      <span>This patient is currently unsubscribed from both email and text messages.</span>
                      :
                      <span>This patient is currently unsubscribed from {email_unsubscribed ? 'emails' : 'text messages'} and do not have an alternative method to contact with.</span>

                  }
                </p>
                <p>Please, update patient's contact information on <b>Profile tab</b> to enable Reset and Re-invite for this patient.</p>
              </>
            )
          }
        </ModalBody>
        <ModalFooter style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          <Button
            color='secondary'
            onClick={() => {
              this.setResetReinviteUnsubscribedModal(false);
            }}>
            {has_email_alternative || has_phone_alternative ? 'Cancel' : 'Close'}
          </Button>
          {
            (has_email_alternative || has_phone_alternative) && (
              <Button
                color='primary'
                disabled={(email_unsubscribed && !has_phone_alternative) || (sms_unsubscribed && !has_email_alternative)}
                onClick={() => {
                  let inviteChannel = has_email_alternative ? 'email' : 'text'
                  appStore.reInviteUnsubscribed(inviteChannel);
                  this.setResetReinviteUnsubscribedModal(false);
                }}>
                Yes (Reset & Reinvite)
              </Button>
            )
          }
        </ModalFooter>
      </>
    )
  }

  setNonRecommendedOption = (nonRecommendedMask) => { this.setState({ nonRecommendedOption: nonRecommendedMask }) }

  shouldShowNROModal = (showNRO) => { this.setState({ showNROModal: showNRO }) }

  render() {
    const langType = getLangType();
    let mask_results_list = this.getMaskResultsList();
    let remaining_masks_list = this.getRemainingMasks();
    let model_output = this.getModelOutput();
    const ptSleepPositions = this.getPatientSleepPosition(this?.props?.store?.selectedRow?.patient_attributes?.sleep_position);
    let beforeRecallSleepPositions = [];
    const formularyNameOptions = appStore.getFormularyNameOptions();

    if (this?.props?.store?.selectedRow?.patient_attributes?.is_recall && this?.props?.store?.selectedRow?.patient_attributes?.answered_recall_question && this.props.store.selectedRow.patient_attributes.recall_question_answer && this.props.store.selectedRow.before_recall_attributes) {
      beforeRecallSleepPositions = this.getPatientSleepPosition(this?.props?.store?.selectedRow?.patient_attributes?.sleep_position);
    }
    // Activate this line once new confidence score is finalized
    let confidence_score = this.getConfidenceScore(model_output);
    if (this.state.feedback_form_visible) {
      return (
        <Container fluid={this.state.readOnly}>

          <PatientRefitForm
            state={this.state}
            checkRefitExists={this.checkRefitExists}
            handleRefitSave={this.handleRefitSave}
            handleRefitCancel={this.handleRefitCancel}
          />

          {/* refit confirmation modal */}
          <Modal
            isOpen={this.state.showRefitConfirmationModal}
            centered
          >
            <Card style={{ 'marginBottom': '0' }}>
              <CardHeader>
                <h5 class="mb-0">Patient Refit Confirmation</h5>
              </CardHeader>
              <CardBody>
                A <b>{this.state.switch_reason}</b> with this mask has already been submitted. Are you sure you want to Refit?
                <div className='mt-4 d-flex justify-content-end'>
                  <Button
                    className='mr-2'
                    color='danger'
                    onClick={() => this.setState({ showRefitConfirmationModal: false })}
                  >
                    {languageOptions.close[langType]}
                  </Button>
                  <Button
                    color='primary'
                    onClick={() => {
                      appStore.submitRefitFeedback(this.state.refitFeedbackAnswers, this.state.refitFeedbackVersion);
                      this.setState({ showRefitConfirmationModal: false })
                      appStore.loadPatients();
                    }}
                  >
                    {languageOptions.save[langType]}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Modal>

          {/* magnetic refit modal */}
          <Modal
            isOpen={this.state.showMagneticRefitModal}
            centered
          >
            <Card style={{ 'marginBottom': '0' }}>
              <CardHeader>
                <h5 class="mb-0">Patient Refit Confirmation</h5>
              </CardHeader>
              <CardBody>
                This patient has a <b>magnetic-sensitive implant</b>. Are you sure you want to refit into a mask that includes magnets?
                <div className='mt-4 d-flex justify-content-end'>
                  <Button
                    className='mr-2'
                    color='danger'
                    onClick={() => this.setState({ showMagneticRefitModal: false })}
                  >
                    {languageOptions.close[langType]}
                  </Button>
                  <Button
                    color='primary'
                    onClick={() => {
                      appStore.submitRefitFeedback(this.state.refitFeedbackAnswers, this.state.refitFeedbackVersion);
                      this.setState({ showMagneticRefitModal: false })
                      appStore.loadPatients();
                    }}
                  >
                    {languageOptions.save[langType]}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Modal>

        </Container>
      )
    }

    const isSameMaskWithSameSize = (
      this.props.store.selectedRow.current_mask_type === this.state.selectedRecommendationMaskType &&
      (this.state.selectedRecommendationMaskCushionSize === this.props.store.selectedRow.current_mask_size &&
        this.state.selectedRecommendationMaskFrameSize === this.props.store.selectedRow.current_mask_frame_size))
    return (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLinkRS
              className={classnames({ active: this.props.store.activeTab === 'masks' })}
              onClick={() => appStore.changeActiveTab('masks')}
            >
              Masks
            </NavLinkRS>
          </NavItem>
          <NavItem>
            <NavLinkRS
              className={classnames({ active: this.props.store.activeTab === 'patient-profile' })}
              onClick={() => { appStore.changeActiveTab('patient-profile') }}
            >
              Profile
            </NavLinkRS>
          </NavItem>
          <NavItem>
            <NavLinkRS
              className={classnames({ active: this.props.store.activeTab === 'pt-messages' })}
              onClick={() => {
                appStore.changeActiveTab('pt-messages')
                if (this.props.store.settings.patientMessagingEnabled &&
                  !this.props.store.ptMessagesFromThreadLoading
                ) {
                  appStore.getPatientMessagesFromThread(this.props.store.selectedRow.website_token, true);
                }
              }}
            >
              Messages
            </NavLinkRS>
          </NavItem>
          <NavItem>
            <NavLinkRS
              className={classnames({ active: this.props.store.activeTab === 'alerts' })}
              onClick={() => {
                appStore.changeActiveTab('alerts')
                if (!this.props.store.isPtAlertsCalled) {
                  appStore.loadPatientAlerts(this.props.store.selectedRow.website_token)
                }
              }}
            >
              Survey - Alerts
            </NavLinkRS>
          </NavItem>
          <NavItem>
            <NavLinkRS
              className={classnames({ active: this.props.store.activeTab === 'compliance-data' })}
              onClick={() => {
                appStore.changeActiveTab('compliance-data')
              }}
            >
              Compliance Data
            </NavLinkRS>
          </NavItem>
          <NavItem>
            <NavLinkRS
              className={classnames({ active: this.props.store.activeTab === 'patient-history' })}
              onClick={() => { appStore.changeActiveTab('patient-history') }}
            >
              History
            </NavLinkRS>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.props.store.activeTab}>
          <TabPane tabId="masks">
            <Container className="tabpane-content" fluid={this.state.readOnly}>
              {this.props.store.scriptedMaskLoading ? <Loading /> :
                <>
                  {this.props.store.selectedRow.is_scripted && !this.props.store.selectedRow.model_output &&
                    <Card>
                      <CardHeader><strong>{languageOptions.pdMaskDetails[langType]}</strong></CardHeader>
                      <CardBody>
                        {this.renderScriptedMaskRecommendation()}
                        {mask_results_list.length === 0 && !this.props.store.selectedRow.complete && (
                          <Button
                            onClick={() => {
                              this.setState({ scriptedForm: true });
                              this.setState({ scriptedMaskEdit: false });
                            }}
                            color='primary'
                            size='large'
                            className='mr-2'
                            hidden={!this.state.scriptedMaskEdit}
                            style={{ display: 'block', marginTop: '0.5rem' }}
                          >
                            {languageOptions.ptEditScriptedMask[langType]}
                          </Button>)
                        }
                        {this.state.scriptedForm && (
                          <>
                            <ScriptedMaskForm
                              handleMaskType={this.handleMaskType}
                              handleCushionSize={this.handleCushionSize}
                              handleFrameSize={this.handleFrameSize}
                              selectedMaskName={this.state.selectedRecommendationMaskType}
                            />
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Button
                                color='primary'
                                disabled={!this.state.scriptedFormValid}
                                onClick={() => {
                                  appStore.editData(
                                    {
                                      is_scripted: this.props.store.selectedRow.is_scripted,
                                      scripted_mask: {
                                        version: this.state.refitFeedbackVersion,
                                        answers: {
                                          switch_reason: "prescription",
                                          switched_to_mask_type: this.state.selectedRecommendationMaskType,
                                          switched_to_mask_size: this.state.selectedRecommendationMaskCushionSize,
                                          switched_to_mask_frame_size: this.state.selectedRecommendationMaskFrameSize,
                                          chin_strap_dispensed: this.state.chinStrapType,
                                          ...(this.state.selectedRecommendationMaskTypeOther !== null && { switched_to_mask_type_other: this.state.selectedRecommendationMaskTypeOther })
                                        }
                                      },
                                      patient_subdomain: this.props.store.selectedRow.subdomain,
                                      website_token: this.props.store.selectedRow.website_token,
                                    },
                                    'scriptedMask',
                                    'scripted_mask',
                                  ).then(() => appStore.loadPatients())
                                  this.setState({ scriptedForm: false });
                                  this.setState({ scriptedMaskEdit: true });
                                }}
                              >
                                {languageOptions.save[langType]}
                              </Button>
                              <Button
                                style={{ marginLeft: '0.5rem' }}
                                color='danger'
                                onClick={() => {
                                  this.setState(
                                    {
                                      selectedRecommendationMaskType: this.props.store.selectedRow.current_mask_type,
                                      selectedRecommendationMaskCushionSize: this.props.store.selectedRow.current_mask_size,
                                      selectedRecommendationMaskFrameSize: this.props.store.selectedRow.current_mask_frame_size,
                                    });

                                  this.setState({ scriptedForm: false });
                                  this.setState({ scriptedMaskEdit: true });
                                  this.setState({ scriptedFormValid: false });
                                }}
                              >
                                {languageOptions.cancel[langType]}
                              </Button>
                            </div>
                          </>
                        )}
                      </CardBody>
                    </Card>
                  }

                  {(!this.props.store.selectedRow.is_scripted || (this.props.store.selectedRow.is_scripted && this.props.store.selectedRow.model_output)) &&
                    <Card>
                      <CardHeader>
                        {['super_administrator', 'administrator'].includes(this.props.store.currentUser.userRole) && this.props.store.currentSubdomain.showRemainingMasks && remaining_masks_list.length > 0 ?
                          <>
                            {model_output && (
                              <div className='d-flex justify-content-end'>
                                <strong>
                                  {`${languageOptions.confidence[langType]} ${model_output.confidence}`}
                                </strong>
                                <ToolTip
                                  id='confidence-score'
                                  position='bottom'
                                  content={
                                    (<span>
                                      {languageOptions.confidenceScore[langType]} <strong>{confidence_score}</strong>
                                      <br />
                                      {languageOptions.confidenceScoreOptimal[langType]}
                                      <br />
                                      {languageOptions.confidenceScoreMedium[langType]}
                                      <br />
                                      {languageOptions.confidenceScoreLow[langType]}
                                      <br />
                                      {languageOptions.confidenceScoreDescPartOne[langType]}
                                      <br />
                                      {languageOptions.confidenceScoreDescPartTwo[langType]}
                                    </span>)
                                  }
                                />
                              </div>
                            )}
                            <Nav tabs>
                              <NavItem>
                                <NavLinkRS
                                  className={classnames({ active: this.props.store.activeMasksTab === 'recommended' })}
                                  onClick={() => {
                                    appStore.changeActiveMasksTab('recommended')
                                  }}
                                >
                                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <strong>{languageOptions.pdMaskDetails[langType]}</strong>
                                  </div>
                                </NavLinkRS>
                              </NavItem>
                              <NavItem>
                                <NavLinkRS
                                  className={classnames({ active: this.props.store.activeMasksTab === 'remainingMasks' })}
                                  onClick={() => {
                                    appStore.changeActiveMasksTab('remainingMasks')
                                  }}
                                >
                                  Remaining Masks
                                </NavLinkRS>
                              </NavItem>
                            </Nav>
                          </> :
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>{languageOptions.pdMaskDetails[langType]}</strong>
                            {model_output && (
                              <div>
                                <strong>
                                  {`${languageOptions.confidence[langType]} ${model_output.confidence}`}
                                </strong>
                                <ToolTip
                                  id='confidence-score'
                                  position='bottom'
                                  content={
                                    (<span>
                                      {languageOptions.confidenceScore[langType]} <strong>{confidence_score}</strong>
                                      <br />
                                      {languageOptions.confidenceScoreOptimal[langType]}
                                      <br />
                                      {languageOptions.confidenceScoreMedium[langType]}
                                      <br />
                                      {languageOptions.confidenceScoreLow[langType]}
                                      <br />
                                      {languageOptions.confidenceScoreDescPartOne[langType]}
                                      <br />
                                      {languageOptions.confidenceScoreDescPartTwo[langType]}
                                    </span>)
                                  }
                                />
                              </div>
                            )}
                          </div>}
                      </CardHeader>
                      <CardBody>
                        {mask_results_list.length === 0 && (
                          model_output?.error ?
                            <Row style={{ height: '100px' }}>
                              <Col sm='12'>
                                <span>
                                  <b style={{ color: "var(--color-darkred)" }}> Scan Error: </b>
                                  {model_output.error === "Empty mask results" ? "Couldn't find any valid mask results for the patient in the Formulary" : model_output.error}
                                </span>
                              </Col>
                            </Row>
                            :
                            <Row style={{ height: '100px' }}>
                              <Col sm='12'>
                                {this.props.store.selectedRow.voided_at ? <Button disabled={true} color='danger'>{languageOptions.setupCancelled[langType]}</Button> : languageOptions.waitForResults[langType]}
                              </Col>
                            </Row>
                        ) || (
                            <FormGroup>
                              {remaining_masks_list.length > 0 && this.props.store.activeMasksTab === 'remainingMasks' ?
                                <div>
                                  <small><strong>{languageOptions.otherFormularyMasks[langType]}</strong></small>
                                  {this.props.store.selectedRow.model_output.rest_of_formulary_mask_results.map((maskResult, i) => {
                                    const { product_id, isFitpack, headgearId } = getProductSKU(maskResult)
                                    return (
                                      <div className='recommendedMasks-container' key={maskResult.product_id + i + maskResult.name} >
                                        <small><strong>{maskResult.subtitle}</strong></small>
                                        <Row
                                          className={(!this.state.readOnly && !this.props.store.selectedRow.voided_at) ? "recommendationSelectRow" : 'recommendationSelectRowNoHover'}
                                        >
                                          <Col xl={6}
                                            className="mask-info mb-2 mb-xl-0"
                                            onClick={() => this.setRecommendedMask(maskResult)}
                                          >
                                            <div className="mask-check">
                                              {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                                                <input type="radio"
                                                  value={maskResult.name}
                                                  checked={this.isRecommendedMask(maskResult)} />
                                              }
                                              <strong style={{
                                                marginLeft: '10px',
                                                color: maskIsMagnetic(maskResult.name) ? "var(--color-darkred)" : undefined
                                              }}>
                                                {getMaskDisplayName(maskResult.name)}
                                              </strong>
                                            </div>
                                            {maskResult.chin_strap && (
                                              <Label className="text-secondary font12" style={{ marginLeft: '23px' }}>{languageOptions.canUseChinStrap[langType]}</Label>
                                            )}
                                          </Col>
                                          <Col xl={6}>
                                            <Row className='justify-content-between mb-2'>
                                              <Col lg={5} className='d-flex align-items-center'>
                                                {languageOptions.cushionSize[langType]}:&nbsp;<strong>{maskResult.cushion_size}</strong>
                                                {maskResult.cushion_size_alternate &&
                                                  ((getMaskNumCushionSizes(maskResult.name) <= 2 && Math.abs(maskResult.cushion_size_margin) < 5.0) || (getMaskNumCushionSizes(maskResult.name) > 2 && Math.abs(maskResult.cushion_size_margin) < 2.0)) && (
                                                    <ToolTip
                                                      id={'cushion-size-alternate-tooltip-' + maskResult.name}
                                                      content={<span>A size <strong>{maskResult.cushion_size_alternate}</strong> cushion may also be appropriate for this patient</span>}
                                                      iconType={maskResult.cushion_size_margin > 0 ? 'fa-arrow-alt-circle-down' : 'fa-arrow-alt-circle-up'}
                                                    />
                                                  )}
                                              </Col>
                                              <Col lg={7}>
                                                {this.state.selectedRecommendationMaskType === maskResult.name &&
                                                  <Row className='align-items-center'>
                                                    <Col xs={6} xl={7} className='p-xl-0'>Cushion Change:</Col>
                                                    <Col xs={6} xl={5} className='p-xl-0'><Select
                                                      isDisabled={this.props.store.selectedRow.voided_at}
                                                      onChange={(e) => this.setState({ selectedRecommendationMaskCushionSize: e.value })}
                                                      options={getMaskCushionSize(maskResult.name)}
                                                      defaultValue={this.isCurrentMask(maskResult) ? { value: this.state.selectedRecommendationMaskCushionSize, label: this.state.selectedRecommendationMaskCushionSize } : { value: maskResult.cushion_size, label: maskResult.cushion_size }} />
                                                    </Col>
                                                  </Row>
                                                }
                                              </Col>
                                            </Row>
                                            <Row className='justify-content-between mb-2'>
                                              <Col lg={5} className='d-flex align-items-center'>
                                                {languageOptions.frameSize[langType]}:&nbsp;<strong>{maskResult.frame_size}</strong>
                                              </Col>
                                              <Col lg={7}>
                                                {this.state.selectedRecommendationMaskType === maskResult.name &&
                                                  <Row className='align-items-center'>
                                                    <Col xs={6} xl={7} className='p-xl-0'>Frame Change:</Col>
                                                    <Col xs={6} xl={5} className='p-xl-0'><Select
                                                      isDisabled={this.props.store.selectedRow.voided_at}
                                                      options={getMaskFrameSize(maskResult.name)}
                                                      defaultValue={this.isCurrentMask(maskResult) ? { label: this.state.selectedRecommendationMaskFrameSize, value: this.state.selectedRecommendationMaskFrameSize } : { label: maskResult.frame_size, value: maskResult.frame_size }}
                                                      onChange={(e) => this.setState({ selectedRecommendationMaskFrameSize: e.value })}
                                                    />
                                                    </Col>
                                                  </Row>}
                                              </Col>
                                            </Row>
                                            <span>
                                              {languageOptions.productID[langType]}: <strong>{headgearId ? `${product_id}+${headgearId}` : product_id}</strong>
                                              {isFitpack && (
                                                <ToolTip
                                                  id={'fitpack-tooltip-' + maskResult.name}
                                                  content={<span>This SKU is part of a <strong>FitPack</strong>.</span>}
                                                  iconType={'fa-info-circle'}
                                                />
                                              )}
                                              {maskResult.name === 'ResMed P10' && (
                                                <ToolTip
                                                  id={'resmed-p10-tooltip-' + maskResult.name}
                                                  content={<span>If you choose <strong>Pink</strong> or <strong>XS size</strong> use SKU: 62910.</span>}
                                                  iconType={'fa-info-circle'}
                                                />
                                              )}
                                            </span>
                                          </Col>
                                        </Row>
                                      </div>
                                    )
                                  })}

                                  <small><strong>{languageOptions.outOfFormularyMasks[langType]}</strong></small>
                                  {this.props.store.selectedRow.model_output.oof_mask_results.map((maskResult, i) => {
                                    const { product_id, isFitpack, headgearId } = getProductSKU(maskResult)
                                    return (
                                      <div className='recommendedMasks-container' key={maskResult.product_id + i + maskResult.name} >
                                        <small><strong>{maskResult.subtitle}</strong></small>
                                        <Row
                                          className={(!this.state.readOnly && !this.props.store.selectedRow.voided_at) ? "recommendationSelectRow" : 'recommendationSelectRowNoHover'}
                                        >
                                          <Col xl={6}
                                            className="mask-info mb-2 mb-xl-0"
                                            onClick={() => this.setRecommendedMask(maskResult)}
                                          >
                                            <div className="mask-check">
                                              {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                                                <input type="radio"
                                                  value={maskResult.name}
                                                  checked={this.isRecommendedMask(maskResult)} />
                                              }
                                              <strong style={{
                                                marginLeft: '10px',
                                                color: maskIsMagnetic(maskResult.name) ? "var(--color-darkred)" : undefined
                                              }}>
                                                {getMaskDisplayName(maskResult.name)}
                                              </strong>
                                            </div>
                                            {maskResult.chin_strap && (
                                              <Label className="text-secondary font12" style={{ marginLeft: '23px' }}>{languageOptions.canUseChinStrap[langType]}</Label>
                                            )}
                                          </Col>
                                          <Col xl={6}>
                                            <Row className='justify-content-between mb-2'>
                                              <Col lg={5} className='d-flex align-items-center'>
                                                {languageOptions.cushionSize[langType]}:&nbsp;<strong>{maskResult.cushion_size}</strong>
                                                {maskResult.cushion_size_alternate &&
                                                  ((getMaskNumCushionSizes(maskResult.name) <= 2 && Math.abs(maskResult.cushion_size_margin) < 5.0) || (getMaskNumCushionSizes(maskResult.name) > 2 && Math.abs(maskResult.cushion_size_margin) < 2.0)) && (
                                                    <ToolTip
                                                      id={'cushion-size-alternate-tooltip-' + maskResult.name}
                                                      content={<span>A size <strong>{maskResult.cushion_size_alternate}</strong> cushion may also be appropriate for this patient</span>}
                                                      iconType={maskResult.cushion_size_margin > 0 ? 'fa-arrow-alt-circle-down' : 'fa-arrow-alt-circle-up'}
                                                    />
                                                  )}
                                              </Col>
                                              <Col lg={7}>
                                                {this.state.selectedRecommendationMaskType === maskResult.name &&
                                                  <Row className='align-items-center'>
                                                    <Col xs={6} xl={7} className='p-xl-0'>Cushion Change:</Col>
                                                    <Col xs={6} xl={5} className='p-xl-0'><Select
                                                      isDisabled={this.props.store.selectedRow.voided_at}
                                                      onChange={(e) => this.setState({ selectedRecommendationMaskCushionSize: e.value })}
                                                      options={getMaskCushionSize(maskResult.name)}
                                                      defaultValue={this.isCurrentMask(maskResult) ? { value: this.state.selectedRecommendationMaskCushionSize, label: this.state.selectedRecommendationMaskCushionSize } : { value: maskResult.cushion_size, label: maskResult.cushion_size }} />
                                                    </Col>
                                                  </Row>
                                                }
                                              </Col>
                                            </Row>
                                            <Row className='justify-content-between mb-2'>
                                              <Col lg={5} className='d-flex align-items-center'>
                                                {languageOptions.frameSize[langType]}:&nbsp;<strong>{maskResult.frame_size}</strong>
                                              </Col>
                                              <Col lg={7}>
                                                {this.state.selectedRecommendationMaskType === maskResult.name &&
                                                  <Row className='align-items-center'>
                                                    <Col xs={6} xl={7} className='p-xl-0'>Frame Change:</Col>
                                                    <Col xs={6} xl={5} className='p-xl-0'><Select
                                                      isDisabled={this.props.store.selectedRow.voided_at}
                                                      options={getMaskFrameSize(maskResult.name)}
                                                      defaultValue={this.isCurrentMask(maskResult) ? { label: this.state.selectedRecommendationMaskFrameSize, value: this.state.selectedRecommendationMaskFrameSize } : { label: maskResult.frame_size, value: maskResult.frame_size }}
                                                      onChange={(e) => this.setState({ selectedRecommendationMaskFrameSize: e.value })}
                                                    />
                                                    </Col>
                                                  </Row>}
                                              </Col>
                                            </Row>
                                            <span>
                                              {languageOptions.productID[langType]}: <strong>{headgearId ? `${product_id}+${headgearId}` : product_id}</strong>
                                              {isFitpack && (
                                                <ToolTip
                                                  id={'fitpack-tooltip-' + maskResult.name}
                                                  content={<span>This SKU is part of a <strong>FitPack</strong>.</span>}
                                                  iconType={'fa-info-circle'}
                                                />
                                              )}
                                              {maskResult.name === 'ResMed P10' && (
                                                <ToolTip
                                                  id={'resmed-p10-tooltip-' + maskResult.name}
                                                  content={<span>If you choose <strong>Pink</strong> or <strong>XS size</strong> use SKU: 62910.</span>}
                                                  iconType={'fa-info-circle'}
                                                />
                                              )}
                                            </span>
                                          </Col>
                                        </Row>
                                      </div>
                                    )
                                  })}
                                </div> :
                                <>
                                  {this.props.store.selectedRow.is_scripted && this.renderScriptedMaskRecommendation()}
                                  <small><strong>{languageOptions.recommendedOptions[langType]}</strong></small>
                                  {mask_results_list.map((maskResult, i) => {
                                    const { product_id, isFitpack, headgearId } = getProductSKU(maskResult)
                                    return (
                                      <div className='recommendedMasks-container' key={maskResult.product_id + i + maskResult.name} >
                                        <small><strong>{maskResult.subtitle}</strong></small>
                                        <Row
                                          className={(!this.state.readOnly && !this.props.store.selectedRow.voided_at) ? "recommendationSelectRow" : 'recommendationSelectRowNoHover'}
                                        >
                                          <Col xl={6}
                                            className="mask-info mb-2 mb-xl-0"
                                            onClick={() => this.setRecommendedMask(maskResult)}
                                          >
                                            <div className="mask-check">
                                              {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                                                <input type="radio"
                                                  value={maskResult.name}
                                                  checked={this.isRecommendedMask(maskResult)} />
                                              }
                                              <strong style={{
                                                marginLeft: '10px',
                                                color: maskIsMagnetic(maskResult.name) ? "var(--color-darkred)" : undefined
                                              }}>
                                                {getMaskDisplayName(maskResult.name)}
                                              </strong>
                                            </div>
                                            {maskResult.chin_strap && (
                                              <Label className="text-secondary font12" style={{ marginLeft: '23px' }}>{languageOptions.canUseChinStrap[langType]}</Label>
                                            )}
                                          </Col>
                                          <Col xl={6}>
                                            <Row className='justify-content-between mb-2'>
                                              <Col lg={5} className='d-flex align-items-center'>
                                                {languageOptions.cushionSize[langType]}:&nbsp;<strong>{maskResult.cushion_size}</strong>
                                                {maskResult.cushion_size_alternate &&
                                                  ((getMaskNumCushionSizes(maskResult.name) <= 2 && Math.abs(maskResult.cushion_size_margin) < 5.0) || (getMaskNumCushionSizes(maskResult.name) > 2 && Math.abs(maskResult.cushion_size_margin) < 2.0)) && (
                                                    <ToolTip
                                                      id={'cushion-size-alternate-tooltip-' + maskResult.name}
                                                      content={<span>A size <strong>{maskResult.cushion_size_alternate}</strong> cushion may also be appropriate for this patient</span>}
                                                      iconType={maskResult.cushion_size_margin > 0 ? 'fa-arrow-alt-circle-down' : 'fa-arrow-alt-circle-up'}
                                                    />
                                                  )}
                                              </Col>
                                              <Col lg={7}>
                                                {this.state.selectedRecommendationMaskType === maskResult.name &&
                                                  <Row className='align-items-center'>
                                                    <Col xs={6} xl={7} className='p-xl-0'>Cushion Change:</Col>
                                                    <Col xs={6} xl={5} className='p-xl-0'><Select
                                                      isDisabled={this.props.store.selectedRow.voided_at}
                                                      onChange={(e) => this.setState({ selectedRecommendationMaskCushionSize: e.value })}
                                                      options={getMaskCushionSize(maskResult.name)}
                                                      defaultValue={this.isCurrentMask(maskResult) ? { value: this.state.selectedRecommendationMaskCushionSize, label: this.state.selectedRecommendationMaskCushionSize } : { value: maskResult.cushion_size, label: maskResult.cushion_size }} />
                                                    </Col>
                                                  </Row>
                                                }
                                              </Col>
                                            </Row>
                                            <Row className='justify-content-between mb-2'>
                                              <Col lg={5} className='d-flex align-items-center'>
                                                {languageOptions.frameSize[langType]}:&nbsp;<strong>{maskResult.frame_size}</strong>
                                              </Col>
                                              <Col lg={7}>
                                                {this.state.selectedRecommendationMaskType === maskResult.name &&
                                                  <Row className='align-items-center'>
                                                    <Col xs={6} xl={7} className='p-xl-0'>Frame Change:</Col>
                                                    <Col xs={6} xl={5} className='p-xl-0'><Select
                                                      isDisabled={this.props.store.selectedRow.voided_at}
                                                      options={getMaskFrameSize(maskResult.name)}
                                                      defaultValue={this.isCurrentMask(maskResult) ? { label: this.state.selectedRecommendationMaskFrameSize, value: this.state.selectedRecommendationMaskFrameSize } : { label: maskResult.frame_size, value: maskResult.frame_size }}
                                                      onChange={(e) => this.setState({ selectedRecommendationMaskFrameSize: e.value })}
                                                    />
                                                    </Col>
                                                  </Row>}
                                              </Col>
                                            </Row>
                                            <span>
                                              {languageOptions.productID[langType]}: <strong>{headgearId ? `${product_id}+${headgearId}` : product_id}</strong>
                                              {isFitpack && (
                                                <ToolTip
                                                  id={'fitpack-tooltip-' + maskResult.name}
                                                  content={<span>This SKU is part of a <strong>FitPack</strong>.</span>}
                                                  iconType={'fa-info-circle'}
                                                />
                                              )}
                                              {maskResult.name === 'ResMed P10' && (
                                                <ToolTip
                                                  id={'resmed-p10-tooltip-' + maskResult.name}
                                                  content={<span>If you choose <strong>Pink</strong> or <strong>XS size</strong> use SKU: 62910.</span>}
                                                  iconType={'fa-info-circle'}
                                                />
                                              )}
                                            </span>
                                          </Col>
                                        </Row>
                                      </div>
                                    )
                                  })}
                                  <NonRecommendedOption
                                    state={this.state}
                                    selectedRow={this.props.store.selectedRow}
                                    shouldShowNROModal={this.shouldShowNROModal}
                                    setNonRecommendedOption={this.setNonRecommendedOption}
                                    setRecommendedMask={this.setRecommendedMask}
                                    isRecommendedMask={this.isRecommendedMask}
                                  />
                                </>
                              }
                              <Modal isOpen={(this.state.selectedRecommendationMaskType === "Other Mask") && this.state.showNROModal} size='lg' centered>
                                <PatientRefitForm
                                  state={this.state}
                                  checkRefitExists={this.checkRefitExists}
                                  handleRefitSave={this.handleOtherMaskSave}
                                  handleRefitCancel={this.handleOtherMaskCancel}
                                  isFromOtherMask={true}
                                  isDisabled={this.props.store.settings.showOtherMask}
                                  masksToExclude={mask_results_list}
                                />
                              </Modal>
                              <div className='p-2'>
                                {(((this.props.store.selectedRow.complete && isSameMaskWithSameSize) || this.props.store.selectedRow.voided_at) && !this.state.readOnly) && (
                                  <Button color={this.props.store.selectedRow.complete ? 'primary' : 'danger'} size='large'
                                    disabled={true}>
                                    {this.props.store.selectedRow.complete ? languageOptions.ptCompleted[langType] : languageOptions.setupCancelled[langType]}
                                  </Button>
                                ) || (
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                      {!this.state.readOnly && (!this.nonRecommendedMaskIsSelected() || !isSameMaskWithSameSize) &&
                                        <div>
                                          <Button
                                            onClick={() => {
                                              if (this.state.shouldShowLowConfidenceModal) {
                                                this.setState({ showLowConfidenceModal: true })
                                              }
                                              else this.handleCompletePatient()
                                            }}
                                            color='primary'
                                            size='large'
                                            className={`mr-2 ${this.props.store.patientCompleteLoading ? 'px-5' : ''}`}
                                            disabled={this.state.selectedRecommendationMaskType === 'Other Mask' || this.props.store.patientCompleteLoading}
                                          >
                                            {/*Quick fix for all masks with mismatch on name and display name*/}
                                            {this.props.store.patientCompleteLoading ?
                                              <div className='spinner-border spinner-border-sm text-white ' role='status'>
                                              </div> :
                                              <span>Complete (Dispense) with {getMaskDisplayName(this.state.selectedRecommendationMaskType)}</span>
                                            }
                                          </Button>
                                          <ToolTip
                                            id='patient-completed-tooltip'
                                            content={<span>{languageOptions.patientCompletedTooltip[langType]}</span>}
                                            style={{ display: !appStore.shouldSendMessageOnComplete(this.state.selectedRecommendationMaskType) ? 'none' : 'inline-block' }}
                                          />
                                        </div>
                                      }
                                      {!this.state.readOnly && (!this.nonRecommendedMaskIsSelected() || !isSameMaskWithSameSize) && !this.props.store.selectedRow.is_scripted && this.props.store.currentSubdomain.showDispenseAsScripted && 
                                        <div className='my-2 justify-content-between row'>
                                          <div className='col'>
                                            <Button
                                              onClick={() => {
                                                this.setState({ isScripted: true, referringNPIEdit: true })
                                              }}
                                              color='primary'
                                              size='large'
                                              className={`mr-2 ${this.props.store.patientCompleteLoading ? 'px-5' : ''}`}
                                              disabled={this.state.selectedRecommendationMaskType === 'Other Mask' || this.props.store.patientCompleteLoading }
                                            >
                                              {/*Quick fix for all masks with mismatch on name and display name*/}
                                              {this.props.store.patientCompleteLoading ?
                                                <div className='spinner-border spinner-border-sm text-white ' role='status'>
                                                </div> :
                                                <span>Dispense as Scripted</span>
                                              }
                                            </Button>
                                            {/* Tooltip is commented because currently we don't have content for that */}
                                            {/* <ToolTip
                                              id='patient-completed-tooltip'
                                              content={<span>{languageOptions.patientCompletedTooltip[langType]}</span>}
                                              style={{ display: !appStore.shouldSendMessageOnComplete(this.state.selectedRecommendationMaskType) ? 'none' : 'inline-block' }}
                                            /> */}
                                          </div>
                                        </div>
                                      }
                                    </div>
                                  )}
                              </div>
                            </FormGroup>
                          )}
                        {this.patientIsMagnetic() && (
                          <span style={{ fontWeight: 'bold', color: 'var(--color-darkred)' }}>{languageOptions.ptMagnetic[langType]}</span>
                        )}
                        {mask_results_list.length === 0 && !model_output?.error && (
                          <Button
                            onClick={() => {
                              this.setState({ scriptedForm: true });
                              this.setState({ scriptedFormValid: false });
                              this.setState({ scriptedMaskAdd: false });
                            }}
                            color='primary'
                            size='large'
                            className='mr-2'
                            hidden={!this.state.scriptedMaskAdd}
                            style={{ display: 'block', marginTop: '0.5rem' }}
                          >
                            {languageOptions.ptAddScriptedMask[langType]}
                          </Button>)}
                        {this.state.scriptedForm && (
                          <>
                            <ScriptedMaskForm
                              handleMaskType={this.handleMaskType}
                              handleCushionSize={this.handleCushionSize}
                              handleFrameSize={this.handleFrameSize}
                              selectedMaskName={this.state.selectedRecommendationMaskType}
                            />
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Button
                                color='primary'
                                disabled={!this.state.scriptedFormValid}
                                onClick={() => {
                                  appStore.editData(
                                    {
                                      is_scripted: this.props.store.selectedRow.is_scripted,
                                      scripted_mask: {
                                        version: this.state.refitFeedbackVersion,
                                        answers: {
                                          switch_reason: "prescription",
                                          switched_to_mask_type: this.state.selectedRecommendationMaskType,
                                          switched_to_mask_size: this.state.selectedRecommendationMaskCushionSize,
                                          switched_to_mask_frame_size: this.state.selectedRecommendationMaskFrameSize,
                                          chin_strap_dispensed: this.state.chinStrapType,
                                          ...(this.state.selectedRecommendationMaskTypeOther !== null && { switched_to_mask_type_other: this.state.selectedRecommendationMaskTypeOther })
                                        }
                                      },
                                      patient_subdomain: this.props.store.selectedRow.subdomain,
                                      website_token: this.props.store.selectedRow.website_token,
                                    },
                                    'scriptedMask',
                                    'scripted_mask',
                                  ).then(() => {
                                    appStore.loadPatients();
                                  })
                                  this.setState({ scriptedForm: false });
                                }}
                              >
                                {languageOptions.save[langType]}
                              </Button>
                              <Button
                                style={{ marginLeft: '0.5rem' }}
                                color='danger'
                                onClick={() => {
                                  this.setState({
                                    selectedRecommendationMaskType: this.props.store.selectedRow.current_mask_type,
                                    selectedRecommendationMaskCushionSize: this.props.store.selectedRow.current_mask_size,
                                    selectedRecommendationMaskFrameSize: this.props.store.selectedRow.current_mask_frame_size,
                                    scriptedForm: false,
                                    scriptedMaskAdd: true,
                                    scriptedFormValid: false,
                                  });
                                }}
                              >
                                {languageOptions.cancel[langType]}
                              </Button>
                            </div>
                          </>
                        )}
                      </CardBody>
                    </Card>}

                  <Modal
                    isOpen={this.state.showVoidModal}
                    centered
                  >
                    <Card>
                      <CardHeader className='mb-2'>
                        {languageOptions.setupCancelledQuestion[langType]}
                      </CardHeader>
                      <FormGroup className='px-2'>
                        <Input
                          value={this.state.void_reason}
                          placeholder='Reason For Cancellation'
                          required={true}
                          onChange={(e) => this.setState({ void_reason: e.target.value })}
                          invalid={this.state.void_reason_error}
                        />
                        <FormFeedback>
                          Field required
                        </FormFeedback>
                        <div className='mt-3 w-100 d-flex'>
                          <div className='ml-auto'>

                            <Button
                              className='mr-2'
                              color='secondary'
                              onClick={() => this.setState({ showVoidModal: false })}
                            >
                              {languageOptions.close[langType]}
                            </Button>
                            <Button
                              color='danger'
                              onClick={() => {
                                if (this.state.void_reason === '') {
                                  this.setState({ void_reason_error: true })
                                } else {
                                  appStore.markPatientVoid(this.state.void_reason);
                                  this.setState({ showVoidModal: false })
                                }
                              }}
                            >
                              {languageOptions.save[langType]}
                            </Button>
                          </div>
                        </div>
                      </FormGroup>
                    </Card>
                  </Modal>

                  <Modal
                    isOpen={this.state.showChinStrapModal}
                    centered
                  >
                    <ModalHeader toggle={() => { this.setState({ showChinStrapModal: !this.state.showChinStrapModal }) }}>
                      <h5>{languageOptions.chinStrapDispensed[langType]}</h5>
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col sm='12'>
                          <Form>
                            <FormGroup className="custom-radios" tag="fieldset" style={{
                              display: 'flex',
                              justifyContent: 'space-evenly',
                              alignItems: 'center'
                            }}>
                              <FormGroup check>
                                <Label check style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  cursor: 'pointer'
                                }}
                                  className="radio-icon"
                                >
                                  <i className="far fa-check-circle fa-2x text-success" style={{ marginRight: '5px' }}></i>
                                  <Input
                                    type="radio"
                                    name="chinStrapDispensed"
                                    onChange={(e) => {
                                      const showModal = this.shouldShowCompletedModal();
                                      if (showModal) {
                                        this.setState({
                                          showChinStrapModal: false,
                                          shouldShowChinStrapModal: false,
                                          showCompletedModal: true,
                                          chinStrapType: 'yes'
                                        });
                                      } else {
                                        this.prepareClinicianSelection();
                                      }
                                    }}
                                  />{' '}
                                  {languageOptions.yes[langType]}
                                </Label>
                              </FormGroup>
                              <FormGroup check>
                                <Label check style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  cursor: 'pointer'
                                }}
                                  className="radio-icon"
                                >
                                  <i className="far fa-times-circle fa-2x text-danger" style={{ marginRight: '5px' }}></i>
                                  <Input
                                    type="radio"
                                    name="chinStrapDispensed"
                                    onChange={(e) => {
                                      const showModal = this.shouldShowCompletedModal();
                                      if (showModal) {
                                        this.setState({
                                          showChinStrapModal: false,
                                          shouldShowChinStrapModal: false,
                                          showCompletedModal: true,
                                          chinStrapType: 'yes'
                                        });
                                      } else {
                                        this.prepareClinicianSelection();
                                      }
                                    }}
                                  />{' '}
                                  {languageOptions.no[langType]}
                                </Label>
                              </FormGroup>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  {/*Mask Variety Modal*/}
                  <Modal
                    isOpen={this.state.showFormularyWarningModal}
                    toggle={this.toggleWarningModal}
                    size="md"
                    centered
                    style={{ maxWidth: '625px', width: '80%' }}
                  >
                    <ModalHeader toggle={() => { this.setState({ showFormularyWarningModal: !this.state.showFormularyWarningModal }) }}>
                      <i className='fa fa-exclamation-circle' style={{ color: "goldenrod", fontSize: "1.5rem", marginRight: "10px" }} />
                      Formulary Configuration
                    </ModalHeader>
                    <ModalBody>
                      <b>There may not be enough masks for an ideal recommendation:</b>
                      <ul style={{ margin: '0.5rem auto', listStyleType: 'none' }}>
                        {this.props.store.masksVarietyWarning && (
                          this.props.store.masksVarietyWarning.map((warning, i) => (
                            <li key={warning + i}>{languageOptions.maskVarietyModalBody[warning][langType]}</li>
                          )))}
                      </ul>
                      <span>Are you sure you want to continue?</span>
                    </ModalBody>
                    <ModalFooter>
                      <Button color='secondary' size='sm' onClick={() => { this.setState({ showFormularyWarningModal: !this.state.showFormularyWarningModal }) }}>
                        {languageOptions.close[langType]}
                      </Button>
                      <Button
                        color='primary'
                        size='sm'
                        style={{ width: '5rem' }}
                        disabled={this.props.store.reprocessPatientLoading || this.props.store.editDataLoading}
                        onClick={async () => {
                          const updatedFormulary = this.state.formulary.map((mask) => mask.value);
                          const previousFormulary = this.props.store.selectedRow.formulary
                          if (!isEqual(previousFormulary, updatedFormulary)) {
                            await appStore.editData(
                              {
                                formulary: JSON.stringify(updatedFormulary),
                                updated_formulary: updatedFormulary,
                                formulary_name: this.state.formularyName,
                                patient_subdomain: this.props.store.selectedRow.subdomain,
                                website_token: this.props.store.selectedRow.website_token,
                              },
                              'formulary',
                              'updated_formulary',
                            );
                            appStore.loadPatients();

                            if (this.props.store.selectedRow.model_output?.mask_results) {
                              this.reprocessPatient(updatedFormulary)
                            }
                          }
                          this.setState({
                            formularyEdit: false,
                            showFormularyWarningModal: !this.state.showFormularyWarningModal
                          });
                        }}
                      >
                        {this.props.store.reprocessPatientLoading || this.props.store.editDataLoading ?
                          <div className='spinner-border spinner-border-sm text-white ' role='status'>
                          </div> :
                          <span>{languageOptions.confirm[langType]}</span>
                        }
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <Card>
                    <CardHeader>
                      <strong>{languageOptions.dispensedMasksTitle[langType]}</strong>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm='12'>
                          <DataTable
                            columns={refit_columns}
                            noHeader
                            data={this.props.store.selectedRow.refits}
                            pagination
                            paginationPerPage={10}
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            defaultSortField='submitted_at'
                          />
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                            <>
                              <p>{languageOptions.refitInfo[langType]}</p>
                              <div className='d-flex gap-2'>
                                <Button
                                  color='primary'
                                  size='large'
                                  disabled={!this.props.store.selectedRow.complete}
                                  onClick={() => {
                                    this.setState({ feedback_form_visible: true });
                                  }}>
                                  {languageOptions.ptRefitBtn[langType]}
                                </Button>

                                {(!this.state.readOnly && !this.props.store.selectedRow.voided_at && (this.props.store.selectedRow.is_scripted && !this.props.store.selectedRow.model_output)) &&
                                  <Button
                                    onClick={() => {
                                      const showModal = this.shouldShowCompletedModal();
                                      showModal ? this.setState({ showCompletedModal: true }) : this.prepareClinicianSelection();
                                    }}
                                    color='primary'
                                    size='large'
                                    disabled={this.props.store.selectedRow.voided_at || this.props.store.selectedRow.complete}
                                  >
                                    {languageOptions.ptMarkAsComplete[langType]}
                                  </Button>
                                }
                              </div>
                            </>
                          }
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <Modal
                    isOpen={this.state.showCompletedModal}
                    centered
                  >
                    <ModalHeader toggle={() => this.setState({ showCompletedModal: !this.state.showCompletedModal })}>
                      <h5>Set Billing DOS</h5>
                    </ModalHeader>
                    <ModalBody>
                      <Input
                        style={{ display: 'block' }}
                        type='date'
                        name='name'
                        value={this.state.dateOfService}
                        onChange={(e) => {
                          this.setState({ dateOfService: e.target.value });
                        }}
                      />
                      <Button
                        className='mt-2'
                        block={true}
                        color='primary'
                        onClick={() => {
                          this.prepareClinicianSelection()
                          this.setState({ showCompletedModal: false })
                        }}
                      >
                        Complete
                      </Button>{' '}
                    </ModalBody>
                  </Modal>

                  {/* Show Low Confidence Modal */}
                  <Modal
                    isOpen={this.state.showLowConfidenceModal}
                    centered
                    size="lg"
                  >
                    <ModalHeader toggle={() => this.setState({ showLowConfidenceModal: !this.state.showLowConfidenceModal })}>
                      Low Confidence Score
                    </ModalHeader>
                    <ModalBody>
                      The confidence score is <strong>LOW</strong>.  This means the scan was not ideal for a mask recommendation.  Please have the patient rescan for optimal results.  Encourage them to have good lighting.
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                      <Button
                        color='primary'
                        onClick={() => {
                          this.setState({ showLowConfidenceModal: false });
                          this.handleCompletePatient();
                        }}
                      >
                        Complete
                      </Button>
                      <Button
                        color='primary'
                        onClick={() => {
                          let unsubscribed = appStore.patientIsUnsubscribed();
                          unsubscribed ? this.handleUnsubscribedReinvite() : appStore.reInvite();
                          this.setState({ showLowConfidenceModal: false });
                        }}
                      >
                        Reset & Reinvite
                      </Button>
                    </ModalFooter>
                  </Modal>

                  {/* pt recall complete modal */}
                  <Modal
                    isOpen={this.state.showPtRecallCompleteModal}
                    centered
                  >
                    <ModalHeader
                      toggle={() => {
                        this.setState({ showPtRecallCompleteModal: !this.state.showPtRecallCompleteModal })
                      }}
                    >
                      Mark recall patient as Completed
                    </ModalHeader>
                    <ModalBody>
                      Do you, anyone in the household or caregiver, have magnet-sensitive implants of any type?
                      <div style={{
                        fontSize: '10px',
                        color: 'black', opacity: '0.9',
                        marginTop: '1rem',
                        marginBottom: '1rem'
                      }}>
                        <h3 style={{ fontSize: '11.5px' }}>Here is a more detailed list of implants that can be magnet-sensitive:</h3>
                        {magneticImplantsListEng.map(item => <span key={item}>{item}, </span>)}
                      </div>
                      <ModalFooter>
                        <Button
                          color='primary'
                          onClick={() => { appStore.markPtRecallComplete(true) }}
                        >
                          Yes
                        </Button>
                        <Button
                          color='danger'
                          onClick={() => { appStore.markPtRecallComplete(false) }}
                        >
                          No
                        </Button>
                      </ModalFooter>
                    </ModalBody>
                  </Modal>

                  {((mask_results_list.length === 0 || model_output?.error) && (!this.state.readOnly && !this.props.store.selectedRow.voided_at)) && !this.props.store.selectedRow.status.includes('Complete') && (
                    <Card>
                      <CardHeader>
                        <strong>In-Person Scan</strong>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col sm='12'>
                            <p>To scan in-person with a supported tablet, touch the button below and then give the tablet to the patient. When the patient is finished, retrieve the tablet.</p>
                            <Button
                              color='primary'
                              size='large'
                              onClick={() => {
                                let scanNowUrl = this.props.store.selectedRow.url + "?in-person";
                                console.log('Redirecting to ' + scanNowUrl);
                                window.open(scanNowUrl);
                              }}>
                              Scan Now
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>)}

                  {(!this.props.store.selectedRow.is_scripted || (this.props.store.selectedRow.is_scripted && this.props.store.selectedRow.model_output)) &&
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col>
                            <div className='d-flex justify-content-between'>
                              <div><strong>
                                {languageOptions.ptAvailableMasks[langType]}
                              </strong>
                                <ToolTip
                                  id='availableMasksTooltip'
                                  content={(
                                    <span>Editing masks for patients who have already scanned will update the recommendation list.</span>
                                  )}
                                />
                              </div>
                              {(!this.state.readOnly &&
                                !this.props.store.selectedRow.voided_at
                              ) &&
                                <span
                                  className='details-padding'
                                  onClick={() => this.setState({ formularyEdit: true })}
                                >
                                  <i className='fas fa-edit' />
                                  {languageOptions.edit[langType]}
                                </span>
                              }
                            </div>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {this.state.formularyEdit && this.props.store.selectedRow.model_output?.mask_results &&
                          <Alert color='primary'>Editing masks for patients who have already scanned will update the recommendation list.</Alert>
                        }
                        <Row>
                          {this.state.formularyEdit &&
                            <Col>
                              <Select
                                options={formularyNameOptions}
                                isSearchable="true"
                                value={{
                                  value: this.state.formularyName,
                                  label: this.state.formularyName,
                                }}
                                onChange={
                                  (e) => {
                                    this.setState({ formulary: store.formularies[e.value].enabled_masks.map(item => { return { value: item, label: getMaskDisplayName(item) } }) })
                                    this.setState({ formularyName: e.value })
                                  }
                                }
                              />
                              <Select
                                className='mt-1'
                                options={this.props.store.availableMaskOptionGroups}
                                isMulti={true}
                                isSearchable={true}
                                closeMenuOnSelect={true}
                                value={this.state.formulary}
                                isDisabled={!this.props.store.currentUser.canUseAdvancedSettings}
                                onChange={(e) => {
                                  let newFormulary = [];
                                  if (e && e.length > 0) {
                                    e.forEach((item) => {
                                      newFormulary.push({
                                        value: item.value,
                                        label: getMaskDisplayName(item.value)
                                      });
                                    });
                                  }
                                  this.setState({ formulary: newFormulary });
                                }}
                                styles={{
                                  option: (styles, { data }) => {
                                    return {
                                      ...styles,
                                      color: maskIsMagnetic(data.value) ? "var(--color-darkred)" : undefined
                                    };
                                  },
                                  multiValueLabel: (styles, { data }) => {
                                    return {
                                      ...styles,
                                      color: maskIsMagnetic(data.value) ? "var(--color-darkred)" : undefined
                                    };
                                  }
                                }}
                              />
                              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                                <Button
                                  color='primary'
                                  size='sm'
                                  style={{ width: '4rem' }}
                                  disabled={(this.props.store.reprocessPatientLoading || this.props.store.editDataLoading) && !this.state.showFormularyWarningModal}
                                  onClick={async () => {
                                    const updatedFormulary = this.state.formulary.map((mask) => mask.value)
                                    const previousFormulary = this.props.store.selectedRow.formulary
                                    const selectedMasks = appStore.getSelectedMasksVariety(updatedFormulary)
                                    this.props.store.masksVarietyWarning = appStore.validateMaskVariety(selectedMasks)

                                    if (this.props.store.masksVarietyWarning?.length) {
                                      this.setState({ showFormularyWarningModal: true })
                                    } else {
                                      // Check if new formulary is different/updated, then edit/reprocess
                                      if (!isEqual(previousFormulary, updatedFormulary)) {
                                        await appStore.editData(
                                          {
                                            formulary: JSON.stringify(updatedFormulary),
                                            updated_formulary: updatedFormulary,
                                            formulary_name: this.state.formularyName,
                                            patient_subdomain: this.props.store.selectedRow.subdomain,
                                            website_token: this.props.store.selectedRow.website_token,
                                          },
                                          'formulary',
                                          'updated_formulary',
                                        )
                                        appStore.loadPatients()

                                        if (this.props.store.selectedRow.model_output?.mask_results) {
                                          this.reprocessPatient(updatedFormulary)
                                        }
                                      }
                                      this.setState({ formularyEdit: false });
                                    }
                                  }}
                                >
                                  {(this.props.store.reprocessPatientLoading || this.props.store.editDataLoading) && !this.state.showFormularyWarningModal ?
                                    <div className='spinner-border spinner-border-sm text-white ' role='status'>
                                    </div> :
                                    <span>{languageOptions.save[langType]}</span>
                                  }
                                </Button>
                                <Button
                                  style={{ marginLeft: '0.5rem' }}
                                  color='danger'
                                  size='sm'
                                  onClick={() => {
                                    this.setState({ formularyEdit: false, formulary: this.getFormularyOptions() })
                                  }}>
                                  {languageOptions.cancel[langType]}
                                </Button>
                              </div>
                            </Col>
                          }

                          {(this.props.store.selectedRow.formulary && !this.state.formularyEdit) && (
                            <Col>
                              {/**formname, we use ternary due to some formularies not having a proper name.*/}
                              <strong>
                                {this.state.formularyName ? this.state.formularyName : ''}
                              </strong>
                              <br />
                              {this.props.store.selectedRow.formulary.map(mask_name => (
                                <Badge
                                  key={uuid()}
                                  color='light'
                                  title={mask_name}
                                  style={{ color: maskIsMagnetic(mask_name) ? "var(--color-darkred)" : 'black' }}
                                >
                                  {getMaskDisplayName(mask_name)}
                                </Badge>
                              ))}
                            </Col>
                          )}
                        </Row>
                      </CardBody>
                    </Card>
                  }

                  {this.props.store.selectedRow.patient_attributes && this.props.store.selectedRow.patient_attributes.is_recall && this.props.store.selectedRow.patient_attributes.answered_recall_question && this.props.store.selectedRow.patient_attributes.recall_question_answer && this.props.store.selectedRow.before_recall_attributes && (
                    <Card>
                      <CardHeader className='d-flex justify-content-between'>
                        <strong>{languageOptions.beforeRecallQuestionnaire[langType]}</strong>
                        <span style={{ fontSize: '0.85rem', color: 'black' }}>
                          {this.props.store.selectedRow.before_recall_attributes_processed_at}
                        </span>
                      </CardHeader>
                      <CardBody>
                        <Row style={{ marginBottom: '20px' }}>
                          <Col sm='6'>
                            {languageOptions.ptGender[langType]} <strong style={{ textTransform: "capitalize" }}>{
                              this.props.store.selectedRow.before_recall_attributes.gender
                            }</strong> <br />
                            {languageOptions.ptBeard[langType]} <strong>{
                              this.props.store.selectedRow.before_recall_attributes.beard && "Yes" || "No"
                            }</strong> <br />
                            {languageOptions.ptClaustrophobic[langType]} <strong>{
                              this.props.store.selectedRow.before_recall_attributes.claustrophobic && "Yes" || "No"
                            }</strong> <br />
                            {languageOptions.ptWearDentures[langType]} <strong>{
                              this.props.store.selectedRow.before_recall_attributes.no_teeth && "Yes" || "No"
                            }</strong> <br />
                            {isValidArray(beforeRecallSleepPositions) &&
                              <>
                                {languageOptions.sleepPosition[langType]} <strong style={{ textTransform: 'capitalize' }}>{beforeRecallSleepPositions.join(', ')}</strong> <br />
                              </>
                            }
                          </Col>
                          <Col sm='6'>
                            {languageOptions.ptMouthBreathing[langType]} <strong>{
                              this.props.store.selectedRow.before_recall_attributes.mild_mouth_open && "Yes" || "No"
                            }</strong> <br />
                            {languageOptions.ptConsistentMouthBreathing[langType]} <strong>{
                              this.props.store.selectedRow.before_recall_attributes.mouth_open && "Yes" || "No"
                            }</strong> <br />
                            {languageOptions.ptNasalCongestion[langType]} <strong>{
                              this.props.store.selectedRow.before_recall_attributes.mild_nasal_congestion && "Yes" || "No"
                            }</strong> <br />
                            {languageOptions.ptSevereNasalCongestion[langType]} <strong>{
                              this.props.store.selectedRow.before_recall_attributes.nasal_congestion && "Yes" || "No"
                            }</strong> <br />
                            {this.props.store.selectedRow.before_recall_attributes.magnet_sensitive_implants && (
                              <span style={{ color: 'var(--color-darkred)' }}>
                                {languageOptions.ptMagneticImplant[langType]} <strong style={{ fontWeight: 'bold' }}>Yes</strong>
                              </span>
                            ) || (
                                <span>{languageOptions.ptMagneticImplant[langType]} <strong>No</strong></span>
                              )}
                            <br />
                            {languageOptions.ptFacialSkinIrritation[langType]} <strong>{
                              this.props.store.selectedRow.before_recall_attributes.facial_skin_irritation && "Yes" || "No"
                            }</strong> <br />
                            {languageOptions.ptDeviatedSeptum[langType]} <strong>{
                              this.props.store.selectedRow.before_recall_attributes.deviated_septum && "Yes" || "No"
                            }</strong> <br />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}

                  {this.props.store.selectedRow.patient_attributes && this.props.store.selectedRow.patient_attributes.is_recall && this.props.store.selectedRow.patient_attributes.answered_recall_question && (
                    <Card>
                      <CardHeader className='d-flex justify-content-between'>
                        <strong>{languageOptions.recallQuestion[langType]}</strong>
                        <span style={{ fontSize: '0.85rem', color: 'black' }}>
                          {this.props.store.selectedRow.patient_attributes.recall_question_timestamp}
                        </span>
                      </CardHeader>
                      <CardBody>
                        <div>
                          {this.props.store.selectedRow.patient_attributes.recall_question_answer ? (
                            <span style={{ color: 'var(--color-darkred)' }}>
                              {languageOptions.ptMagneticImplant[langType]} <strong style={{ fontWeight: 'bold' }}>Yes</strong>
                            </span>
                          ) : (
                            <span>{languageOptions.ptMagneticImplant[langType]} <strong>No</strong></span>
                          )}
                          {this.renderMagneticImplantList(this.props.store.selectedRow.patient_attributes.recall_question_answer)}
                        </div>
                      </CardBody>
                    </Card>
                  )}
                  {(this.props.store.selectedRow.patient_attributes && this.props.store.selectedRow.model_output) && (
                    <Card>
                      <CardHeader className='d-flex justify-content-between'>
                        <strong>{languageOptions.ptQuestionnaire[langType]}</strong>
                        <span style={{ fontSize: '0.85rem', color: 'black' }}>
                          {this.props.store.selectedRow.processed_at}
                        </span>
                      </CardHeader>
                      <CardBody>
                        <PatientQuestionnaire
                          renderMagneticImplantList={this.renderMagneticImplantList}
                          ptSleepPositions={ptSleepPositions}
                          setRecommendedMask={(maskResult) => this.setRecommendedMask(maskResult)}
                        />
                      </CardBody>
                    </Card>
                  )}

                  <Card>
                    <CardHeader className='d-flex justify-content-between'>
                      <span>
                        <strong>Intervention Notes </strong>
                        (SleepGlad live call use only)
                      </span>
                      <div>
                        {(this.props.store.currentUser.isStaff || this.props.store.currentUser.isIntervention) && this.props.store.selectedRow.patient_attributes.is_recall &&
                          <Button
                            color='primary'
                            size='sm'
                            onClick={() => {
                              appStore.resendRecallInvite(this.props.store.selectedRow.website_token)
                            }}>
                            Resend Recall Text
                          </Button>
                        }
                        {(this.props.store.currentUser.isStaff || this.props.store.currentUser.isIntervention) && this.props.store.selectedRow.patient_attributes.is_recall && !this.state.readOnly && !this.props.store.selectedRow.voided_at && this.props.store.selectedRow.status !== 'Recall - Completed' &&
                          <Button
                            color='primary'
                            size='sm'
                            className='ml-2'
                            onClick={() => {
                              this.setState({ showPtRecallCompleteModal: true })
                            }}>
                            Mark as Recall Complete
                          </Button>
                        }
                      </div>
                    </CardHeader>
                    <CardBody>
                      <RecallPatientNotes />
                    </CardBody>
                  </Card>

                  <Row >
                    {!this.state.readOnly &&
                      <div className='d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row p-3'>
                        <div>
                          <Button className='mb-2 mb-sm-0' color='primary'
                            onClick={() => {
                              let unsubscribed = appStore.patientIsUnsubscribed();
                              unsubscribed ? this.handleUnsubscribedReinvite() : appStore.reInvite();
                            }}>
                            {languageOptions.reinviteBtn[langType]}
                          </Button>
                          <ToolTip
                            id='patient-reinvite-tooltip'
                            content={<span>Remove any existing recommendation, and send the patient a new link</span>}
                          />
                        </div>
                        {this.props.store.selectedRow.is_marked_complete &&
                          <div>
                            <Button color='secondary' onClick={appStore.resendResults}>
                              Resend Mask Results
                            </Button>
                            <ToolTip
                              id='patient-resend-tooltip'
                              content={<span>Resend mask results to patient</span>}
                            />
                          </div>}
                        {!this.props.store.selectedRow.complete &&
                          <div>
                            <Button
                              onClick={() => {
                                this.setState({ showVoidModal: true });
                                if (this.props.store.scanPackageEnabled) {
                                  appStore.loadScanCounter();
                                }
                              }}
                              color='danger'
                              size='large'
                              disabled={this.props.store.selectedRow.voided_at}
                            >
                              {this.props.store.selectedRow.voided_at ? languageOptions.setupCancelled[langType] : languageOptions.voidSetup[langType]}
                            </Button>
                            <ToolTip
                              id='patient-void-tooltip'
                              content={<span>{languageOptions.markPtCancelled[langType]}</span>}
                            />
                          </div>
                        }
                      </div>
                    }
                  </Row>
                </>
              }
            </Container>
          </TabPane>

          <TabPane tabId="patient-profile">
            <Container className="tabpane-content" fluid={this.state.readOnly}>
              <Card>
                <CardHeader>
                  <strong>{languageOptions.pdProfile[langType]}</strong>
                </CardHeader>
                <CardBody>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.pdPatientID[langType]}</span>
                    </Col>
                    <Col sm='9' >
                      {!this.state.patientIdEdit && (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            {this.props.store.selectedRow.patientId.toString()}
                          </span>
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                            <span
                              className='details-padding'
                              onClick={() => this.setState({ patientIdEdit: true })}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>
                          }
                        </span>
                      )}
                      {this.state.patientIdEdit && (
                        <div>
                          <Input
                            style={{ display: 'block' }}
                            type='text'
                            name='name'
                            onChange={(e) => {
                              this.setState({ patientId: e.target.value });
                            }}
                            defaultValue={this.props.store.selectedRow.patientId}
                          />
                          {this.state.errors.id && (<div className='d-flex'>
                            <ToolTip className='mr-2' id='patient-id-tooltip'
                              content={<span className='mr-1 mt-1'>Patient ID is <b>required.</b> <br /> The ID must be <b>unique</b> for every patient. <br /> The ID should consist of <b>numbers, letters, or the following punctuation: - _ .</b></span>} />
                            {displayFormError('Please enter a valid id.')}
                          </div>)}
                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                            <Button
                              color='primary'
                              onClick={this.onEditIdSave}>
                              {languageOptions.save[langType]}
                            </Button>
                            <Button
                              style={{ marginLeft: '0.5rem' }}
                              color='danger'
                              onClick={() => this.setState({ patientIdEdit: false })}>
                              {languageOptions.cancel[langType]}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.pdFirstName[langType]}</span>
                    </Col>
                    <Col sm='9' >
                      {!this.state.firstNameEdit && (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            {this.props.store.selectedRow.first_name.toString()}
                          </span>
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                            <span
                              className='details-padding'
                              onClick={() => this.setState({ firstNameEdit: true })}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>
                          }
                        </span>
                      )}
                      {this.state.firstNameEdit && (
                        <div>
                          <Input
                            invalid={this.state.errors.firstName}
                            style={{ display: 'block' }}
                            type='text'
                            name='name'
                            onChange={(e) => {
                              this.setState({ firstName: e.target.value });
                            }}
                            defaultValue={capitalize(
                              this.props.store.selectedRow.first_name
                            )}
                          />
                          <FormFeedback>
                            {languageOptions.firstNameError[langType]}
                          </FormFeedback>
                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                            <Button
                              color='primary'
                              onClick={() => {
                                this.onEditSave(
                                  'firstName', 'firstNameEdit', 'first_name'
                                )
                              }}>
                              {languageOptions.save[langType]}
                            </Button>
                            <Button
                              style={{ marginLeft: '0.5rem' }}
                              color='danger'
                              onClick={() => this.setState({ firstNameEdit: false })}>
                              {languageOptions.cancel[langType]}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.pdLastName[langType]}</span>
                    </Col>
                    <Col sm='9'>
                      {!this.state.lastNameEdit && (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            {capitalize(
                              this.props.store.selectedRow.last_name
                            )}
                          </span>
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                            <span
                              className='details-padding'
                              onClick={() => this.setState({ lastNameEdit: true })}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>
                          }
                        </span>
                      )}
                      {this.state.lastNameEdit && (
                        <div>
                          <Input
                            invalid={this.state.errors.lastName}
                            style={{ display: 'block' }}
                            type='text'
                            name='name'
                            onChange={(e) => {
                              this.setState({ lastName: e.target.value });
                            }}
                            defaultValue={capitalize(
                              this.props.store.selectedRow.last_name
                            )}
                          />
                          <FormFeedback>
                            {languageOptions.lastNameError[langType]}
                          </FormFeedback>
                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                            <Button
                              color='primary'
                              onClick={() => {
                                this.onEditSave(
                                  'lastName', 'lastNameEdit', 'last_name'
                                )
                              }}>
                              {languageOptions.save[langType]}
                            </Button>
                            <Button
                              style={{ marginLeft: '0.5rem' }}
                              color='danger'
                              onClick={() => this.setState({ lastNameEdit: false })}>
                              {languageOptions.cancel[langType]}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.pdCreated[langType]}</span>
                    </Col>
                    <Col sm='9'>
                      <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>
                          {dayjs(this.props.store.selectedRow.created).format('MM/DD/YYYY - hh:mm a')}
                        </span>
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.email[langType]}:</span>
                    </Col>
                    <Col sm='9'>
                      {!this.state.emailEdit && (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            {this.props.store.selectedRow.email} {this.props.store.selectedRow.patient_attributes?.email_unsubscribed_at && <span> &nbsp; <i style={{ color: '#dc3545', fontSize: '90%' }}> (Unsubscribed)</i></span>}
                          </span>
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                            <span
                              className='details-padding'
                              onClick={() => this.setState({ emailEdit: true })}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>
                          }
                        </span>
                      )}
                      {this.state.emailEdit && (
                        <div>
                          <Input
                            style={{ display: 'block' }}
                            type='text'
                            name='name'
                            onChange={(e) => {
                              this.setState({ email: e.target.value });
                            }}
                            defaultValue={this.props.store.selectedRow.email}
                          />
                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                            <Button
                              color='primary'
                              onClick={() => {
                                if (!validateEmail(this.state.email) && this.state.inviteType === 'invite_email') {
                                  toast.error('Please enter a valid email!');
                                  return;
                                }
                                this.setState({
                                  emailEdit: false,
                                });
                                if (this.state.email === this.props.store.selectedRow.email) return;
                                appStore.editData(
                                  {
                                    patient_email: this.state.email,
                                    patient_subdomain: this.props.store.selectedRow.subdomain,
                                    website_token: this.props.store.selectedRow.website_token,
                                  },
                                  'email',
                                  'patient_email',
                                );
                                appStore.loadPatients();
                              }}>
                              {languageOptions.save[langType]}
                            </Button>
                            <Button
                              style={{ marginLeft: '0.5rem' }}
                              color='danger'
                              onClick={() => this.setState({ emailEdit: false })}>
                              {languageOptions.cancel[langType]}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.pdPhone[langType]}</span>
                    </Col>
                    <Col sm='9'>
                      {!this.state.phoneEdit && (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            {formatPhoneNumber(this.props.store.selectedRow?.phone)} {this.props.store.selectedRow.patient_attributes?.sms_unsubscribed_at && <span> &nbsp; <i style={{ color: '#dc3545', fontSize: '90%' }}> (Unsubscribed)</i></span>}
                          </span>
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                            <span
                              className='details-padding'
                              onClick={() => this.setState({ phoneEdit: true })}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>
                          }
                        </span>
                      )}
                      {this.state.phoneEdit && (
                        <div>
                          <PhoneNumberInput
                            phoneNumberError={this.state.phoneNumberError}
                            isValidPhoneNumber={this.state.isValidPhoneNumber}
                            handlePhoneInputChange={this.handlePhoneInputChange}
                            phoneInputCountry={this.state.phoneInputCountry}
                            updatePhoneInputCountry={(country) => {
                              this.setState({ phoneInputCountry: country })
                            }}
                            value={this.state.phoneNumber}
                          />
                          {this.state.phoneNumberError !== numberErrorEnum.success && (
                            displayFormError(
                              `Error: ${this.state.phoneNumberError == numberErrorEnum.invalidNum ? "this is an invalid number." : this.state.phoneNumberError == numberErrorEnum.notMobileNum ? "this is not a mobile number." : numberErrorEnum.emptyNum ? "empty number." : "unknown."}`
                            )
                          )}
                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                            <Button
                              disabled={
                                (this.state.phoneNumberError !== numberErrorEnum.success ||
                                  this.state.phoneNumber === null ||
                                  !isPossiblePhoneNumber(this.state.phoneNumber) ||
                                  this.props.store.twilioLookupLoading)
                                // && this.state.inviteType !== 'invite_email' 
                                // Even if invite type is email, users should not be able to enter/edit invalid phone numbers
                              }
                              color='primary'
                              onClick={() => {
                                if (!validatePhone(this.state.phoneNumber) && this.state.inviteType !== 'invite_email') {
                                  toast.error('Please enter a valid phone!');
                                  return;
                                }
                                this.resetPhoneEdit()
                                if (this.state.phoneNumber === this.props.store.selectedRow.phone) return;
                                appStore.editData(
                                  {
                                    patient_phone: this.state.phoneNumber,
                                    patient_subdomain: this.props.store.selectedRow.subdomain,
                                    website_token: this.props.store.selectedRow.website_token,
                                  },
                                  'phone',
                                  'patient_phone',
                                );
                                appStore.loadPatients();
                              }}>
                              {languageOptions.save[langType]}
                            </Button>
                            <Button
                              style={{ marginLeft: '0.5rem' }}
                              color='danger'
                              onClick={this.resetPhoneEdit}>
                              {languageOptions.cancel[langType]}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.msgDelivery[langType]}:</span>
                    </Col>
                    <Col sm='9'>
                      {!this.state.inviteTypeEdit && (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            {(this.state.inviteType.includes('email') && this.props.store.selectedRow.email) && <span>Email</span>}
                            {(this.state.inviteType.includes('text') && this.props.store.selectedRow.phone) && <span>Text Message</span>}
                          </span>
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                            <span
                              className='details-padding'
                              onClick={() => this.setState({ inviteTypeEdit: true })}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>
                          }
                        </span>
                      )}
                      {this.state.inviteTypeEdit &&
                        <div>
                          <Select
                            options={[
                              { value: 'invite_text', label: 'Text Message', isDisabled: (!this.props.store.selectedRow.phone || this.props.store.selectedRow.patient_attributes?.sms_unsubscribed_at) ? true : false },
                              { value: 'invite_email', label: 'Email', isDisabled: (!this.props.store.selectedRow.email || this.props.store.selectedRow.patient_attributes?.email_unsubscribed_at) ? true : false },
                            ]}
                            onChange={(e) => {
                              this.setState({ inviteType: e.value })
                            }}
                          />
                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                            <Button
                              color='primary'
                              onClick={() => {
                                //if user selects text but has no phone number, disable edit mode and show toast.
                                if (this.state.inviteType === 'invite_text') {
                                  if (!this.props.store.selectedRow.phone) {
                                    this.clearMessageDelivery('email');
                                    this.setState({ inviteTypeEdit: false })
                                    toast.error('Phone Number must be provided!')
                                  } else {
                                    appStore.editData({
                                      should_send_sms: true,
                                      should_send_email: false,
                                      patient_subdomain: this.props.store.selectedRow.subdomain,
                                      website_token: this.props.store.selectedRow.website_token,
                                    },
                                      'should_send_sms',
                                      'should_send_sms'
                                    );
                                    //here we toggle modal for reset & reinvite.
                                    this.setResetReinviteModal(true);
                                  }
                                  this.setState({ inviteType: 'invite_text' })
                                } else if (this.state.inviteType === 'invite_email') {
                                  if (!this.props.store.selectedRow.email) {
                                    this.clearMessageDelivery('text');
                                    this.setState({ inviteTypeEdit: false })
                                    toast.error('Email must be provided!')
                                  } else {
                                    appStore.editData({
                                      should_send_sms: false,
                                      should_send_email: true,
                                      patient_subdomain: this.props.store.selectedRow.subdomain,
                                      website_token: this.props.store.selectedRow.website_token,
                                    },
                                      'should_send_email',
                                      'should_send_email'
                                    )
                                    //here we toggle modal for reset & reinvite.
                                    this.setResetReinviteModal(true);
                                  }
                                  this.setState({ inviteType: 'invite_email' })
                                }
                                this.setState({ inviteTypeEdit: false })
                                appStore.loadPatients();
                              }}
                            >
                              {languageOptions.save[langType]}
                            </Button>
                            <Button
                              style={{ marginLeft: '0.5rem' }}
                              color='danger'
                              onClick={() => this.setState({ inviteTypeEdit: false })}>
                              {languageOptions.cancel[langType]}
                            </Button>
                          </div>
                        </div>
                      }
                    </Col>
                    {this.state.resetReinviteModal &&
                      <Modal
                        isOpen={this.state.resetReinviteModal}
                        toggle={() => this.setResetReinviteModal(false)}
                        centered
                        style={{ maxWidth: '625px', width: '80%' }}
                      >
                        <ModalHeader style={{ padding: '0.8rem 1rem' }} toggle={() => this.setResetReinviteModal(false)}>
                          <h5 style={{ marginBottom: '0' }}>Delivery Method has been changed</h5>
                        </ModalHeader>
                        <ModalBody>
                          <span style={{ textAlign: 'center' }}>Would you like to <b>Reset & Reinvite</b> the patient with this new Message Delivery method?</span>

                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                          <Button
                            color='secondary'
                            onClick={() => {
                              this.setResetReinviteModal(false);
                            }
                            }>
                            No
                          </Button>
                          <Button
                            color='primary'
                            onClick={() => {
                              let unsubscribed = appStore.patientIsUnsubscribed();
                              unsubscribed ? this.handleUnsubscribedReinvite() : appStore.reInvite();
                              this.setResetReinviteModal(false);
                            }
                            }>
                            Yes (Reset & Reinvite)
                          </Button>
                        </ModalFooter>
                      </Modal>
                    }

                    {this.state.resetReinviteUnsubscribedModal &&
                      <Modal
                        isOpen={this.state.resetReinviteUnsubscribedModal}
                        toggle={() => this.setResetReinviteUnsubscribedModal(false)}
                        centered
                        style={{ maxWidth: '625px', width: '80%' }}
                      >
                        {this.renderUnsubscribeReinvite()}
                      </Modal>
                    }
                  </Row>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.pdAHI[langType]}</span>
                    </Col>
                    <Col sm='9'>
                      {!this.state.ahiEdit && (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            {this.props.store.selectedRow.ahi}
                          </span>
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) && !this.props.store.selectedRow.model_output &&
                            <span
                              className='details-padding'
                              onClick={() => this.setState({ ahiEdit: true })}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>}
                        </span>
                      )}
                      {this.state.ahiEdit && (
                        <div>
                          <Input
                            style={{ display: 'block' }}
                            type='number'
                            onKeyDown={(e) => !validNumberInput(e) && e.preventDefault()}
                            min={0}
                            max={100}
                            name='name'
                            onChange={(e) => {
                              this.setState({ ahi: e.target.value });
                            }}
                            defaultValue={capitalize(
                              this.props.store.selectedRow.ahi
                            )}
                          />
                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                            <Button
                              color='primary'
                              disabled={!this.state.ahi || isNaN(this.state.ahi) || (this.state.ahi < 0 || this.state.ahi > 100)}
                              onClick={() => {
                                this.setState({
                                  ahiEdit: false,
                                });
                                appStore.editData(
                                  {
                                    ahi: this.state.ahi.toString(),
                                    patient_subdomain: this.props.store.selectedRow.subdomain,
                                    website_token: this.props.store.selectedRow.website_token,
                                  },
                                  'ahi',
                                  'ahi',
                                );
                                appStore.loadPatients();
                              }}>
                              {languageOptions.save[langType]}
                            </Button>
                            <Button
                              style={{ marginLeft: '0.5rem' }}
                              color='danger'
                              onClick={() => this.setState({ ahiEdit: false })}>
                              {languageOptions.cancel[langType]}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='12'>
                      <Row>
                        <Col sm='3'>
                          <span>{languageOptions.papTherapy[langType]}</span>
                        </Col>
                        <Col sm='9'>
                          <span className='d-flex justify-content-between'>
                            <div className='d-flex flex-wrap align-items-center gap-1 gap-lg-4'>
                              <div className='d-flex gap-1'>
                                <input
                                  type="radio"
                                  id="pressureCPAP"
                                  name="pressure"
                                  value="pressureCPAP"
                                  onChange={this.handleRadioButtonChange}
                                  disabled={!this.state.pressureEdit}
                                  checked={this.state.selectedOption === "pressureCPAP"}
                                />
                                <label htmlFor="pressureCPAP" style={{ fontSize: '14px' }}>CPAP</label>
                              </div>
                              <div className='d-flex gap-1'>
                                <input
                                  type="radio"
                                  id="pressureCPAPAP"
                                  name="pressure"
                                  value="pressureCPAPAP"
                                  onChange={this.handleRadioButtonChange}
                                  disabled={!this.state.pressureEdit}
                                  checked={this.state.selectedOption === "pressureCPAPAP"}
                                />
                                <label htmlFor="pressureCPAPAP" style={{ fontSize: '14px' }}>CPAP Auto Pressure</label>
                              </div>
                              <div className='d-flex gap-1'>
                                <input
                                  type="radio"
                                  id="pressureBIPAP"
                                  name="pressure"
                                  value="pressureBIPAP"
                                  onChange={this.handleRadioButtonChange}
                                  disabled={!this.state.pressureEdit}
                                  checked={this.state.selectedOption === "pressureBIPAP"}
                                />
                                <label htmlFor="pressureBIPAP" style={{ fontSize: '14px' }}>BiPAP</label>
                              </div>
                              <div className='d-flex gap-1'>
                                <input
                                  type="radio"
                                  id="pressureASVST"
                                  name="pressure"
                                  value="pressureASVST"
                                  onChange={this.handleRadioButtonChange}
                                  disabled={!this.state.pressureEdit}
                                  checked={this.state.selectedOption === "pressureASVST"}
                                />
                                <label htmlFor="pressureASVST" style={{ fontSize: '14px' }}>ASV/ST</label>
                              </div>
                            </div>
                          </span>
                        </Col>
                      </Row>

                      {/*all of these need to be false for the edit button to show*/}
                      {!this.state.pressureEdit && ((!this.state.readOnly && !this.props.store.selectedRow.voided_at) && !this.props.store.selectedRow.model_output) ? (
                        <div className='d-flex justify-content-end' >
                          <span
                            className='details-padding'
                            onClick={() => this.setState({ pressureEdit: true })}>
                            <i className='fas fa-edit' />
                            {languageOptions.edit[langType]}
                          </span>
                        </div>
                      ) : (<Row style={{ paddingTop: '22px' }}></Row>)}

                      <Row>
                        <Col sm='3'>
                          <span>{languageOptions.pdPressure[langType]}</span>
                        </Col>
                        <Col sm='9'>
                          {!this.state.pressureEdit && (
                            <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <span>
                                {this.props.store.selectedRow.patient_attributes.pressure}
                              </span>
                            </span>
                          )}
                          {this.state.pressureEdit && (
                            <div>
                              <Select
                                value={this.state.pressure ? { value: this.state.pressure, label: this.state.pressure } : null}
                                placeholder={languageOptions.selectPressure[langType]}
                                options={this.state.pressureOption}
                                onChange={(value) => {
                                  this.setState({ pressure: value?.value });
                                }}
                                name='pressureDropdown'
                                id='pressureDropdown'
                              />

                              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                                <Button
                                  color='primary'
                                  onClick={() => {
                                    this.setState({ pressureEdit: false, });
                                    appStore.editData(
                                      {
                                        pressure: this.state.pressure,
                                        pap_therapy: this.getPapValue(),
                                        patient_subdomain: this.props.store.selectedRow.subdomain,
                                        website_token: this.props.store.selectedRow.website_token,
                                      },
                                      'pressure',
                                      'pap_therapy',
                                    );
                                    appStore.loadPatients();
                                  }}>
                                  {languageOptions.save[langType]}
                                </Button>
                                <Button
                                  style={{ marginLeft: '0.5rem' }}
                                  color='danger'
                                  onClick={() => {
                                    this.setState({
                                      selectedOption: this.getPapValueStore(this.props.store.selectedRow.pap_therapy),
                                      pressureEdit: false
                                    });
                                  }}
                                >
                                  {languageOptions.cancel[langType]}
                                </Button>
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* We are calculating bmi from pt questionnaire*/}
                  {/* <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>BMI:</span>
                    </Col>
                    <Col sm='9'>
                      {!this.state.bmiEdit && (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            {this.state.bmi}
                          </span>
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                            <span
                              className='details-padding'
                              onClick={() => this.setState({ bmiEdit: true })}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>
                          }
                        </span>
                      )}
                      {this.state.bmiEdit && (
                        <div>
                          <Input
                            min={0}
                            max={100}
                            style={{ display: 'block' }}
                            type='number'
                            value={this.state.bmi}
                            name='name'
                            onChange={(e) => {
                              this.setState({ bmi: e.target.value });
                            }}
                            defaultValue={this.props.store.selectedRow.bmiEdit}
                          />
                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                            <Button
                              color='primary'
                              onClick={() => {
                                this.setState({
                                  bmiEdit: false,
                                });
                                if (this.state.bmi > 100) {
                                  this.setState({ bmi: this.props.store.selectedRow.patient_attributes.bmi })
                                  toast.error('BMI Must be lower than 100!')
                                  return;
                                } else if (this.state.bmi <= 0) {
                                  this.setState({ bmi: this.props.store.selectedRow.patient_attributes.bmi })
                                  toast.error('BMI Must be greater than 0!')
                                  return;
                                }
                                appStore.editData(
                                  {
                                    bmi: this.state.bmi,
                                    patient_subdomain: this.props.store.selectedRow.subdomain,
                                    website_token: this.props.store.selectedRow.website_token,
                                  },
                                  'bmi',
                                  'bmi',
                                );
                                appStore.loadPatients();
                              }}>
                              {languageOptions.save[langType]}
                            </Button>
                            <Button
                              style={{ marginLeft: '0.5rem' }}
                              color='danger'
                              onClick={() => {
                                this.setState({ bmi: this.props.store.selectedRow.patient_attributes.bmi })
                                this.setState({ bmiEdit: false })
                              }}>
                              {languageOptions.cancel[langType]}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row> */}
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.dateOfBirth[langType]}:</span>
                    </Col>
                    <Col sm='9'>
                      {!this.state.dobEdit && (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            {dayjs(this.state.dob).format('MM/DD/YYYY')}
                          </span>
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                            <span
                              className='details-padding'
                              onClick={() => this.setState({ dobEdit: true })}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>
                          }
                        </span>
                      )}
                      {this.state.dobEdit && (
                        <div>
                          <CustomDatePicker
                            value={dayjs(this.state.dob)}
                            onChange={(date) => {
                              this.setState({ dob: date ? dayjs(date).format('MM/DD/YYYY') : '' });
                            }}
                            maxDate={dayjs()}
                            minDate={dayjs('1900-01-01')}
                          />
                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                            <Button
                              color='primary'
                              onClick={() => {
                                const dob = new Date(this.state.dob);
                                if (
                                  !this.state.dob ||
                                  isNaN(dob) ||
                                  dob.toString() === 'Invalid Date' ||
                                  dob < new Date('1900-01-01') ||
                                  dob > new Date()
                                ) {
                                  this.setState({ dob: this.props.store.selectedRow.patient_attributes.date_of_birth });
                                  toast.error('Invalid date of birth!');
                                  return;
                                }
                                this.setState({ dobEdit: false })
                                appStore.editData(
                                  {
                                    date_of_birth: this.state.dob,
                                    patient_subdomain: this.props.store.selectedRow.subdomain,
                                    website_token: this.props.store.selectedRow.website_token,
                                  },
                                  'dob',
                                  'dob',
                                );
                                this.setState({ dob: this.state.dob })
                                appStore.loadPatients();
                              }}>
                              {languageOptions.save[langType]}
                            </Button>
                            <Button
                              style={{ marginLeft: '0.5rem' }}
                              color='danger'
                              onClick={() => {
                                this.setState({
                                  dob: this.props.store.selectedRow.patient_attributes.date_of_birth,
                                  dobEdit: false
                                })
                              }}>
                              {languageOptions.cancel[langType]}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.patientLang[langType]}:</span>
                    </Col>
                    <Col sm='9'>
                      {!this.state.languageEdit && (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            {this.state.language && this.state.language.label}
                          </span>
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                            <span
                              className='details-padding'
                              onClick={() => this.setState({ languageEdit: true })}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>
                          }
                        </span>
                      )}
                      {this.state.languageEdit && (
                        <div>
                          <Select
                            defaultValue={this.state.language}
                            options={ptLanguageOptions}
                            onChange={(e) => {
                              this.setState({ language: e });
                            }}
                            name='languageDropdown'
                            id='languageDropdown'
                          />
                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                            <Button
                              color='primary'
                              onClick={() => {
                                this.setState({
                                  languageEdit: false,
                                });
                                if (!this.state.language) return
                                appStore.editData(
                                  {
                                    language: this.state.language.value,
                                    patient_subdomain: this.props.store.selectedRow.subdomain,
                                    website_token: this.props.store.selectedRow.website_token,
                                  },
                                  'language',
                                  'language',
                                );
                                this.setState({ language: this.state.language })
                                appStore.loadPatients();
                              }}>
                              {languageOptions.save[langType]}
                            </Button>
                            <Button
                              style={{ marginLeft: '0.5rem' }}
                              color='danger'
                              onClick={() => {
                                this.setState({
                                  language: ptLanguageOptions.filter((e) => e.value === this.props.store.selectedRow.language)[0],
                                  languageEdit: false
                                })
                              }}>
                              {languageOptions.cancel[langType]}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.ethnicity[langType]}:</span>
                    </Col>
                    <Col sm='9'>
                      {!this.state.ethnicityEdit && (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            {this.state.ethnicity && this.state.ethnicity.label}
                          </span>
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) && !this.props.store.selectedRow.model_output &&
                            <span
                              className='details-padding'
                              onClick={() => this.setState({ ethnicityEdit: true })}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>
                          }
                        </span>
                      )}
                      {this.state.ethnicityEdit && (
                        <div>
                          <Select
                            isSearchable="true"
                            options={ethnicityOptions}
                            value={this.state.ethnicity}
                            onChange={(e) => {
                              this.setState({ ethnicity: e });
                            }}
                            name='ethnicityDropdown'
                            id='ethnicityDropdown'
                          />
                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                            <Button
                              color='primary'
                              onClick={() => {
                                this.setState({
                                  ethnicityEdit: false,
                                });
                                if (!this.state.ethnicity) return
                                appStore.editData(
                                  {
                                    ethnicity: this.state.ethnicity.value,
                                    patient_subdomain: this.props.store.selectedRow.subdomain,
                                    website_token: this.props.store.selectedRow.website_token,
                                  },
                                  'ethnicity',
                                  'ethnicity',
                                );
                                this.setState({ ethnicity: this.state.ethnicity })
                                appStore.loadPatients();
                              }}>
                              {languageOptions.save[langType]}
                            </Button>
                            <Button
                              style={{ marginLeft: '0.5rem' }}
                              color='danger'
                              onClick={() => {
                                this.setState({
                                  ethnicity: ethnicityOptions.filter((e) => e.value === this.props.store.selectedRow.patient_attributes.ethnicity)[0],
                                  ethnicityEdit: false
                                })
                              }}>
                              {languageOptions.cancel[langType]}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.sleepStudyType[langType]}:</span>
                    </Col>
                    <Col sm='9'>
                      {!this.state.sleepStudyTypeEdit && (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            {this.state.sleepStudyType && this.state.sleepStudyType.label}
                          </span>
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                            <span
                              className='details-padding'
                              onClick={() => this.setState({ sleepStudyTypeEdit: true })}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>
                          }
                        </span>
                      )}
                      {this.state.sleepStudyTypeEdit && (
                        <div>
                          <Select
                            isSearchable="true"
                            value={this.state.sleepStudyType}
                            options={sleepStudyTypeOptions}
                            onChange={(e) => {
                              this.setState({ sleepStudyType: e });
                            }}
                          />
                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                            <Button
                              color='primary'
                              onClick={() => {
                                this.setState({
                                  sleepStudyTypeEdit: false,
                                });
                                if (!this.state.sleepStudyType) return;
                                appStore.editData(
                                  {
                                    sleep_study_type: this.state.sleepStudyType.value,
                                    patient_subdomain: this.props.store.selectedRow.subdomain,
                                    website_token: this.props.store.selectedRow.website_token,
                                  },
                                  'sleepStudyType',
                                  'sleepStudyType',
                                );
                                this.setState({ sleepStudyType: this.state.sleepStudyType })
                                appStore.loadPatients();
                              }}>
                              {languageOptions.save[langType]}
                            </Button>
                            <Button
                              style={{ marginLeft: '0.5rem' }}
                              color='danger'
                              onClick={() => {
                                this.setState({
                                  sleepStudyType: sleepStudyTypeOptions.filter((e) => e.value === this.props.store.selectedRow.patient_attributes.sleep_study_type)[0],
                                  sleepStudyTypeEdit: false
                                })
                              }}>
                              {languageOptions.cancel[langType]}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.pdCreatedBy[langType]}</span>
                    </Col>
                    <Col sm='9'>
                      <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{this.props.store.selectedRow.created_by}</span>
                      </span>
                    </Col>
                  </Row>
                  {/**SETUP DATE */}
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.pdSetupDate[langType]}</span>
                    </Col>
                    <Col sm='9'>
                      {!this.state.setupDateEdit && (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            {dayjs(this.props.store.selectedRow.patient_attributes.set_up_date).format('MM/DD/YYYY')}
                          </span>
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                            <span
                              className='details-padding'
                              onClick={() => this.setState({ setupDateEdit: true })}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>
                          }
                        </span>
                      )}
                      {this.state.setupDateEdit && (
                        <div>
                          <CustomDatePicker
                            value={dayjs(this.state.setupDate)}
                            maxDate={dayjs(this.props.store.selectedRow.created).add(60, 'days')}  // 60 days from creation date
                            minDate={dayjs(this.props.store.selectedRow.created)}
                            onChange={(date) => {
                              this.setState({ setupDate: date ? dayjs(date).format('YYYY-MM-DD') : '' });
                            }}
                          />
                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                            <Button
                              color='primary'
                              onClick={() => {
                                const createdDate = dayjs(this.props.store.selectedRow.created);
                                const createdNextTwoMonths = createdDate.add(60, 'day');
                                const setupDate = dayjs(this.state.setupDate);
                                if (!this.state.setupDate || setupDate.toString() === 'Invalid Date' || (setupDate.isBefore(createdDate, 'day') || setupDate.isAfter(createdNextTwoMonths))) {
                                  this.setState({ setupDate: this.props.store.selectedRow.patient_attributes.set_up_date });
                                  toast.error('Invalid setup date!');
                                  return;
                                }
                                this.setState({ setupDateEdit: false })
                                appStore.editData(
                                  {
                                    set_up_date: this.state.setupDate,
                                    patient_subdomain: this.props.store.selectedRow.subdomain,
                                    website_token: this.props.store.selectedRow.website_token,
                                  },
                                  'set_up_date',
                                  'set_up_date',
                                );
                                this.setState({ setupDate: this.state.setupDate })
                                appStore.loadPatients();
                                this.props.store.selectedRow.patient_attributes.set_up_date = this.state.setupDate
                              }}>
                              {languageOptions.save[langType]}
                            </Button>
                            <Button
                              style={{ marginLeft: '0.5rem' }}
                              color='danger'
                              onClick={() => {
                                this.setState({
                                  setupDate: this.props.store.selectedRow.patient_attributes.set_up_date,
                                  setupDateEdit: false
                                })
                              }}>
                              {languageOptions.cancel[langType]}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  {/**ASSIGNED CLINICIAN */}
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.pdAssignedClinician[langType]}</span>
                    </Col>
                    <Col sm='9'>
                      {!this.state.assignedClinicianEdit && (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            {this.props.store.selectedRow.patient_attributes.patient_assigned_to}
                          </span>
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                            <span
                              className='details-padding'
                              onClick={() => this.setState({ assignedClinicianEdit: true })}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>
                          }
                        </span>
                      )}
                      {this.state.assignedClinicianEdit && (
                        <div>
                          <Select
                            isSearchable="true"
                            onFocus={() => !this.props.store.users?.length &&
                              appStore.loadUsers({ is_active: true })}
                            options={this.props.store.users ? this.props.store.users
                              .map(({ username }) => ({ label: username, value: username })) : []}
                            value={this.state.assignedClinician}
                            onChange={(e) => {
                              if (!e) return;
                              this.setState({ assignedClinician: e });
                            }}
                          />
                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                            <Button
                              color='primary'
                              onClick={() => {
                                if (!this.state.assignedClinician) return;
                                this.setState({ assignedClinicianEdit: false });
                                appStore.editData(
                                  {
                                    patient_assigned_to: this.state.assignedClinician.value,
                                    patient_subdomain: this.props.store.selectedRow.subdomain,
                                    website_token: this.props.store.selectedRow.website_token,
                                  },
                                  'patient_assigned_to',
                                  'patient_assigned_to',
                                );
                                this.setState({ assignedClinician: this.state.assignedClinician })
                                appStore.loadPatients();
                                this.props.store.selectedRow.patient_attributes.patient_assigned_to = this.state.assignedClinician.value
                              }}>
                              {languageOptions.save[langType]}
                            </Button>
                            <Button
                              style={{ marginLeft: '0.5rem' }}
                              color='danger'
                              onClick={() => {
                                this.setState({
                                  assignedClinician: this.props.store.selectedRow.patient_attributes.patient_assigned_to,
                                  assignedClinicianEdit: false
                                })
                              }}>
                              {languageOptions.cancel[langType]}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.pdCompletedBy[langType]}</span>
                    </Col>
                    <Col sm='9'>
                      <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{this.props.store.selectedRow.completed_by}</span>
                      </span>
                    </Col>
                  </Row>
                  {this.props.store.selectedRow.voided_at &&
                    <>
                      <Row style={{ marginBottom: '20px' }}>
                        <Col sm='3'>
                          <span>{languageOptions.voidedBy[langType]}</span>
                        </Col>
                        <Col sm='9'>
                          <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{this.props.store.selectedRow.voided_by}</span>
                          </span>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: '20px' }}>
                        <Col sm='3'>
                          <span>{languageOptions.voidReason[langType]}</span>
                        </Col>
                        <Col sm='9'>
                          <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{this.props.store.selectedRow.void_reason}</span>
                          </span>
                        </Col>
                      </Row>
                    </>}

                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.pdOnboardedLocation[langType]}</span>
                    </Col>
                    <Col sm='9'>
                      {!this.state.locationEdit && (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            {this.props.store.selectedRow.locationId && this.props.store.selectedRow.location}
                          </span>
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at && !this.props.store.currentSubdomain.npi_client) &&
                            <span
                              className='details-padding'
                              onClick={() => {
                                this.setState({ locationEdit: true })
                                appStore.getLocationOptions()
                              }}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>
                          }
                        </span>
                      )}
                      {this.state.locationEdit && (
                        <div>
                          <Select
                            className='mt-1'
                            isMulti={false}
                            isSearchable={true}
                            closeMenuOnSelect={true}
                            options={this.props.store.locationOptionGroups.map(loc => ({
                              ...loc,
                              options: loc.options.filter(opt => !opt.isHidden)
                            }))}
                            value={this.state.location}
                            isDisabled={!this.props.store.currentUser.canUseAdvancedSettings}
                            onChange={(e) => {
                              this.setState({ location: e })
                            }}
                          />
                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                            <Button
                              color='primary'
                              onClick={() => {
                                this.setState({ locationEdit: false });
                                const dataToUpdate = {
                                  location: this.state.location.value[1], // location id
                                  locationLabel: this.state.location.label,
                                  subdomain: this.state.location.value[0],
                                  patient_subdomain: this.props.store.selectedRow.subdomain,
                                  website_token: this.props.store.selectedRow.website_token,
                                }
                                appStore.editData(
                                  dataToUpdate,
                                  'location',
                                  'locationLabel',
                                );
                                appStore.loadPatients();
                              }}>
                              {languageOptions.save[langType]}
                            </Button>
                            <Button
                              style={{ marginLeft: '0.5rem' }}
                              color='danger'
                              onClick={() => this.setState({ locationEdit: false })}>
                              {languageOptions.cancel[langType]}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.facilityType[langType]}:</span>
                    </Col>
                    <Col sm='9'>
                      {!this.state.facilityTypeEdit && (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            {this.props.store.selectedRow.patient_attributes.facility_type}
                          </span>
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                            <span
                              className='details-padding'
                              onClick={() => this.setState({ facilityTypeEdit: true })}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>
                          }
                        </span>
                      )}
                      {this.state.facilityTypeEdit && (
                        <div>
                          <Select
                            className='mt-1'
                            options={facilityTypeOptions}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            value={this.state.facilityType}
                            isDisabled={!this.props.store.currentUser.canUseAdvancedSettings}
                            onChange={(e) => {
                              this.setState({ facilityType: e })
                            }}
                          />
                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                            <Button
                              color='primary'
                              onClick={() => {
                                this.setState({ facilityTypeEdit: false })
                                appStore.editData(
                                  {
                                    facility_type: this.state.facilityType.value,
                                    patient_subdomain: this.props.store.selectedRow.subdomain,
                                    website_token: this.props.store.selectedRow.website_token,
                                  },
                                  'facility_type',
                                  'facilityType',
                                );
                                appStore.loadPatients();
                                this.props.store.selectedRow.patient_attributes.facility_type = this.state.facilityType.value
                              }}>
                              {languageOptions.save[langType]}
                            </Button>
                            <Button
                              style={{ marginLeft: '0.5rem' }}
                              color='danger'
                              onClick={() => this.setState({ facilityTypeEdit: false })}>
                              {languageOptions.cancel[langType]}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>Billing DOS:</span>
                    </Col>
                    <Col sm='9'>
                      <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{this.props.store.selectedRow.date_of_service ? formatDate(this.props.store.selectedRow.date_of_service, 'mm-dd-yyyy') : ''}</span>
                      </span>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.referringNPI[langType]}:</span>
                    </Col>
                    <Col sm='9'>
                      {!this.state.referringNPIEdit && (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            {this.props.store.selectedRow.referring_name}
                          </span>
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                            <span
                              className='details-padding'
                              onClick={() => this.setState({ referringNPIEdit: true })}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>
                          }
                        </span>
                      )}
                      <Modal
                        isOpen={this.state.referringNPIEdit}
                        toggle={this.toggleRefPhysModal}
                        className='modal-lg'
                      >
                        <ModalHeader toggle={() => this.setState({ referringNPIEdit: false })}>
                          {languageOptions.refPhysLookup[langType]}
                        </ModalHeader>
                        <ModalBody>
                          <ReferringPhysicianLookup
                            isEdit={true}
                            selectedRow={this.props.store.selectedRow}
                            toggle={() => this.setState({ referringNPIEdit: false })}
                            shouldCompletePatient={true}
                            completeCallBack={() => this.handleCompletePatient()}
                          />
                        </ModalBody>
                      </Modal>
                    </Col>
                  </Row>

                  <PatientNote readOnly={this.state.readOnly} noteType={'clinic_notes'} />

                  {/* Patient's link URL in Profile*/}
                  <Row style={{ marginBottom: '20px' }}>
                    <Col sm='3'>
                      <span>{languageOptions.ptsLink[langType]}</span>
                    </Col>
                    <Col sm='9'>
                      <span style={{ display: 'flex', justifyContent: 'space-between', }}>
                        <small><a clicktracking="off" target="_blank" rel="noopener noreferrer" href={this.props.store.selectedRow.url}> {this.props.store.selectedRow.url}</a></small>
                      </span>
                    </Col>
                  </Row>
                  {
                    this.props.store.selectedRow.onboarded_by &&
                    <Row style={{ marginBottom: '20px' }}>
                      <Col sm='3'>
                        <span>{languageOptions.onboardedBy[langType]}</span>
                      </Col>
                      <Col sm='9'>
                        <span style={{ display: 'flex', justifyContent: 'space-between', }}>
                          <span>{this.props.store.selectedRow.onboarded_by}</span>
                        </span>
                      </Col>
                    </Row>

                  }
                  {this.props.store.settings.sharedPatientsEnabled && !appStore.ptOriginDifferentAndSharedWithMe() &&
                    <Row>
                      <Col sm='12' className='mb-3'>
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>Patient Shared With:</span>
                          {(this.props.store.selectedRow.shared_with && !this.state.sharedWithEdit) && (
                            <Col>
                              {this.props.store.selectedRow.shared_with.map(cust => <span className="badge badge-light" title={cust.value} key={uuid()} >{cust.label}</span>)}
                            </Col>
                          )}
                          {(!this.state.readOnly && !this.props.store.selectedRow.voided_at) &&
                            <span
                              className='details-padding'
                              onClick={() => this.setState({ sharedWithEdit: true })}>
                              <i className='fas fa-edit' />
                              {languageOptions.edit[langType]}
                            </span>
                          }
                        </span>
                        {this.state.sharedWithEdit && (
                          <div>
                            <Select
                              className='mt-2'
                              style={{ display: 'block' }}
                              options={this.props.store.locationsSharedWithOptions}
                              isDisabled={!this.props.store.locationsSharedWithOptions.length > 0}
                              isMulti="true"
                              value={this.state.shared_with}
                              isSearchable="true"
                              onChange={this.onSharedWithChange}
                            />{' '}
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                              <Button
                                color='primary'
                                onClick={() => {
                                  this.setState({
                                    sharedWithEdit: false,
                                  });
                                  appStore.editData(
                                    {
                                      shared_with: this.state.shared_with,
                                      patient_subdomain: this.props.store.selectedRow.subdomain,
                                      website_token: this.props.store.selectedRow.website_token,
                                    },
                                    'shared_with',
                                    'shared_with',
                                  );
                                  appStore.loadPatients();
                                }}>
                                {languageOptions.save[langType]}
                              </Button>
                              <Button
                                style={{ marginLeft: '0.5rem' }}
                                color='danger'
                                onClick={() => this.setState({ sharedWithEdit: false })}>
                                {languageOptions.cancel[langType]}
                              </Button>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  }

                  <PatientCompliance readOnly={this.state.readOnly} />
                </CardBody>
              </Card>
            </Container>
          </TabPane>

          {store.activeTab === 'alerts' && (
            <ErrorBoundary>
              <Suspense fallback={<Loading />}>
                <TabPane tabId="alerts">
                  <Container className="tabpane-content" >
                    <AlertsTab />
                  </Container>
                </TabPane>
              </Suspense>
            </ErrorBoundary>
          )}

          {store.activeTab === 'pt-messages' && (
            <ErrorBoundary>
              <Suspense fallback={<Loading />}>
                <TabPane tabId="pt-messages">
                  <MessageTab />
                </TabPane>
              </Suspense>
            </ErrorBoundary>
          )}

          {store.activeTab === 'compliance-data' && (
            <ErrorBoundary>
              <Suspense fallback={<Loading />}>
                <TabPane tabId="compliance-data">
                  <Container className="tabpane-content" >
                    <ComplianceDataTab />
                  </Container>
                </TabPane>
              </Suspense>
            </ErrorBoundary>
          )}

          {store.activeTab === 'patient-history' && (
            <ErrorBoundary>
              <Suspense fallback={<Loading />}>
                <TabPane tabId="patient-history">
                  <Container className="tabpane-content" >
                    <PatientHistoryTab
                      readOnly={this.state.readOnly}
                      setShowVoidModal={this.setShowVoidModal}
                      handleUnsubscribedReinvite={this.handleUnsubscribedReinvite}
                    />
                  </Container>
                </TabPane>
              </Suspense>
            </ErrorBoundary>
          )}

        </TabContent>
      </React.Fragment >
    );
  }
}

export default collect(PatientDetails);

import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { collect } from 'react-recollect';
import CampaignIcon from '@mui/icons-material/Campaign';
import Timer from './Timer';

const NoticeNotification = ({notification, handleCountdownEnds}) => {
    const [visible, setVisible] = useState(true);
    const onDismiss = () => {
      if (notification.dismissible) {
        setVisible(false);
        handleCountdownEnds();
      }
    };
    const toggleFunction = notification.dismissible ? onDismiss : undefined;
    
    return (
      <Alert color='info' className='d-flex align-items-center' isOpen={visible} toggle={toggleFunction}>
        <CampaignIcon className='mr-2'/>
        <div>
          <span>{notification?.customMessage}</span>
          {notification?.showCountdown && <Timer dueDate={notification?.dueDate} handleCountdownEnds={handleCountdownEnds}/>}
        </div>
      </Alert>
    );
  };

export default collect(NoticeNotification);
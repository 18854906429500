export const languageOptions = {
    'role_super_administrator': {
        en: 'Role: Super Administrator',
        es: 'Rol: Superadministrador'
    },
    role_administrator: {
        en: 'Role: Administrator',
        es: 'Rol: Administrador'
    },
    role_tech: {
        en: 'Role: Tech',
        es: 'Rol: Técnico'
    },
    role_user: {
        en: 'Role: User',
        es: 'Rol: Usuario'
    },
    role_manager: {
        en: 'Role: Manager',
        ese: 'Rol: Gerente'
    },
    role_clinician: {
        en: 'Role: Clinician',
        es: 'Rol: Clínico'
    },
    'role_readonly': {
        en: 'Role: Read-Only',
        es: 'Rol: Seguir Leyendo'
    },
    selectLocation: {
        en: 'Select Location',
        es: 'Seleccionar ubicación'
    },
    dashboard: {
        en: 'Dashboard',
        es: 'Panel de control'
    },
    analytics: {
        en: 'Analytics',
        es: 'Estadísticas'
    },
    settingsAndFormulary: {
        en: 'Settings & Formulary',
        es: 'Configuración y Formulario'
    },
    knowledgeBase: {
        en: 'Knowledge Base',
        es: 'Área de Conocimiento'
    },
    adHoc: {
        en: 'Ad Hoc',
        es: 'Ad Hoc'
    },
    search: {
        en: 'Search',
        es: 'Búsqueda'
    },
    clinician: {
        en: 'Clinician',
        es: 'Clínico'
    },
    searchPatientId: {
        en: 'Search By Patient ID',
        es: 'Buscar por ID de paciente'
    },
    searchClinicianEmail: {
        en: 'Search by Clinician Email',
        es: 'Buscar por Correo Electrónico del Médico'
    },
    searchPatientName: {
        en: 'Search By Name',
        es: 'Buscar por nombre'
    },
    filterLocation: {
        en: 'Filter By Location',
        es: 'Filtrar por ubicación'
    },
    filterStatus: {
        en: 'Filter By Status',
        es: 'Filtrar por estado'
    },
    manageUsers: {
        en: 'Manage Users',
        es: 'Administrar usuarios'
    },
    changePassword: {
        en: 'Change Password',
        es: 'Cambiar contraseña'
    },
    login: {
        en: 'Login',
        es: 'Iniciar sesión'
    },
    logout: {
        en: 'Logout',
        es: 'Cerrar sesión'
    },
    goBack: {
        en: 'Go back',
        es: 'Volver a'
    },
    resetEmail: {
        en: 'Password Reset email sent',
        es: 'Correo de restablecimiento de contraseña enviado'
    },
    approvedSender: {
        en: 'We recommend that you add sleepglad.com to your Approved Sender list, within your email reader.',
        es: 'Recomendamos que agregue sleepglad.com a su lista de remitentes aprobados, dentro de su lector de correo electrónico'
    },
    emailNotRecieved: {
        en: 'If you do not receive the confirmation message within a few minutes of signing up, please check your Spam or Bulk E-Mail folder just in case the confirmation email got delivered there instead of your inbox. If so, select the confirmation message and mark it Not Spam, which should allow future messages to get through.',
        es: 'Si no recibe el mensaje de confirmación a los pocos minutos de registrarse, revise su carpeta de correo no deseado o correo masivo en caso de que el correo electrónico de confirmación se haya enviado allí en lugar de su bandeja de entrada. Si es así, seleccione el mensaje de confirmación y márquelo como No Spam, lo que debería permitir que lleguen mensajes futuros'
    },
    emailSent: {
        en: 'Email Sent',
        es: 'Correo electrónico enviado'
    },
    passwordResetEmail: {
        en: 'We have sent you an email with your password reset link',
        es: 'Le hemos enviado un correo electrónico con su enlace de restablecimiento de contraseña'
    },
    forgotPassword: {
        en: 'Forgot Password?',
        es: '¿Olvidó su contraseña?'
    },
    contactSysAdmin: {
        en: "Please contact your company's system administrator.'",
        es: 'Póngase en contacto con el administrador de sistemas de su empresa'
    },
    sysAdminReset: {
        en: 'They should have the ability to reset your password.',
        es: 'Deben tener la capacidad de restablecer su contraseña'
    },
    welcome: {
        en: 'Welcome Back!',
        es: '¡Bienvenido de nuevo!'
    },
    username: {
        en: 'Username or Email:',
        es: 'Nombre de usuario o correo electrónico:'
    },
    password: {
        en: 'Password:',
        es: 'Contraseña:'
    },
    oneTimePass: {
        en: 'Authentication Code:',
        es: 'Código de autenticación:'
    },
    verifyOtp: {
        en: 'Verify',
        es: 'Verificar'
    },
    resetPassword: {
        en: 'Reset Password',
        es: 'Restablecer la contraseña',
    },
    submit: {
        en: "Submit",
        es: 'Enviar'
    },
    dispenseMasks: {
        en: "Check all the masks you would like to dispense",
        es: 'Escoja todas las máscaras que desea dispensar'
    },
    pickPercentage: {
        en: 'When multiple masks are appropriate, the system will rotate through available manufacturers.',
        es: 'Cuando varias máscaras son apropiadas, el sistema rotará a través de los fabricantes disponibles.'
    },
    setPerfResmed: {
        en: 'Set your preference for Resmed masks:',
        es: 'Establezca su preferencia para las máscaras Resmed:',
    },
    setPerfPhilips: {
        en: 'Set your preference for Philips masks:',
        es: 'Establezca su preferencia para las máscaras de Philips:',
    },
    setPerfFP: {
        en: 'Set your preference for Fisher-Paykel masks:',
        es: 'Establezca su preferencia para las máscaras de Fisher-Paykel:',
    },
    errorTotal: {
        en: 'The total percentage has to be exactly 100%',
        es: 'El porcentaje total tiene que ser exactamente 100%'
    },
    all: {
        en: 'All',
        es: 'Todos'
    },
    selectAll: {
        en: 'Select All Masks',
        es: 'Seleccionar todas las máscaras'
    },
    select: {
        en: 'Select..',
        es: 'Seleccione..'
    },
    loading: {
        en: 'Loading...',
        es: 'Cargando ...'
    },
    currentLocation: {
        en: `Patient's assigned branch`,
        es: 'Rama asignada del paciente'
    },
    currentLocationError: {
        en: 'Select a location from the list',
        es: 'Seleccione una ubicación de la lista'
    },
    patientId: {
        en: 'Patient ID, MRN, or record number',
        es: 'Cédula del paciente, u otro número de registro'
    },
    patientIdPlaceHolder: {
        en: 'Enter Patient ID, MRN or record number...',
        es: 'Ingresar Cédula del paciente o otro número de registro ...'

    },
    patientIdError: {
        en: 'Patient ID is required',
        es: 'Se requiere Cédula del paciente'
    },
    mobileCell: {
        en: 'Mobile/Cell Number',
        es: 'Número de teléfono móvil'
    },
    mobieCellError: {
        en: 'Please enter a valid mobile number',
        es: 'Número de teléfono móvil *'
    },
    mobileHint: {
        en: 'Make sure this number can receive text messages',
        es: 'Ingrese un número de teléfono móvil válido'
    },
    firstName: {
        en: 'First name',
        es: 'Nombre'
    },
    firstNameError: {
        en: 'First Name is required',
        es: 'Se requiere  nombre'
    },
    lastName: {
        en: 'Last name',
        es: 'Apellido'
    },
    lastNameError: {
        en: 'Last Name is required',
        es: 'Se requiere apellido'
    },
    email: {
        en: 'Email',
        es: 'Correo electrónico'
    },
    emailError: {
        en: 'Please enter a valid email',
        es: 'Ingrese un correo electrónico válido'
    },
    patientLang: {
        en: 'Patient language',
        es: 'Lenguaje del paciente'
    },
    ahi: {
        en: 'Apnea-Hypopnea Index (AHI)',
        es: 'Índice de apnea-hipopnea (IAH)'
    },
    ahiError: {
        en: 'Please enter a valid AHI',
        es: 'Ingrese un IAH válido'
    },
    pressure: {
        en: 'Pressure (cmH2O)',
        es: 'Presión (cmH2O)'
    },
    pressureError: {
        en: 'Please enter a valid Pressure value',
        es: 'Ingrese un valor de presión válido'
    },
    pressureSmall: {
        en: 'CPAP pressure (BiPAP: max pressure)',
        es: 'Presión CPAP (BiPAP: presión máxima)'
    },
    messageToPatient: {
        en: 'Message to patient:',
        es: 'Mensaje al paciente:'
    },
    messagePlaceholder: {
        en: 'Messages typed in here will appear to the patient',
        es: 'Los mensajes escritos aquí aparecerán para el paciente'
    },
    messageToPatientSmall: {
        en: 'Patient will be able to read this message when they receive your text for Remote CPAP Mask Fitting',
        es: 'Escribir anotaciones sobre el paciente (no visible para el paciente):'
    },
    writeNotes: {
        en: 'Write notes on patient (not visible to the patient):',
        es: 'Escribir anotaciones sobre el paciente (no visible para el paciente):'
    },
    writeNotesPlaceholder: {
        en: 'Write important notes about the patient that you want to save',
        es: 'Escriba anotaciones importantes sobre el paciente que desea guardar'
    },
    sendFollowUpMessage: {
        en: 'Send Follow-Up Text Message After',
        es: 'Enviar mensaje de texto de seguimiento'
    },
    noFollowUp: {
        en: 'No Follow-up',
        es: 'Sin seguimiento'
    },
    hours: {
        en: "hours",
        es: 'horas'
    },
    days: {
        en: 'days',
        es: 'días'
    },
    now: {
        en: "Immediately",
        es: 'Inmediatamente'
    },
    followupMessage: {
        en: 'Follow-Up Message',
        es: 'Mensaje de seguimiento'
    },
    followupMessagePlaceHolder: {
        en: 'Write an optional followup message here',
        es: 'Escriba un mensaje de seguimiento opcional aquí'
    },
    followupMessageSmall: {
        en: 'Patient will receive a follow-up text message containing instructions for ResMed or Philips CPAP, along with your follow-up message.',
        es: 'El paciente recibirá un mensaje de texto de seguimiento que contiene instrucciones para ResMed o Philips CPAP, junto con su mensaje de seguimiento'
    },
    ptType: {
        en: 'Patient type',
        es: 'Tipo de Paciente'
    },
    pdProfile: {
        en: "Patient's Profile",
        es: 'Perfil del paciente'
    },
    pdFirstName: {
        en: 'First Name:',
        es: 'Nombre:'
    },
    pdLastName: {
        en: 'Last Name:',
        es: 'Apellido:'
    },
    pdEmail: {
        en: 'Email:',
        es: 'Correo electrónico:'
    },
    pdPhone: {
        en: 'Phone:',
        es: 'Teléfono:'
    },
    pdAHI: {
        en: 'AHI:',
        es: 'IAH:'
    },
    pdAHIUpdate: {
        en: 'If you update AHI please resend invite link to the patient for a mask refit.',
        es: 'Si actualiza IAH, reenvíe el enlace de invitación al paciente para un reajuste de máscara'
    },
    pdPressure: {
        en: 'Pressure:',
        es: 'Presión:'
    },
    pdPressureUpdate: {
        en: 'If you update Pressure please resend invite link to the patient for a mask refit.',
        es: 'Si actualiza la presión, reenvíe el enlace de invitación al paciente para un reajuste de máscara'
    },
    pdPatientID: {
        en: 'Patient ID:',
        es: 'Identificación del paciente:'
    },
    pdCreated: {
        en: 'Created:',
        es: 'Creado:'
    },
    edit: {
        en: 'Edit',
        es: 'Editar'
    },
    save: {
        en: 'Save',
        es: 'Guardar'
    },
    cancel: {
        en: 'Cancel',
        es: 'Cancelar'
    },
    pdCreatedBy: {
        en: 'Created By:',
        es: 'Creado por:'
    },
    pdCompletedBy: {
        en: 'Completed By:',
        es: 'Completado por:'
    },
    pdOnboardedLocation: {
        en: `Patient's Assigned Branch:`,
        es: 'Ramo Atribuído ao Paciente:'
    },
    pdFollowUpMessage: {
        en: 'Follow-Up Message:',
        es: 'Mensaje de seguimiento:'
    },
    pdResendMessage: {
        en: 'When you click the button below your patient will get another link for a mask refit.',
        es: 'Cuando oprima el botón abajo, su paciente obtendrá otro enlace para un reajuste de máscara'
    },
    pdResendLink: {
        en: 'Resend Link Via Text',
        es: 'Reenviar enlace por texto'
    },
    pdNotesOnPatient: {
        en: 'Notes on patient:',
        es: 'Anotaciones sobre el paciente:'
    },
    pdMaskDetails: {
        en: "Recommended Mask Details",
        es: 'Detalle de máscara recomendada'
    },
    pdRecommendedModel: {
        en: 'Recommended Model:',
        es: 'Modelo recomendado:'
    },
    pdSize: {
        en: "Size:",
        es: 'Talla:'
    },
    pdOtherOptions: {
        en: "Other Options:",
        es: 'Otras opciones:'
    },
    pdConfidence: {
        en: "Confidence:",
        es: 'Acertado:'
    },
    pdMessageSent: {
        en: 'Message sent to patient via the invite link:',
        es: 'Mensaje enviado al paciente a través del enlace de invitación:'
    },
    pdSetupDate: {
        en: 'Setup Date:',
        es: 'Fecha de configuración:'
    },
    pdAssignedClinician: {
        en: 'Assigned Clinician:',
        es: 'Clínico asignado:'
    },
    ptMessages: {
        en: 'Messages:',
        es: 'Mensajes:'
    },
    ptPatientId: {
        en: 'Patient ID',
        es: 'Identificación del paciente'
    },
    ptPatientName: {
        en: 'Name',
        es: 'Nombre'
    },
    ptPatientDetails: {
        en: 'Patient Details',
        es: 'Detalles del paciente'
    },
    ptViewDetails: {
        en: 'View Details',
        es: 'Detalles'
    },
    ptStatus: {
        en: 'Status',
        es: 'Estado'
    },
    ptWaiting: {
        en: 'Waiting...',
        es: 'Esperando...'
    },
    ptRX: {
        en: 'RX',
        es: 'RX'
    },
    ptProcessed: {
        en: 'Processed',
        es: 'Procesado'
    },
    ptRXProcessed: {
        en: 'RX - Processed',
        es: 'RX - Procesado'
    },
    ptCompleted: {
        en: 'Completed (Dispensed)',
        es: 'Realizado'
    },
    ptRecallCompleted: {
        en: 'Recall - Completed',
        es: 'Recall - Realizado'
    },
    ptRXCompleted: {
        en: 'RX - Completed (Dispensed)',
        es: 'RX - Realizado'
    },
    ptFailed: {
        en: 'Failed',
        es: 'Falló'
    },
    ptDelivered: {
        en: 'Delivered',
        es: 'Entregada'
    },
    ptVoided: {
        en: 'Voided',
        es: 'Vacío'
    },
    ptUnsubscribed: {
        en: 'Unsubscribed',
        es: 'Dado de baja'
    },
    ptExpired: {
        en: 'Expired',
        es: 'Caducado'
    },
    ptStatusSent: {
        en: 'Sent',
        es: 'Enviado'
    },
    ptScanError: {
        en: 'Scan Error',
        es: 'Error de exploración'
    },
    ptSelectedMak: {
        en: 'Selected Mask',
        es: 'Máscara seleccionada'
    },
    ptLocation: {
        en: 'Location',
        es: 'ubicación'
    },
    ptCreated: {
        en: 'Patient Created',
        es: 'Paciente Creado'
    },
    ptConfidence: {
        en: 'Confidence',
        es: 'Confiabilidad'
    },
    ptHigh: {
        en: 'High',
        es: 'Alta'
    },
    ptMedium: {
        en: 'Medium',
        es: 'Mediana'
    },
    ptLow: {
        en: 'Low',
        es: 'Baja'
    },
    ptNoPatients: {
        en: 'You have not added any new patients.',
        es: 'No ha agregado nuevos pacientes'
    },
    ptAll: {
        en: 'All Patients',
        es: 'Todos Los Pacientes'
    },
    ptNew: {
        en: 'New Patients',
        es: 'Nuevos Pacientes'
    },
    ptResupply: {
        en: 'Resupply Patients',
        es: 'Pacientes De Reabastecimiento'
    },
    ptRecall: {
        en: 'Recall Patients',
        es: 'Pacientes Retirados'
    },
    campaignPatients: {
        en: 'Campaign Patients',
        es: '"Campaña Pacientes'
    },
    ptRecallYes: {
        en: 'Recall Patients (+)',
        es: 'Pacientes Retirados (+)'
    },
    ptResupplySingular: {
        en: 'Resupply',
        es: 'Reabastecimiento'
    },
    ptResupplyDesc: {
        en: 'This patient is already a PAP user, and they are switching to a new mask to replace the one they already have.',
        es: 'Este paciente ya es usuario de PAP, y están cambiando a una nueva mascarilla para sustituir a la que ya tienen.'
    },
    ptNewSinglular: {
        en: 'New Patient',
        es: 'Nuevo Paciente'
    },
    ptNewDesc: {
        en: 'This patient is starting PAP therapy for the first time, and they are using SleepGlad to receive their first, initial mask.',
        es: 'Este paciente está comenzando la terapia pap por primera vez, y están usando SleepGlad para recibir su primera, máscara inicial.'
    },
    ptAddNewPatient: {
        en: "Add New Patient",
        es: 'Agregar nuevo paciente'
    },
    ptCurrentPatients: {
        en: 'Current Patients',
        es: 'Pacientes actuales'
    },
    formulary: {
        en: 'Formulary',
        es: 'Formulario'
    },
    filterFormulary: {
        en: 'Filter formulary',
        es: 'Filtrar del formulario'
    },
    ptReoloadPatients: {
        en: 'Reload Patients',
        es: 'Refrescar pacientes'
    },
    ptFormulary: {
        en: 'SleepGlad Formulary',
        es: 'Formulario SleepGlad'
    },
    ptDownloadCSV: {
        en: 'Download as CSV',
        es: 'Descargar como archivo CSV'
    },
    ptInstruction: {
        en: 'When you add a patient we will send a text message to their phone to perform remote CPAP mask fitting.',
        es: 'Cuando agregue un paciente, le enviaremos un mensaje de texto a su teléfono para realizar el ajuste remoto de la máscara CPAP'
    },
    ptSaveFormulary: {
        en: 'Save Formulary',
        es: 'Guardar formulario'
    },
    ptMarkAsComplete: {
        en: 'Mark As Complete',
        es: 'Marcar como finalizado'
    },
    close: {
        en: 'Close',
        es: 'Cerrar'
    },
    changePass: {
        en: 'Change your password',
        es: 'Cambie su contraseña'
    },
    unauthorized: {
        en: 'unauthorized',
        es: 'no autorizado'
    },
    newUserFn: {
        en: 'First Name:',
        es: 'Nombre:'
    },
    newUserFnError: {
        en: 'First Name is required',
        es: 'Se requiere nombre'
    },
    newUserLn: {
        en: 'Last Name:',
        es: 'Apellido:'
    },
    newUserLnError: {
        en: 'Last Name is rquired',
        es: 'Se requiere el apellido'
    },
    newUserEmail: {
        en: 'Email',
        es: 'Correo electrónico'
    },
    newUserEmailError: {
        en: 'Email is required',
        es: 'Se requiere correo electrónico'
    },
    newUserPh: {
        en: 'Phone Number:',
        es: 'Número de teléfono:'
    },
    newUserPhone: {
        en: 'Phone Number is required for authentication',
        es: 'Se requiere un número de teléfono para la autenticación'
    },
    newUserPassword: {
        en: 'Password',
        es: 'Contraseña'
    },
    newUserPasswordError: {
        en: 'Enter a valid password for this user',
        es: 'Ingrese una contraseña válida para este usuario'
    },
    newUserConfirmPass: {
        en: "Confirm Password:",
        es: 'Confirmar contraseña:'
    },
    newUserPasswordMatch: {
        en: 'Passwords must match',
        es: 'Las contraseñas deben coincidir'
    },
    newUserSelectRole: {
        en: 'Select User Role',
        es: 'Seleccionar rol de usuario'
    },
    newUserReadOnly: {
        en: 'Read-Only',
        es: 'Seguir Leyendo',
    },
    newUserTech: {
        en: 'Tech',
        es: 'Técnico’'
    },
    newUserManager: {
        en: 'Manager',
        es: 'Gerente’'
    },
    newUserAdministrator: {
        en: 'Administrator',
        es: 'Administrador'
    },
    newUserSuperAdministrator: {
        en: 'Super Administrator',
        es: 'Superadministrador'
    },
    utFirstName: {
        en: 'First Name',
        es: 'Nombre'
    },
    utLastName: {
        en: 'Last Name',
        es: 'Apellido'
    },
    utEmail: {
        en: 'Email',
        es: 'Correo electrónico'
    },
    utAdministrator: {
        en: 'Administrator',
        es: 'Administrador'
    },
    utChangePassword: {
        en: "Change Password",
        es: 'Cambiar contraseña'
    },
    utActive: {
        en: 'Active',
        es: 'Activo'
    },
    utLastLogin: {
        en: 'Last Login',
        es: 'Ultimo inicio de sesión'
    },
    utCurrentUsers: {
        en: 'Current Users',
        es: 'Usuarios actuales'
    },
    utAddNewUser: {
        en: 'Add New User',
        es: 'Agregar nuevo usuario'
    },
    utAddUser: {
        en: 'Add User',
        es: 'Agregar usuario'
    },
    utEditUser: {
        en: 'Edit User',
        es: 'Editar usuario'
    },
    utResetPassword: {
        en: 'Reset Password',
        es: 'Restablecer contraseña'
    },
    utResettingPassword: {
        en: 'Reset the password for user:',
        es: 'Está restableciendo la contraseña para un usuario:'
    },
    utValidPassword: {
        en: 'Enter a valid password for this user',
        es: 'Ingrese una contraseña válida para este usuario'
    },
    utPasswordMatch: {
        en: 'Passwords must match',
        es: 'Las contraseñas deben coincidir'
    },
    utConfirmPassword: {
        en: 'Confirm Password',
        es: 'Confirmar contraseña'
    },
    formularyShowPatientMask: {
        en: 'Display Recommended Mask to Patient',
        es: 'Mostrar a los pacientes la máscara recomendada'
    },
    refPhysLookup: {
        en: 'Referring Physician Lookup',
        es: 'Búsqueda de médicos remitentes'
    },
    lookup: {
        en: 'Lookup',
        es: 'Buscar'
    },
    clear: {
        en: 'Clear',
        es: 'Limpiar'
    },
    startDate: {
        en: 'Start Date',
        es: 'Fecha de Inicio'
    },
    endDate: {
        en: 'End Date',
        es: 'Fecha de Finalización'
    },
    facilityType: {
        en: 'Facility type',
        es: 'Tipo de Instalación'
    },
    sleepLab: {
        en: 'Sleep Lab',
        es: 'Laboratorio de Sueño'
    },
    medicalDoctor: {
        en: 'Medical Doctor',
        es: 'Médico'
    },
    skilledNursingFacility: {
        en: 'Skilled Nursing Facility',
        es: 'Centro de Enfermería Especializada'
    },
    other: {
        en: 'Other',
        es: 'Otro'
    },
    ethnicity: {
        en: 'Ethnicity',
        es: 'Etnia'
    },
    americanIndian: {
        en: 'American Indian or Alaska Native',
        es: 'Indio americano o nativo de Alaska'
    },
    asian: {
        en: 'Asian',
        es: 'Asiático'
    },
    africanAmerican: {
        en: 'Black or African American',
        es: 'Afroamericano'
    },
    hispanic: {
        en: 'Hispanic or Latino',
        es: 'Hispano o Latino'
    },
    hawaiian: {
        en: 'Native Hawaiian or Other Pacific Islander',
        es: 'Hawaiano Nativo u Otro Isleño del Pacífico'
    },
    caucasian: {
        en: 'White or Caucasian',
        es: 'Blanco o Caucásico'
    },
    sleepStudyType: {
        en: 'Sleep study type',
        es: 'Tipo de Estudio del Sueño'
    },
    sleepPosition: {
        en: 'Sleep Position(s):',
        es: 'Posición(es) para dormir:'
    },
    homeSleepTest: {
        en: 'Home Sleep Test',
        es: 'Prueba de Sueño en Casa'
    },
    titration: {
        en: 'Titration',
        es: 'Titulación'
    },
    followUpSurvey: {
        en: 'Patient will receive a Follow-Up Survey 90 days after they have been sent their scan invite.',
        es: 'El paciente recibirá una encuesta de seguimiento 90 días después de que se le haya enviado su invitación de exploración.'
    },
    ptRefitFeedBack: {
        en: 'Patient Refit Feedback',
        es: 'Retroalimentación de Reacondicionamiento del Paciente'
    },
    ptRefitBtn: {
        en: 'Refit This Patient',
        es: 'Vuelva a ajustar a este paciente'
    },
    ptRefitRefund12: {
        en: 'This mask recommendation was made less than 12 hours ago. A refit for this patient may not be eligible for a refund.',
        es: 'Esta recomendación de mascarilla se hizo hace menos de 12 horas. Un reacondicionamiento para este paciente puede no ser elegible para un reembolso.'
    },
    ptRefitRefund45: {
        en: 'This mask recommendation was made more than 45 days ago. A refit for this patient may not be eligible for a refund.',
        es: 'Esta recomendación de mascarilla se hizo hace más de 45 días. Un reacondicionamiento para este paciente puede no ser elegible para un reembolso.'
    },
    switchedToMask: {
        en: 'Switched To Mask',
        es: 'Cambiado a Máscara'
    },
    cushionSize: {
        en: 'Cushion Size',
        es: 'Tamaño del cojín'
    },
    frameSize: {
        en: 'Frame Size',
        es: 'Tamaño del Marco'
    },
    productID: {
        en: 'SKU',
        es: 'ID de Producto'
    },
    type: {
        en: 'Type',
        es: 'Tipo'
    },
    submittedAt: {
        en: 'Submitted At',
        es: 'Enviado en'
    },
    submittedBy: {
        en: 'Submitted By',
        es: 'Presentado por'
    },
    msgType: {
        en: 'Message Type',
        es: 'Tipo de Mensaje'
    },
    scheduledTime: {
        en: 'Scheduled Time',
        es: 'Hora Programada'
    },
    printToPDF: {
        en: 'Print to PDF',
        es: 'Imprimir en PDF'
    },
    confidence: {
        en: 'Confidence:',
        es: 'Confianza:'
    },
    confidenceScore: {
        en: 'Scan Confidence Score:',
        es: 'Puntuación de Confianza de Análisis:'
    },
    confidenceScoreOptimal: {
        en: `A score above 90 is optimal.`,
        es: `Una puntuación por encima de 90 es óptima`
    },
    confidenceScoreMedium: {
        en: 'A score between 80 and 90 results in a Medium Confidence Score.',
        es: 'Una puntuación entre 80 y 90 da como resultado una puntuación de confianza media.'
    },
    confidenceScoreLow: {
        en: 'A score below 80 results in a Low Confidence Score.',
        es: 'Una puntuación por debajo de 80 da como resultado una puntuación de confianza baja.'
    },
    confidenceScoreDescPartOne: {
        en: 'To improve the scan confidence score, ask the patient to follow the instructions on their screen,',
        es: 'Para mejorar la puntuación de confianza en la exploración, pida al paciente que siga las instrucciones de la pantalla,'
    },
    confidenceScoreDescPartTwo: {
        en: ' keep their eyes open, hold their phone up level with their face, and look directly into the camera while taking their scan.',
        es: ' mantenga los ojos abiertos, sostenga el teléfono a la altura de la cara y mire directamente a la cámara mientras realiza el escaneo.'
    },
    recommendedOptions: {
        en: 'Recommended Options',
        es: 'Opciones Recomendadas',
    },
    otherFormularyMasks: {
        en: 'Other Formulary Masks',
        es: 'Otras Mascarillas Del Formulario',
    },
    outOfFormularyMasks: {
        en: 'Out of Formulary Masks',
        es: 'Fuera Del Formulario Mascarillas',
    },
    waitForResults: {
        en: 'Waiting for results...',
        es: 'A la espera de resultados...'
    },
    setupCancelled: {
        en: 'Setup Cancelled',
        es: 'Instalación Cancelada'
    },
    ptMagnetic: {
        en: 'Some active medical implants, such as a pacemaker, defibrillator, or hemostatic clip implant may be affected by localized magnetic fields. Some PAP masks may include magnetic clips, which should be kept at least 150 mm away from any active medical implant. Use of masks containing magnetic clips may be contraindicated in patients with certain pre-existing conditions.',
        es: 'Algunos implantes médicos activos, como un marcapasos, un desfibrilador o un implante de clip hemostático, pueden verse afectados por campos magnéticos localizados. Algunas mascarillas PAP pueden incluir clips magnéticos, que deben mantenerse al menos a 150 mm de distancia de cualquier implante médico activo. El uso de mascarillas que contengan clips magnéticos puede estar contraindicado en pacientes con ciertas condiciones preexistentes.'
    },
    completeWith: {
        en: 'Complete with',
        es: 'Completa con'
    },
    setupCancelledQuestion: {
        en: 'Are you sure you want to cancel this setup?',
        es: '¿Está seguro de que desea cancelar esta configuración?'
    },
    dispensedMasksTitle: {
        en: 'Dispensed Masks',
        es: 'Máscaras dispensadas'
    },
    refitInfo: {
        en: 'If a patient requires a refit after their SleepGlad mask fit, click here to input their new mask details.',
        es: 'Si un paciente requiere un reacondicionamiento después de su ajuste de la máscara SleepGlad, haga clic aquí para ingresar los detalles de su nueva máscara.'
    },
    newTab: {
        en: 'Open in New Tab',
        es: 'Abrir en Nueva pestaña'
    },
    msgDelivery: {
        en: 'Message delivery',
        es: 'Entrega de Mensajes'
    },
    dateOfBirth: {
        en: 'Date of birth',
        es: 'Fecha de Nacimiento'
    },
    referringNPI: {
        en: 'Referring physician',
        es: 'Número NPI del médico de referencia'
    },
    physicianNPI: {
        en: 'Physician',
        es: 'Médico'
    },
    hasPrescription: {
        en: 'Scripted mask',
        es: 'Máscara prescripta'
    },
    maskPrescription: {
        en: 'Mask Prescription',
        es: 'Prescripción de la Máscara'
    },
    ptMaskPrescribed: {
        en: 'Mask Prescribed',
        es: 'Máscara Prescrita'
    },
    ptCushionsizePrescribed: {
        en: 'Mask Cushion Size Prescribed',
        es: 'Tamaño del cojín de la máscara prescrito'
    },
    ptFrameSizePrescribed: {
        en: 'Mask Frame Size Prescribed',
        es: 'Tamaño del marco de máscara prescrito'
    },
    advancedSettings: {
        en: 'Advanced Settings',
        es: 'Configuración avanzada'
    },
    voidedBy: {
        en: 'Voided By:',
        es: 'Anulado por:'
    },
    voidReason: {
        en: 'Void Reason:',
        es: 'Razón Vacía:'
    },
    ptsLink: {
        en: 'Patient\'s link:',
        es: 'Enlace del Paciente:'
    },
    ptSurveyAnswers: {
        en: 'Patient Survey Answers:',
        es: 'Respuestas a la encuesta de Pacientes:'
    },
    ptQuestionnaire: {
        en: 'Patient Questionnaire',
        es: 'Cuestionario del Paciente'
    },
    ptGender: {
        en: 'Gender:',
        es: 'Género:'
    },
    ptBeard: {
        en: 'Beard:',
        es: 'Barba:'
    },
    facialHair: {
        en: 'Facial Hair:',
        es: 'Vello facial:'
    },
    ptBeardType: {
        en: 'Beard type:',
        es: 'Tipo de barba:'
    },
    ptTossNTurn: {
        en: 'Toss and Turn:',
        es: 'Lanzar y Girar:'
    },
    ptClaustrophobic: {
        en: 'Claustrophobic:',
        es: 'Claustrofóbico:'
    },
    ptWearDentures: {
        en: 'Wear dentures:',
        es: 'Dentadura postiza:'
    },
    ptMissingTeeth: {
        en: 'Missing teeth:',
        es: 'Le faltan dientes:'
    },
    ptSleepDentures: {
        en: 'Sleep in dentures:',
        es: 'Duerme con dentadura postiza:'
    },
    ptMouthBreathing: {
        en: 'Mouth Breathing:',
        es: 'Respiración Bucal:'
    },
    ptConsistentMouthBreathing: {
        en: 'Consistent Mouth Breathing:',
        es: 'Respiración Bucal Consistente:'
    },
    ptNasalCongestion: {
        en: 'Mild Nasal Congestion:',
        es: 'Congestión Nasal:'
    },
    ptSevereNasalCongestion: {
        en: 'Severe Nasal Congestion:',
        es: 'Congestión Nasal Severa:'
    },
    ptMagneticImplant: {
        en: 'Magnet-Sensitive Surgical Implant:',
        es: 'Implante Quirúrgico Sensible al Imán:'
    },
    ptFacialSkinIrritation: {
        en: 'Facial Skin Irritation:',
        es: 'Irritación Facial de la Piel:'
    },
    ptDeviatedSeptum: {
        en: 'Deviated Septum:',
        es: 'Tabique Desviado:'
    },
    ptNoResponse: {
        en: 'No Response',
        es: 'Sin Respuesta de Mensaje'
    },
    ptAvailableMasks: {
        en: 'Available masks for this patient',
        es: 'Máscaras disponibles para este paciente'
    },
    ptMsgHistory: {
        en: 'Patient Message History',
        es: 'Historial de Mensajes del Paciente'
    },
    reinviteBtn: {
        en: 'Reset Patient and Re-Invite',
        es: 'Restablecer paciente y volver a invitar'
    },
    voidSetup: {
        en: 'Void Setup',
        es: 'Configuración de Void'
    },
    markPtCancelled: {
        en: 'Mark this patient as cancelled',
        es: 'Marcar a este Paciente Como Cancelado'
    },
    newPtInv: {
        en: 'Invite a patient to perform remote PAP mask fitting',
        es: 'Invitar a un paciente a realizar el ajuste remoto de la máscara de PAP'
    },
    chinStrapDispensed: {
        en: 'Was a chin strap dispensed with this mask?',
        es: 'Era una correa para la barbilla prescindir de esta máscara?'
    },
    canUseChinStrap: {
        en: 'Consider using a chin strap with this mask.',
        es: 'Considere usar una correa para la barbilla con esta máscara.'
    },
    yes: {
        en: 'Yes',
        es: 'Sí'
    },
    no: {
        en: 'No',
        es: 'No'
    },
    create: {
        en: 'Create',
        es: 'Crear',
    },
    kbUpdatesTitle: {
        en: 'Title',
        es: 'Título'
    },
    kbUpdatesShortDesc: {
        en: 'Short Description',
        es: 'Breve descripción'
    },
    kbUpdatesContent: {
        en: 'Write your content here!',
        es: '¡Escribe tu contenido aquí!'
    },
    kbUpdatesUserType: {
        en: 'User Type',
        es: 'Tipo de usuario'
    },
    kbUpdatesDragnDrop: {
        en: `Drag 'n' drop Image here, or click to select files`,
        es: 'Arrastre y suelte la imagen aquí, o haga clic para seleccionar archivos'
    },
    patientCompletedTooltip: {
        en: 'When the patient is Completed, they will receive a followup message with educational information about their mask.',
        es: 'Cuando el paciente haya terminado, recibirá un mensaje de seguimiento con información educativa sobre su mascarilla.'
    },
    confirm: {
        en: 'Confirm',
        es: 'confirmar',
    },
    ptDuplicateModalHeader: {
        en: 'Duplicate Patient',
        es: 'Paciente duplicado/duplicada',
    },
    ptDuplicateModalBody: {
        en: 'Patient with the same ID already exists. Please confirm that you want to add this record!',
        es: 'Ya existe un paciente con la misma identificación. Confirme que desea agregar este registro!',
    },
    formularySelectSubscript: {
        en: 'Ensure you are making selections for the formulary shown.',
        es: 'Asegúrese de hacer selecciones para el formulario que se muestra.',
    },
    kbDeletePostTitle: {
        en: 'Delete post',
        es: 'Borrar puesto',
    },
    kbDeletePostBody: {
        en: 'Please confirm you want to delete selected post.',
        es: 'Por favor, confirme que desea eliminar el puesto seleccionado.',
    },
    withTitration: {
        en: 'with Titration',
        es: 'con titulación'
    },
    noTitration: {
        en: 'no Titration',
        es: 'sin titulación'
    },
    ptAddSharedNote: {
        en: 'Add Note',
        es: 'Añadir La Nota'
    },
    NPINumber: {
        en: 'NPI Number',
        es: 'Número NPI'
    },
    liaisonEmail: {
        en: 'Liaison Email',
        es: 'Correo Electrónico De Enlace'
    },
    liaisonPhone: {
        en: 'Liaison Phone',
        es: 'Teléfono De Enlace'
    },
    liaisonFax: {
        en: 'Liaison Fax',
        es: 'Fax De Enlace'
    },
    recallQuestion: {
        en: 'Recall Question',
        es: 'Recordar Pregunta'
    },
    beforeRecallQuestionnaire: {
        en: 'Before Recall Questionnaire',
        es: 'Antes Del Cuestionario De Recuerdo'
    },
    ptAddScriptedMask: {
        en: "Add Scripted Mask",
        es: 'Agregar Máscara Prescripta'
    },
    ptEditScriptedMask: {
        en: "Edit Scripted Mask",
        es: 'Editar Máscara Prescripta'
    },
    applyFilters: {
        en: "Apply Filters",
        es: 'Aplicar Filtros'
    },
    papTherapy: {
        en: "PAP Therapy:",
        es: 'Terapia PAP:'
    },
    papTherapyPressure: {
        en: "PAP Therapy & Pressure",
        es: 'Terapia PAP y Presión'
    },
    selectPressure: {
        en: "Select Pressure...",
        es: 'Seleccione Presión...'
    },
    onboardedBy: {
        en: "Onboarded By:",
        es: "Incorporado por:"
    },
    maskVarietyModalBody: {
        lessThanFive: {
            en: 'Less than five masks',
            es: 'Menos de cinco máscaras'
        },
        noFullFace: {
            en: 'No Full Face masks',
            es: 'Ninguna máscara de tipo Full Face'
        },
        noNasalPillow: {
            en: 'No Nasal Pillow masks',
            es: 'Ninguna máscara de tipo Nasal Pillow'
        },
        noNasal: {
            en: 'No Nasal masks',
            es: 'Ninguna máscara de tipo Nasal'
        },
        allMagnetic: {
            en: 'No masks without magnetic clips',
            es: 'Ninguna máscara sin clips magnéticos'
        }
    },
    isCompliant: {
        en: "Patient is Compliant",
        es: "El paciente cumple"
    },
    compliancePercentage: {
        en: "Compliance Percentage",
        es: "Porcentaje de cumplimiento"
    },
    complianceDay: {
        en: "Compliance Day",
        es: "Día de cumplimiento"
    },
    complianceMachine: {
        en: "Machine Type",
        es: "Tipo de Máquina"
    },
    admin: {
        en: "Billing",
        es: "Facturación"
    },
}
